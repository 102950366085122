export const featureAccess = {
    showTechnicalProblemMessage: false,
    showWebAppBtn: false, // show or not button webapp on mathia toolbar
    institutionsGroupAccess: false,
    translationAccess: {
        show: true,
        withFlag: true
    },
    helpMenu: {
        default: true,
        learner: true,
    },
    showLangChoiceWhenPlayLesson: false,
    showAsksHeader: true, // an header with text and an external link
};
