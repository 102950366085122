export const featureAccess = {
    feedbackMenu: { default: false, parent: true, trainer: true }, // menu to send feedback
    feedbackMenuAsGar: { default: false, trainer: true }, // menu to send feedback
    institutionsGroupAccess: false,
    isGoToLoginPageShortcutAllowed: false,
    showTechnicalProblemMessage: false,
    showWebAppBtn: false, // show or not button webapp on mathia toolbar
    translationAccess: {
        show: false,
        withFlag: true
    }
};
