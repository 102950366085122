export const secondsToHoursMinutesSeconds = (length: number): string => {
    let duration = '';

    if (length) {
        const seconds = length;
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600) + d * 24;
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '';
        const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m : '00';
        const sDisplay = s > 0 ? ':' + (s < 10 ? '0' : '') + s : ':00';
        duration = hDisplay + mDisplay + sDisplay;
    }

    return duration;
};