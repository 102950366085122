import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphHumanumService} from '../../services/graph-humanum.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {AttendanceData} from '../../model/attendance-data';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import {rgbOpacity} from 'shared/utils';
import 'moment-duration-format';
import {GraphHumanumTranslateService} from '@modules/graph-humanum/core/services/graph-humanum-translate.service';
import {FakeStepperOptions} from '@modules/graph-humanum/core/component/fake-stepper/fake-stepper.component';
import {ChartConfiguration} from 'chart.js/dist/types';

const ChaptersColors = [
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
];

const ModalityColors = [
    'rgb(3,124,194)',
    'rgb(3,124,194)',
    'rgb(3,124,194)',
];

@Component({
    selector: 'app-attendance-graph',
    templateUrl: './attendance-graph.component.html',
})
export class AttendanceGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    public empty = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };

    constructor(
        private graphHumanumService: GraphHumanumService,
        private graphHumanumTranslateService: GraphHumanumTranslateService,
    ) {
    }

    ngOnInit(): void {
        this.graphHumanumService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.graphHumanumService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphHumanumService.getAttendanceGraphData()),
                tap(() => this.showLoader = true),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            if (data.entities.length === 0) {
                this.empty = true;
                this.graphConfigObs.next(null);
            } else {
                this.empty = false;
                this.generateGlobalGraph(data);
            }
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateGlobalGraph(graphData: AttendanceData): void {
        const rawData = graphData.entitiesGroupedByLearnerAndExercise;
        const learners = this.graphHumanumService.learners
            .filter(l => (graphData.graphFilters.learnerList || []).includes(+l.id))
            .sort((a, b) => a.nickname < b.nickname ? -1 : 1);
        const chapters = [{id: undefined}]; // this.graphHumanumService.chapters.sort((a, b) => a.attributes.name < b.attributes.name ? -1 : 1);
        const chartLabels: string[] = learners.map(l => l.nickname);
        const chartDataSets: ChartConfiguration['data']['datasets'] = [];
        const learnerActivityCounter: {
            learnerId: number,
            count: number
        }[] = [];

        const availableLessons = this.graphHumanumService
            .conceptsAvailable
            .map(c => c.id)
            .map(conceptId =>
                this.graphHumanumService
                    .lessons
                    .filter(l =>
                        l.get('metadatas')
                            ?.concepts
                            .map(currentLessonConcept => +currentLessonConcept.id)
                            .includes(+conceptId)
                    )
            )
            .reduce((acc, val) => acc.concat(val), []);

        chapters.forEach((chapter, indexChapter) => {
            const chapterColor = this.getChapterColors(indexChapter);

            const chapterDataSet = {
                label: this.graphHumanumTranslateService.get('graph_humanum.y_axis_legend'), // label: chapter.attributes.name,
                backgroundColor: rgbOpacity(chapterColor, '.9'),
                hoverBackgroundColor: chapterColor,
                data: [],
                barThickness: 'flex',
            };

            learners.forEach((learner, indexLearner) => {
                const learnerGraphData = rawData[+learner.id + ''] || [];
                let totalOfActivitiesDone = 0;

                learnerGraphData
                    .map(lgd => lgd.attributes)
                    .filter(progressData => progressData.lessons.length > 0
                        && progressData.lessons.some(l => availableLessons.some(al => +al.id === +l.id)))
                    .forEach(progressData =>
                        progressData.qs
                            .forEach(qs => totalOfActivitiesDone += qs.answers.total)
                    );

                // const duration: number = exerciceGraphData ? exerciceGraphData.map(d => d.detail.map((dd) => (0 + dd.duration))).flat().reduce((acc, dd) => acc + dd, 0) : 0;
                learnerActivityCounter.push({
                    learnerId: +learner.id,
                    count: totalOfActivitiesDone
                });
                chapterDataSet.data[indexLearner] = totalOfActivitiesDone;
            });

            chartDataSets.push(chapterDataSet);
        });

        this.empty = chartDataSets.every(cds => cds.data.every(d => d === 0));

        // On reboucle parce qu'on avait besoin d'avoir les totaux avant c'est pas la plus optimisé des façon de faire, mais la plus lisible
        const dotsDetails: CustomTooltipModalContentSettings[][] = chapters.map((chapter) => {
            return learners.map((learner): CustomTooltipModalContentSettings => {
                const activitiesCounter = learnerActivityCounter.filter(c => +c.learnerId === +learner.id);
                const count = activitiesCounter.reduce((acc, ac) => acc + ac.count, 0);
                return ({
                    content: `<div>${count} ${this.graphHumanumTranslateService.get('graph_humanum.exercises')}</div>`,
                    header: `<div>${learner.nickname}</div>`
                });
            });
        });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    x: {
                        grid: {
                            display: true,
                            offset: false
                        },
                        stacked: true,
                        // gridLines: {
                        //     display: false,
                        // }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            precision: 0
                        },
                        type: 'linear',
                    },

                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation(),
                        display: true
                    }
                },
                aspectRatio: 2.5
            },
            chartData: chartDataSets,
            modalContent: dotsDetails,
        });
    }

    private getChapterColors(indexChapter: number): string {
        if (indexChapter >= 0 && indexChapter < ChaptersColors.length) {
            return ChaptersColors[indexChapter];
        }
        const defaultColor = 'rgb(0,0,0)';
        console.warn(`Color indexed at number ${indexChapter} but there are only ${ChaptersColors.length} colors. Color "${defaultColor}" by default`);
        return defaultColor;
    }
}
