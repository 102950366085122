import {Component, inject} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
  selector: 'app-assignment-add-rules-validation',
  templateUrl: './assignment-add-rules-validation.component.html',
})
export class AssignmentAddRulesValidationComponent {
    private assignmentByStepService: AssignmentByStepService;

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
    }
}
