import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-activity-edition-typology-bloc',
  templateUrl: './activity-edition-typology-bloc.component.html',
  styleUrls: ['./activity-edition-typology-bloc.component.scss']
})

export class ActivityEditionTypologyBlocComponent {
    @Input() label: string;
    @Input() description: string;
    @Input() iconUrl: string;
    @Input() ariaLabel: string;
}
