import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-old-school',
  templateUrl: './old-school.component.html',
  styleUrls: ['./old-school.component.scss']
})
export class OldSchoolComponent {
    @Output() public buttonClick = new EventEmitter<MouseEvent>();

    public onButtonClick($event: MouseEvent): void {
        this.buttonClick.emit($event);
    }
}
