import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-edition-preview-true-false',
  templateUrl: './activity-edition-preview-true-false.component.html',
  styleUrls: ['./activity-edition-preview-true-false.component.scss']
})
export class ActivityEditionPreviewTrueFalseComponent {

}
