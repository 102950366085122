import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrettyCardComponent } from './pretty-card/pretty-card.component';



@NgModule({
    declarations: [
        PrettyCardComponent
    ],
    exports: [
        PrettyCardComponent
    ],
    imports: [
        CommonModule
    ]
})
export class PrettyCardModule { }
