import {inject, Injectable} from '@angular/core';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {AsyncRules, LessonGranuleEntity, NavigateToLessonOptions} from '@modules/activities/core/models';
import {of} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';
import {LessonsService} from './lessons.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonMetadataDialogComponent} from '@modules/activities/core/lessons/lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {MetadataCardInterface} from 'fuse-core/components/card/metadata-card.interface';

@Injectable({
    providedIn: 'root'
})
export class LessonsCardService {

    private authorizationService = inject(AuthorizationService);
    private lessonsService = inject(LessonsService);
    private activitiesService = inject(ActivitiesService);
    private dialog = inject(MatDialog);
    private octopusConnect = inject(OctopusConnectService);

    public getCallbacks(): Partial<DataCardInterface<LessonGranuleEntity>> {
        return {
            'isEditableAndErasable$': (resource) => this.authorizationService.currentUserCan(AsyncRules.EditLesson, of(false), resource),
            'isDuplicable$': (resource) => this.authorizationService.currentUserCan(AsyncRules.DuplicateLesson, of(false), resource),
            'openAssign': (resource) => this.openAssign(resource),
            'openDialog': (resource) => this.lessonsService.openDeleteDialog(resource),
            'openDuplicate': (id) => this.lessonsService.launchEditor(id),
            'openEditor': (id) => this.lessonsService.launchEditor(id),
            'editAndDeleteAssignments': (resource) => this.lessonsService.editAndDeleteAssignments(resource).subscribe(),
            'openMetaDialog': (event, metadata) => this.openMetaDialog(event, metadata),
            'play': (resource, options: NavigateToLessonOptions) => this.lessonsService.navigateToLesson(resource as LessonGranuleEntity, options),
        };

    }

    private openAssign(lesson): void {
        if (this.activitiesService.assignmentView) {
            const dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: lesson.id
               }
            });

            dialogRef.afterClosed().subscribe((data) => {
                if (data && (!lesson.get('locked') || lesson.get('locked') === '0')) {
                    const entity = new DataEntity('granule-lesson', lesson.attributes, this.octopusConnect, lesson.id);
                    entity.set('locked', true);
                    entity.save()
                        .subscribe(success => {
                            if (success) {
                                this.lessonsService.userLessonsPaginated.paginator.reload();
                            }
                        });
                }
            });
        }
    }

    public openMetaDialog(e: MouseEvent, metadatas: MetadataCardInterface): void {
        e.stopPropagation();
        this.dialog.open(LessonMetadataDialogComponent, {
            data: {
                metadatas,
                settings: {lessonMetadataDialogFields: this.lessonsService.getLessonMetadataDialogFieldsForCurrentRole()}
            }
        });
    }

    public getLessonTypeClasses(lesson: LessonGranuleEntity): string[] {
        if (Array.isArray(lesson.get('metadatas').lessonType)) {
            return (lesson.get('metadatas').lessonType as { id: string, label: string }[])
                .map((type) => `lesson-type-${type.label.toLocaleLowerCase()}`) || [];
        } else if (lesson.get('metadatas').lessonType) {
            let label = (lesson.get('metadatas').lessonType as { id: string, label: string }).label;
            return [`lesson-type-${label.toLocaleLowerCase()}`];
        }
    }
}
