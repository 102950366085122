import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';

@Component({
    selector: 'app-reward-page',
    templateUrl: './reward-page.component.html'
})
/**
 * reward page use at end of question set
 */
export class RewardPageComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnDestroy {
    reward = 0; // 1 to 4
    private newScore = 0; // percentage 0 to 100
    private previousScore = 0;
    public coinsToShow: boolean[] = [];// coin to show or not
    public nextLevel = false;
    public newLevel = 0;
    public showNextLevelRewarded = false;
    public coinGained = 0;
    public coinAlreadyGained = 0;
    public coinToGainToPassNextLevel = 999999;

    constructor(private communicationCenter: CommunicationCenterService,
                private lessonsService: LessonsService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        super();
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('loadAvatar').next(true);
        // during reward page we doesn't show progress bar and header of exo
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
        this.communicationCenter.getRoom('header-exo').getSubject('show').next(false);
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data) => {
                this.newLevel = data.level + 1;// if enought coin win it's the level we will show
                this.coinToGainToPassNextLevel = data?.pointsToNextLevel;
            });

        this.activatedRoute.queryParams.pipe(
            tap((data: { subLessonId: string }) => {
                this.coinsToShow = [];
                const dataScore = <any>this.lessonsService.subLessonProgressScoreList[data.subLessonId];
                this.previousScore = dataScore?.score || 0;
                this.newScore = this.lessonsService.currentAssignment.get('config') && JSON.parse(this.lessonsService.currentAssignment.get('config'))[data.subLessonId]?.score;
                this.previousScoreNotToShowCoinAlreadyAdded();
                this.initData();
            })
        ).subscribe();
    }

    next(): void {
        if (this.nextLevel === true && this.showNextLevelRewarded === false) {
            this.showNextLevelRewarded = true;
        } else {
            // will navigate to summary component
            this.router.navigate(['../', 'summary'], {relativeTo: this.activatedRoute});
        }
    }

    /**
     * set coin in regard of score and if score is better or first
     * set reward 0 to 4 4 equal diamond
     * @private
     */
    private initData(): void {
        if (this.newScore < 50) {
            this.reward = 0;
            this.coinsToShow = [false, false, false, false];
        } else if (this.newScore >= 50 && this.newScore < 70) {
            this.reward = 1;
            this.coinGained = 1;
            this.addCoinToShowInRegardToPreviousScore(1);
        } else if (this.newScore < 90) {
            this.reward = 2;
            this.coinGained = 3;
            this.addCoinToShowInRegardToPreviousScore(2);
        } else if (this.newScore <= 99) {
            this.reward = 3;
            this.coinGained = 6;
            this.addCoinToShowInRegardToPreviousScore(3);
        } else if (this.newScore === 100) {
            this.reward = 4;
            this.coinGained = 10;
            this.addCoinToShowInRegardToPreviousScore(4);
        }
        this.coinGained = this.coinGained >= this.coinAlreadyGained ? this.coinGained - this.coinAlreadyGained : 0;
        if (this.coinGained >= this.coinToGainToPassNextLevel) {
            this.nextLevel = true;
        }
    }

    private previousScoreNotToShowCoinAlreadyAdded(): void {
        if (this.previousScore === 0) {
            this.coinsToShow = [];
            return;
        }

        if (this.previousScore >= 50 && this.previousScore < 70) {
            this.coinsToShow = [false];
            this.coinAlreadyGained = 1;
        } else if (this.previousScore < 90) {
            this.coinAlreadyGained = 3;
            this.coinsToShow = [false, false];
        } else if (this.previousScore <= 99) {
            this.coinAlreadyGained = 6;
            this.coinsToShow = [false, false, false];
        } else if (this.previousScore === 100) {
            this.coinAlreadyGained = 10;
            this.coinsToShow = [false, false, false, false];
        }
    }

    private addCoinToShowInRegardToPreviousScore(position: number) {
        if (position > this.coinsToShow.length && this.coinsToShow.length < 4) {
            let limit = position - this.coinsToShow.length;
            for (let i = 1; i <= limit; i++) {
                this.coinsToShow.push(true);
            }
        }
        while (this.coinsToShow.length < 4) {
            this.coinsToShow.push(false);
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(false);
        this.communicationCenter.getRoom('header-exo').getSubject('show').next(true);
        this.communicationCenter.getRoom('activities').getSubject('backToLesson').next({forceUrl: [], doAction: false});
        super.ngOnDestroy();
    }
}