// return the current os like Windows 10
export const currentOs = function (): string {
    try {
        var osName = 'Unknown';
        if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
            osName = 'Windows 10';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1) {
            osName = 'Windows 8.1';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
            osName = 'Windows 8';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
            osName = 'Windows 7';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
            osName = 'Windows Vista';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
            osName = 'Windows XP';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
            osName = 'Windows 2000';
        }
        if (window.navigator.userAgent.indexOf('Mac') !== -1) {
            osName = 'Mac/iOS';
        }
        if (window.navigator.userAgent.indexOf('X11') !== -1) {
            osName = 'UNIX';
        }
        if (window.navigator.userAgent.indexOf('Linux') !== -1) {
            osName = 'Linux';
        }
        return osName;
    } catch (ex) {
        return '';
    }
};