import {MergeTypes} from 'shared/merge';
import {GranuleEntity, GranuleEntityAttributesInterface, GranuleMetadata} from 'shared/models/granule';
import {TypedDataEntityInterface, TypedPaginatedCollectionInterface} from 'shared/models/octopus-connect';
export interface LessonReference {
    'id': string,
    'type': 'activity' | 'lesson',
    'title': string,
}

export type LessonGranuleEntity = GranuleEntity<LessonReference[]>
export type LessonGranulePaginatedCollection = TypedPaginatedCollectionInterface<GranuleEntityAttributesInterface<LessonReference[]>>

export type UpdatableLessonGranuleEntityAttributes = MergeTypes< [
    GranuleEntityAttributesInterface<LessonReference[]>,
    { reference: string[] ,
    metadatas: string ,
    usage: (string | number)[] },
]>

export type UpdatableGranuleLessonEntity = TypedDataEntityInterface<UpdatableLessonGranuleEntityAttributes>

export type UpdatableLessonMetaEntityAttributes = TypedDataEntityInterface<MergeTypes<[
    GranuleMetadata,
    {
        chapters: (string | number)[],
        concepts: (string | number)[],
        educationalLevel: (string | number)[],
    }
]>>

export interface LessonReferenceEntityAttributes {
    lesson_step: LessonReference[]
}

export type LessonReferenceEntity = TypedDataEntityInterface<LessonReferenceEntityAttributes>
export type UpdatableLessonReferenceEntityAttributes = MergeTypes<[
    LessonReferenceEntityAttributes,
    { lesson_step: string[] }
]>
export type UpdatableLessonReferenceEntity = TypedDataEntityInterface<UpdatableLessonReferenceEntityAttributes>