import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    answerStatusEnum,
    ButtonComponentConfigInterface,
    ItemAnswerStateEnum,
} from '@modules/activities/core/models';
import {FeedbackInterface} from '@modules/activities/core/player-components/base-activity.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';

@Component({
    selector: 'app-activities-feedback',
    templateUrl: './activities-feedback.component.html',
    styleUrls: ['./activities-feedback.component.scss'],
    animations: [
        trigger('fadeAndSlideIn', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('200ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
        ]),
    ],
})

export class ActivitiesFeedbackComponent {
    @Input() activityType: TypologyLabel;
    @Input() feedback: FeedbackInterface<any>;
    @Input() buttons: ButtonComponentConfigInterface[];
    @Input() answerStatus: answerStatusEnum;
    @Output() action = new EventEmitter<ButtonComponentConfigInterface>();
    public folded: boolean = false;

    public onAction(button: ButtonComponentConfigInterface): void {
        this.action.emit(button);
    }

    public toggleFeedback() {
        this.folded = !this.folded;
    }

    public get svgIcon(): string {
        if ([TypologyLabel.awareness, TypologyLabel.multimedia, TypologyLabel.external, TypologyLabel.recording].includes(this.activityType)) {
            return 'bookmark-check-fill';
        } else if (this.answerStatus === answerStatusEnum.correct || this.feedback.state === ItemAnswerStateEnum.wasCorrect) {
            return 'check-rounded-700';
        } else {
            return 'close-rounded-700';
        }
    }

    public get classCss(): string {
        if (this.feedback.state === ItemAnswerStateEnum.wasCorrect) {
            return 'correct';
        }
        if (this.feedback.state === ItemAnswerStateEnum.missing) {
            return 'missing';
        }
        if (this.feedback.state === ItemAnswerStateEnum.incorrect) {
            return 'wrong';
        }
        return 'pristine';
    }
}