import {Component, Input} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FormControl, FormRecord, Validators} from "@angular/forms";
import {FormDialogService} from "fuse-core/components/form-dialog/form-dialog.service";
import {FormQuestion} from './form.model';
import {timer} from "rxjs/internal/observable/timer";

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
})
export class FormDialogComponent {
    @Input() public source: string;
    @Input() private formId: string;
    
    public questions: FormQuestion[] = [];
    public formGroup = new FormRecord<FormControl<string | null>>({});
    public isSubmitting = false;

    constructor(
        private dialogRef: MatDialogRef<FormDialogComponent>,
        private formDialogService: FormDialogService,
    ) {
        this.formDialogService
            .loadForm$()
            .subscribe((questions) => {
                this.questions = questions;
                this.questions.forEach((question, index) => {
                    const validators = [];

                    if (question.required) {
                        validators.push(Validators.required);
                    }

                    this.formGroup.addControl(question.key, new FormControl('', validators));
                });
            });
    }
    
    public get isEmbedded(): boolean {
        return this.source === 'basic-page';
    }

    public onSubmit(): void {
        this.isSubmitting = true;
        this.formDialogService
            .submitForm(this.formGroup.value)
            .subscribe(() => {
                this.isSubmitting = false;
                this.dialogRef.close();
            });
    }
}
