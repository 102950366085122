// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  min-width: 0;
}
:host .theme-options-button {
  position: absolute;
  top: 160px;
  right: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.9;
  z-index: 998;
}
:host .theme-options-button mat-icon {
  animation: rotating 3s linear infinite;
}
:host .theme-options-button:hover {
  opacity: 1;
}
:host .theme-options-sidebar {
  width: 360px;
  min-width: 360px;
  max-width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AADJ;AAGI;EACI,kBAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,oBAAA;EACA,YAAA;EACA,YAAA;AADR;AAGQ;EACI,sCAAA;AADZ;AAIQ;EACI,UAAA;AAFZ;AAMI;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;AAJR","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n\r\n:host {\r\n    position: relative;\r\n    display: flex;\r\n    flex: 1 1 auto;\r\n    width: 100%;\r\n    height: 100%;\r\n    min-width: 0;\r\n\r\n    .theme-options-button {\r\n        position: absolute;\r\n        top: 160px;\r\n        right: 0;\r\n        width: 48px;\r\n        height: 48px;\r\n        line-height: 48px;\r\n        text-align: center;\r\n        cursor: pointer;\r\n        border-radius: 0;\r\n        margin: 0;\r\n        pointer-events: auto;\r\n        opacity: .90;\r\n        z-index: 998;\r\n\r\n        mat-icon {\r\n            animation: rotating 3s linear infinite;\r\n        }\r\n\r\n        &:hover {\r\n            opacity: 1;\r\n        }\r\n    }\r\n\r\n    .theme-options-sidebar {\r\n        width: 360px;\r\n        min-width: 360px;\r\n        max-width: 360px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
