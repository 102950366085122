import * as Sentry from '@sentry/angular-ivy';
import {brand, furtherLinks} from './settings';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, inject, NgModule} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterModule,
    Routes,
} from '@angular/router';
import {
    ActivitiesModule,
    LessonsModule,
    ThemesModule,
} from '@modules/activities';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {
    CommunicationCenterModule,
    CommunicationCenterService,
} from '@modules/communication-center';
import {DataEntity, OctopusConnectModule} from 'octopus-connect';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import {
    LangChangeEvent,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
    MatLegacyDialogModule as MatDialogModule,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {Observable, combineLatest} from 'rxjs';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {
    addRoleLicenceSuffixForExternalLink,
    baseAppUrl,
    defaultApiURL,
    defaultNodeURL,
    enableRgpdLinks,
    externalUrl
} from './settings';
import {filter, map, mergeMap, startWith} from 'rxjs/operators';

import {AccountManagementModule} from '@modules/account-management';
import {Angulartics2Module} from 'angulartics2';
import {AppComponent} from 'app/app.component';
import {AssignationModule} from '@modules/assignation';
import {AuthenticationModule} from '@modules/authentication/core/authentication.module';
import {AuthenticationService} from '@modules/authentication';
import {AuthorizationService} from '@modules/authorization';
import {BdModule} from '@modules/bdtool/core/bd.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserTestModule} from '@modules/browser-test';
import {ConfigurationModule} from '@modules/configuration';
import {CorpusModule} from '@modules/corpus';
import {DashboardModule} from '@modules/dashboard';
import {DataVisualizationModule} from '@modules/data-visualization';
import {DatacardService} from 'shared/datacard.service';
import {DefaultRouteComponent} from 'fuse-core/components/default-route/default-route.component';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';
import {EventService} from 'shared/event.service';
import {FaqModule} from '@modules/faq';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {GamecodeModule} from '@modules/gamecode';
import {GamificationModule} from './@modules/gamification/core/gamification.module';
import {GlobalAsyncRules, GlobalSyncRules} from 'shared/models';
import {GraphAssignationModule} from '@modules/graph-assignation/core/graph-assignation.module';
import {GraphHumanumModule} from '@modules/graph-humanum/core/graph-humanum.module';
import {GraphBayardModule} from '@modules/graph-bayard/core/graph-bayard.module';
import {GroupsManagementModule} from '@modules/groups-management';
import {HeaderModule} from './header/header.module';
import {HomePageComponent} from 'fuse-core/components/home-page/home-page.component';
import {HttpClientModule} from '@angular/common/http';
import {IdeasWallModule} from '@modules/ideas-wall';
import {IsDefaultOrDefaultLoginRoute} from './guards/is-default-or-default-login-route';
import {IsUserAnonymous} from './guards/is-user-anonymous.class';
import {IsUserAuthorisedByRoleGuard} from './guards/is-user-authorised-by-role.guard';
import {IsUserLogged} from './guards/is-user-logged.class';
import {IsAllowedBySettings} from './guards/is-allowed-by-settings.class';
import {LayoutModule} from 'app/layout/layout.module';
import {LicensingModule} from '@modules/licensing';
import {LrsModule} from '@modules/lrs';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MindmapModule} from '@modules/mindmap';
import {MobileRedirectionModalComponent} from 'fuse-core/components/mobile-redirection-modal/mobile-redirection-modal.component';
import {NavigationModule} from './navigation/navigation.module';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {NotepadModule} from '@modules/notepad';
import {NotificationModule} from '@modules/notification';
import {SampleModule} from 'app/main/sample/sample.module';
import {ServiceInstantiator} from './service-instantiator.class';
import {SharedTranslateModule} from 'shared/shared-translate.module';
import {SvgIconList} from './themes/isc_lycee/svgIconList';
import {TimelineModule} from '@modules/timeline';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {TtsModule} from './tts/tts.module';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {layoutConfig} from 'app/fuse-config';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {CollectionComponent} from 'fuse-core/components/collection/collection.component';
import {BasicPageListAsCardsComponent} from 'fuse-core/components/basic-page/basic-page-list-as-cards/basic-page-list-as-cards.component';
import {ContestModule} from '@modules/contest';
import {LessonEditionModule} from '@modules/lesson-edition';
import {InformationsLegalesComponent} from 'fuse-core/components/informations-legales/informations-legales.component';
import {ActivityEditionModule} from '@modules/activity-edition';
import {CompassModule} from 'fuse-core/components/compass/compass.module';
import {FuseNavigationItem} from "fuse-core/types";

const appRoutes: Routes = [
    {
        path: '',
        component: DefaultRouteComponent,
        canActivate: [IsUserLogged, IsAllowedBySettings],
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'further',
        canActivate: [IsUserLogged, IsAllowedBySettings],
        children: [
            {
                path: ':alias',
                component: HomePageComponent,
                canActivate: [IsAllowedBySettings],
            }
        ]
    },
    {
        title: 'aventure',
        path: 'page/aventure',
        component: FullPageComponent,
        canActivate: [IsUserLogged, IsAllowedBySettings]
    },
    {
        path: 'page/:alias',
        component: FullPageComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'informations-legales',
        component: InformationsLegalesComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'page-as-card-list/:alias',
        component: BasicPageListAsCardsComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'collection/:alias',
        component: CollectionComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        DefaultRouteComponent,
        MaintenanceComponent,
        MobileRedirectionModalComponent,
    ],
    imports: [
        RecaptchaV3Module,
        NgHcaptchaModule.forRoot({
            siteKey: '8858d7eb-82d8-425e-9d74-e114ceca737b',
            // languageCode: 'de' // optional, will default to browser language
        }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            // enableTracing: true
        }),

        // import Angulartics2GoogleGlobalSiteTag in root ngModule
        Angulartics2Module.forRoot(),

        SharedTranslateModule,
        TranslateModule.forRoot(),

        // Material moment date module
        AuthenticationModule.forRoot(),
        NavigationModule.forRoot(),
        MatMomentDateModule,

        // Mat expansion panel
        MatExpansionModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatDialogModule,

        // Fuse shared_modules
        FuseModule.forRoot(layoutConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App shared_modules
        HeaderModule.forRoot(),
        TtsModule,
        // App shared_modules
        LayoutModule,
        SampleModule,
        GroupsManagementModule.forRoot(),
        DashboardModule.forRoot(),
        AccountManagementModule.forRoot(),
        BdModule.forRoot(),
        NotificationModule.forRoot(),
        CorpusModule.forRoot(),
        ActivitiesModule.forRoot(),
        AssignationModule.forRoot(),
        LessonsModule.forRoot(),
        LessonEditionModule.forRoot(),
        ActivityEditionModule.forRoot(),
        ThemesModule.forRoot(),
        IdeasWallModule.forRoot(),
        LicensingModule.forRoot(),
        GamificationModule.forRoot(),
        ConfigurationModule.forRoot(),
        TimelineModule.forRoot(),
        MindmapModule.forRoot(),
        GamecodeModule.forRoot(),
        NotepadModule.forRoot(),
        BrowserTestModule.forRoot({
            desktopSize: {
                minWidth: 1024,
                minHeight: 768,
            },
            browsersBlackList: [
                {
                    name: 'ie',
                    minVersion: '11',
                },
                {
                    name: 'chrome',
                    minVersion: '72',
                },
                {
                    name: 'firefox',
                    minVersion: '65',
                },
                {
                    name: 'edge',
                    minVersion: '16',
                },
                {
                    name: 'safari',
                    minVersion: '10',
                },
            ],
        }),
        CommunicationCenterModule.forRoot(),
        GraphAssignationModule.forRoot(),
        DataVisualizationModule.forRoot(),
        OctopusConnectModule.forRoot({
            defaultInterface: 'localstorage',
            maxRetry: 5,
            retryTimeout: 50000,
            liveRefreshService: 'refresh',
            configuration: {
                localstorage: {
                    prefix: 'test',
                },
                http: {
                    apiUrl: defaultApiURL,
                    useApiExtension: true,
                    headers: {
                        'Content-type': 'application/json',
                    },
                },
                nodejs: {
                    socketUrl: defaultNodeURL,
                },
            },
            map: {
                achievements: {
                    type: 'http',
                    useLanguage: true,
                },
                contests: 'http',
                'contest-registration': 'http',
                'contest-tickets': 'http',
                'contest-progress': 'http',
                'contest-rank': 'http',
                establishments: 'http',
                'openbadges': {
                    type: 'http',
                    useLanguage: true,
                },
                'openbadges-awards-pdf': 'http',
                'form-project': 'http',
                feedback: 'http',
                bd: 'http',
                'log-book': 'http',
                users: 'http',
                user_search: 'http',
                learners: 'http',
                trainers: 'http',
                licenses: 'http',
                migrations: {
                    type: 'http',
                    useLanguage: true,
                },
                'licenses-import': 'http',
                groups: 'http',
                institutions: 'http',
                'user-registration': 'http',
                'reset-password': 'http',
                userfiles: 'http',
                projects: 'http',
                'contact-message': 'http',
                notification: {
                    type: 'http',
                    refreshEnabled: true,
                },
                corpus: 'http',
                lesson: 'http',
                activity: 'http',
                media: 'http',
                qcm: 'http',
                assignations: {
                    type: 'http',
                    refreshEnabled: true,
                },
                'assignations-group': 'http',
                'event-assignation': 'http',
                'collections': 'http',
                granule: {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference',
                    },
                    useLanguage: true,
                },
                difficulty: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                usage: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                schoolyears: 'http',
                'granule-activity': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity',
                    },
                },
                'granule-lesson': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                },
                'granule-sequence': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                },
                'granule-form': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                },
                'granule-format': {
                    type: 'http',
                    cached: false,
                },
                regions: {
                    type: 'http',
                    cached: false,
                    useLanguage: true
                },
                tralabar_links: {
                    type: 'http',
                    cached: false,
                },
                educational_level: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                tags: {
                    type: 'http',
                    refreshEnabled: true,
                },
                bookmarks: 'http',
                'bookmarks-theme': 'http',
                consulted: 'http',
                consulted_assignation: 'http',
                evaluation: 'http',
                'corpus-text-ressource': 'http',
                'corpus-url-ressource': 'http',
                metadatas: 'http',
                'corpus-set': {
                    type: 'http',
                    embeddings: {
                        'corpus-list': 'corpus',
                    },
                },
                'research-sheets': {
                    type: 'http',
                    refreshEnabled: true,
                },
                sections: {
                    type: 'http',
                    refreshEnabled: true,
                },
                'draft-sections': {
                    type: 'http',
                    refreshEnabled: true,
                },
                'section-definitions-set': {
                    type: 'http',
                    embeddings: {
                        sections: 'section-definitions',
                    },
                },
                'section-definitions': {
                    type: 'http',
                    embeddings: {
                        readingGrids: 'reading-grid',
                    },
                },
                refresh: 'nodejs',
                comments: 'http',
                'user-progress': 'http',
                'user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity',
                    },
                },
                'last-user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity',
                    },
                },
                'user-activity': 'http',
                answer: 'http',
                'qcm-save': 'http',
                'ord-save': 'http',
                'app-save': 'http',
                'rb-save': 'http',
                genericsave: 'http',
                'reading-grids': 'http',
                'draft-questions': 'http',
                'logical-links-set': 'http',
                'logical-links': 'http',
                'ideas-wall': {
                    type: 'http',
                    refreshEnabled: true,
                },
                'wall-idea': {
                    type: 'http',
                    refreshEnabled: true,
                },
                'wall-category': {
                    type: 'http',
                    refreshEnabled: true,
                },
                ideas_wall_category: {
                    type: 'http',
                    cached: false,
                },
                'corpus_search': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference',
                    },
                    useLanguage: true,
                },
                basic_search: {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity',
                    },
                    useLanguage: true,
                },
                assignation_search: {
                    type: 'http',
                },
                'assignations-batch': {
                    type: 'http',
                },
                faq_search: {
                    type: 'http',
                    useLanguage: true,
                    //cached: false
                },
                lesson_granule_search: {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                    useLanguage: true,
                },
                themes: {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                    useLanguage: true,
                },
                theme_search: {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                    },
                    useLanguage: true,
                },
                'variables/instance': {
                    type: 'http',
                    useLanguage: true,
                },
                assignation_state: 'http',
                groups_search: {
                    type: 'http',
                },
                'variables/postalCodes': {
                    type: 'http',
                },
                chapters: {
                    type: 'http',
                    useLanguage: true,
                },
                assignation_type: {
                    type: 'http',
                    useLanguage: true,
                },
                skills: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                concepts: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                thematics: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                notions: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                authenticated: {
                    type: 'nodejs',
                },
                'join-wall': {
                    type: 'nodejs',
                },
                pages: {
                    type: 'http',
                    useLanguage: true,
                },
                'user-points': 'http',
                'badges-type': 'http',
                badges: {
                    type: 'http',
                    useLanguage: true,
                },
                levels: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                'users-import': 'http',
                'classes-import': 'http',
                threads: {
                    type: 'http',
                },
                messages: {
                    type: 'http',
                    refreshEnabled: true,
                },
                'error-reporting': {
                    type: 'http',
                    useLanguage: true,
                },
                events: {
                    type: 'http',
                    useLanguage: true,
                },
                'analytics-listing': 'http',
                timeline: {
                    type: 'http',
                },
                marker_type: {
                    type: 'http',
                    cached: false,
                    useLanguage: true,
                },
                video_marker: 'http',
                reviews: 'http',
                summary: 'http',
                'user-dashboard': 'http',
                progress: 'http',
                metrics: 'http',
                mindmaps: 'http',
                creations: 'http',
                notepads: 'http',
                homepage_lms: {
                    type: 'http',
                    useLanguage: true,
                },
                'variables/helpVideos': 'http',
                'comments-list': {
                    type: 'http',
                    useLanguage: true,
                },
                feedbacks: 'http',
                recaptchav3: {
                    type: 'http',
                },
                'statements-analytics': 'http',
                recommendations: 'http',
                'users-link': 'http',
                activity_type: 'http',
                'statements-stats': 'http',
                'learners-stat': 'http',
                'user-role-update': 'http',
                leaderboard: 'http',
                'page-table': 'http',
                'page-views': 'http',
                'survey-config': 'http',
                'survey': 'http',
                'survey-link': 'http',
                'survey-save': 'http',
                'thematique-boussole': 'http',
                'ressource-boussole': 'http',
                'level-boussole': 'http',
                'explore': 'http',
                'diagnostic': 'http',
                'skills-boussole': 'http',
                'difficulty-boussole': 'http',
                'pedago': 'http',
                'organisation': 'http',
            },
        }),
        HammerModule,
        LrsModule,
        GraphHumanumModule.forRoot(),
        GraphBayardModule.forRoot(),
        HammerModule,
        CompassModule.forRoot(),
        FaqModule.forRoot(),
        ContestModule.forRoot(),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        IsDefaultOrDefaultLoginRoute,
        IsUserLogged,
        IsUserAuthorisedByRoleGuard,
        IsUserAnonymous,
        IsAllowedBySettings,
        ServiceInstantiator,
        TralaTranslationLoaderService,
        SvgIconList,
        CommunicationCenterService,
        DatacardService,
        EventService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6Le-rdsUAAAAADbacQJe_sNQg1jxPsCsdqNcBvb1',
        },
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    public brand: string = brand;

    private dynamicNavigation = inject(DynamicNavigationService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private communicationCenter = inject(CommunicationCenterService);
    private authService = inject(AuthenticationService);
    private authorizationService = inject(AuthorizationService);
    public dialog = inject(MatDialog);

    constructor() {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    Sentry.setUser({email: data.get('email')});
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => {
                    if (route.data) {
                        return route.data;
                    } else {
                        return null;
                    }
                })
            )
            .subscribe((evt) => {
                let menuId: string;

                if (evt && evt['menu']) {
                    menuId = evt['menu'];
                } else {
                    menuId = 'level0';
                }
                this.registerDefaultLinkWithoutAuthentication();
                this.dynamicNavigation.generateMenu(menuId);
                this.dynamicNavigation.switchClassPath(menuId);
            });
    }

    getMostImportantLicenceOfUser$(): Observable<string> {
        const userLicences$: Observable<string[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('userLicenses')
            .pipe(
                map((userLicences: TypedDataEntityInterface<{ type: { label: string } }>[]) => userLicences.map(l => l.get('type')?.label)),
            );

        const userInstitutionLicences$: Observable<string[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .pipe(
                map((institutionList: { license: { type: string } }[]) => institutionList.map(i => i.license?.type)),
            );

        return combineLatest([userLicences$, userInstitutionLicences$])
            .pipe(
                map(([userLicences, userInstitutionLicences]) => [...userLicences, ...userInstitutionLicences]),
                map(licences => {
                    if (licences.includes('Institution')) {
                        return 'Institution';
                    }
                    if (licences.includes('Class')) {
                        return 'Class';
                    } else {
                        return 'Free';
                    }
                }),
            );
    }

    private registerDefaultLinkWithoutAuthentication(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'legalnotice',
            classes: 'legalnotice',
            title: 'Mentions légales',
            translate: 'navigation.legalnotice',
            type: 'item',
            icon: 'new_releases',
            url: '/page/conditions-generales',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'privacy',
            classes: 'privacy',
            title: 'Privacy',
            translate: 'navigation.privacy',
            type: 'link',
            icon: 'shield',
            url: '/page/privacy',
        });
        this.addHelpAnonymous();
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'needhelp',
            classes: 'needhelp',
            title: 'Need help',
            translate: 'navigation.needhelp',
            type: 'link',
            icon: 'help',
            url: '/',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'how-to',
            title: 'how-to',
            translate: 'navigation.how-to',
            type: 'item',
            icon: 'idea',
            url: '/page/comment-ca-marche',
            displayIcon: true,
        });

        if (!this.authService.isGAR()) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'access-future-engineer',
                title: 'access future engineer',
                translate: 'navigation.access_future_engineer',
                type: 'item',
                icon: 'access-future-engineer',
                url: externalUrl && externalUrl['access-future-engineer'],
                externalUrl: externalUrl && !!externalUrl['access-future-engineer'],
                openInNewTab: externalUrl && !!externalUrl['access-future-engineer'],
                displayIcon: true,
            });
        }

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'external-url',
            title: 'navigate to external url',
            translate: this.authService.isLearner() ? 'navigation.external-url-learner' : 'navigation.external-url',
            type: 'item',
            url: `${baseAppUrl}#/session?token=${localStorage
                .getItem('http_accessToken')
                ?.slice(1, -1)}&redirectPath=lessons/home`,
            externalUrl: true,
            openInNewTab: false,
            displayIcon: false,
            function: (event, item) => this.askConsent(event, item)
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'contact-us',
            title: 'contact us',
            translate: 'navigation.contact_us',
            type: 'item',
            icon: 'contact-us',
            url: this.urlFromSettings('contact-us').url,
            externalUrl: this.urlFromSettings('contact-us').isExternalUrl,
            openInNewTab: this.urlFromSettings('contact-us').isExternalUrl,
            displayIcon: true,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'mentions-legales',
            title: 'mentions-legales',
            translate: 'navigation.legalnotice',
            type: 'item',
            icon: 'visibility_legalnotice',
            url: this.urlFromSettings('mentions-legales').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('mentions-legales').isExternalUrl,
            openInNewTab: this.urlFromSettings('mentions-legales').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'informations-legales',
            title: 'informations-legales',
            classes: 'legalnotice',
            translate: 'navigation.legal_notice',
            type: 'item',
            icon: 'policy-rounded-fill',
            url: '/informations-legales',
            displayIcon: true,
            externalUrl: false,
            openInNewTab: false,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'cgurgpd',
            classes: 'cgurgpd',
            title: 'cgurgpd',
            translate: 'navigation.cgurgpd',
            type: 'item',
            icon: 'visibility_cgurgpd',
            url: this.urlFromSettings('cgurgpd').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('cgurgpd').isExternalUrl,
            openInNewTab: this.urlFromSettings('cgurgpd').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'accessibility',
            classes: 'accessibility',
            title: 'accessibility',
            translate: 'navigation.accessibility',
            type: 'item',
            icon: 'accessibility',
            url: '/page/accessibilite',
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'contact',
            classes: 'contact',
            title: 'contact',
            translate: 'navigation.contact',
            type: 'item',
            icon: 'help',
            url: this.urlFromSettings('contact').url,
            externalUrl: this.urlFromSettings('contact').isExternalUrl,
            openInNewTab: this.urlFromSettings('contact').isExternalUrl,
        });

        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'feedback',
            classes: 'contact',
            title: 'contact',
            translate: 'navigation.contact',
            type: 'item',
            icon: 'feedback',
            url: this.authService.isGAR() ? '/page/feedback-gar' : '/page/feedback',
        });


        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'politique-confidentialite',
            classes: 'politique-confidentialite',
            title: 'politique-confidentialite',
            translate: 'navigation.privacy',
            type: 'item',
            icon: 'visibility_privacy',
            url: this.urlFromSettings('politique-confidentialite').url,
            displayIcon: true,
            externalUrl: this.urlFromSettings('politique-confidentialite').isExternalUrl,
            openInNewTab: this.urlFromSettings('politique-confidentialite').isExternalUrl,
        });
    }

    /**
     * Replace help link with appropriate url
     */
    private updateHelpLink(Urlsuffix = ''): void {
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.translate
            .get('navigation.needhelp_url' + Urlsuffix)
            .subscribe((url: string) => {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    id: 'needhelp',
                    title: 'Need help',
                    translate: 'navigation.needhelp',
                    type: 'link',
                    icon: 'help',
                    url: url,
                });
            });
    }

    private postAuthentication(): void {
        if (this.authService.isGAR()) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'access-adventure',
                title: 'access adventure',
                translate: 'navigation.access_adventure',
                type: 'item',
                icon: 'code_sand_box',
                url: 'page/aventure',
                externalUrl: false,
                openInNewTab: false,
                displayIcon: true,
            });
        }

        this.handleFurtherMenu();
        this.handleFurtherLinksAtRoot();
        this.registerDefaultLinkWithoutAuthentication();
        this.handleEducationalResourcesMenu();
        this.handleExternalButton();

        if (this.authService.isGAR()) {
            this.dynamicNavigation.clearMenuItem('level0', 'access-future-engineer');
        }


        if (this.authService.hasLevel(['learner', 'parent', 'trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'home',
                title: 'Home',
                translate: 'navigation.home',
                type: 'item',
                icon: 'home',
                url: '/home',
            });
        }

        if (this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'needhelp',
                title: 'Need help',
                translate: 'navigation.needhelp',
                type: 'link',
                icon: 'help',
                url: '/',
            });

            this.updateHelpLink();

            // boussole as faq component
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: '/faq',
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'boussole',
                title: 'boussole',
                translate: 'generic.compass',
                type: 'item',
                icon: 'compass',
                url: '/boussole',
            });

            // boussole as rise360 export
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: `${defaultApiURL}interactive/boussole/`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: true,
                function: (event, item) => this.askConsent(event, item)
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'funambuleMove',
                title: 'Manoeuvre du funambule',
                translate: 'funambule.move',
                type: 'item',
                icon: 'funambule',
                url: '/page/manoeuvre-funambule',
            });

            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'virtualClass',
                title: 'Classe virtuelle',
                translate: 'virtual.class',
                type: 'item',
                icon: 'virtual_class',
                url: '/page/classe-virtuelle',
            });

            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink();
            });
        } else if (this.authService.hasLevel(['learner'])) {
            this.updateHelpLink('_learner');
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateHelpLink('_learner');
            });
        }

        if (this.authorizationService.currentUserCan<boolean>(GlobalSyncRules.accessToEducationalSheetsAsCards, false)) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'educational-sheets-as-cards',
                title: 'global.educational-sheets-as-cards',
                translate: 'global.educational-sheets-as-cards',
                type: 'item',
                icon: 'book',
                url: 'page-as-card-list/educational-sheets-list',
            });
        }

        this.dynamicNavigation.clearMenuItem('level0', 'help_anonymous');
    }

    private getFurtherChildLinks(): FuseNavigationItem[] {
        return [
            {
                id: 'gameCodeList',
                title: 'gameCodeList',
                translate: 'generic.game_code_title',
                type: 'item',
                url: '/code/gamecode/list',
            },
            {
                id: 'gleamCodeList',
                title: 'gleamCodeList',
                translate: 'generic.gleam_code_title',
                type: 'item',
                url: '/code/gleamcode/list',
            },
            {
                id: 'brume',
                classes: 'brume',
                title: 'brume',
                translate: 'navigation.brume',
                type: 'item',
                url: '/collection/brume',
            },
            {
                id: 'activities',
                title: 'activities',
                translate: 'activities.title',
                type: 'item',
                url: '/activities/list'
            },
            {
                id: 'faq',
                title: 'faq',
                translate: 'generic.faq',
                type: 'item',
                icon: 'compass',
                url: '/faq',
            },
            {
                id: 'boussole',
                title: 'boussole',
                translate: 'generic.compass',
                type: 'item',
                icon: 'compass',
                url: '/boussole',
            },
            {
                id: 'external-dictionary',
                title: 'navigate to external url',
                translate: 'navigation.external-url-dictionary',
                type: 'item',
                url: `${baseAppUrl}#/session?token=${localStorage
                    .getItem('http_accessToken')
                    ?.slice(1, -1)}&redirectPath=activities/dictionary`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: false,
                function: (event, item) => this.askConsent(event, item)
            },
            {
                id: 'external-language-lab',
                title: 'navigate to external url',
                translate: 'navigation.external-language-lab',
                type: 'item',
                url: `${baseAppUrl}#/session?token=${localStorage
                    .getItem('http_accessToken')
                    ?.slice(1, -1)}&redirectPath=activities/language-lab`,
                externalUrl: true,
                openInNewTab: false,
                displayIcon: false,
                function: (event, item) => this.askConsent(event, item)
            },
            ...this.getFurtherChildLinksFromSettings()
        ];
    }

    private postLogout(): void {
        this.registerDefaultLinkWithoutAuthentication();
        this.dynamicNavigation.clearMenuItem(
            'level0',
            'external-target-link-menu'
        );
        this.dynamicNavigation.clearMenuItem('level0', 'home');
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.dynamicNavigation.clearMenuItem('level0', 'funambuleMove');
        this.dynamicNavigation.clearMenuItem('level0', 'virtualClass');
        this.dynamicNavigation.clearMenuItem('level0', 'boussole');
        this.dynamicNavigation.clearMenuItem('level0', 'faq');
        this.dynamicNavigation.clearMenuItem('level0', 'educational-sheets-as-cards');
        this.dynamicNavigation.clearMenuItem('level0', 'educational_resources');
        this.dynamicNavigation.clearMenuItem('level0', 'forFurther');
        this.dynamicNavigation.clearMenuItem('level0', 'informations-legales');
        furtherLinks.forEach(l => this.dynamicNavigation.clearMenuItem('level0', 'root_' + l.label));
        if (!enableRgpdLinks) {
            this.dynamicNavigation.clearMenuItem('level0', 'mentions-legales');
            this.dynamicNavigation.clearMenuItem('level0', 'cgurgpd');
            this.dynamicNavigation.clearMenuItem(
                'level0',
                'politique-confidentialite'
            );
            this.dynamicNavigation.clearMenuItem('level0', 'accessibility');
            this.addHelpAnonymous();
        }
    }

    private askConsent($event, item): void {
        $event.preventDefault();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: 'activities.redirect_' + this.authService.accessLevel + '_to_app',
            labelTrueDialog: 'generic.confirm',
            labelFalseDialog: 'generic.cancel'
        };

        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        const modalRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        modalRef.afterClosed().subscribe(result => {
            if (result === true) {
                window.open(item.url, item.openInNewTab ? '_blank' : '_self'); // Redirige l'utilisateur si le résultat est true
            }
        });
    }

    /**
     * Add link to helper resources just for unlogged user
     * @private
     */
    private addHelpAnonymous(): void {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'help_anonymous',
            title: 'help',
            translate: 'navigation.general_help',
            type: 'item',
            icon: 'help',
            url: '/page/aide-aux-ressources',
        });
    }

    private urlFromSettings(id: string): { url: string, isExternalUrl: boolean } {
        const urls = {
            'cgurgpd': '/page/cgurgpd',
            'mentions-legales': '/page/mentions-legales',
            'politique-confidentialite': '/page/politique-confidentialite',
            'contact': '/feedback', // default value
        };

        const garUrls = {
            'cgurgpd': '/page/cgurgpd-gar',
            'mentions-legales': '/page/mentions-legales-gar',
            'politique-confidentialite': '/page/politique-confidentialite-gar',
            'contact-us': '/page/contact-us',
            'contact': '/feedback', // default value
        };

        const sapiensUrls = {
            'contact': 'https://unstoppable-us.com/contact', // contact for sapiens is an external website
        };

        if (this.authService.isGAR()) {
            if (garUrls[id]) {
                return {url: garUrls[id], isExternalUrl: false};
            }
        } else if (this.brand === 'sapiens' && sapiensUrls[id]) {
            return {url: sapiensUrls[id], isExternalUrl: true};
        } else if (urls[id]) {
            return {url: urls[id], isExternalUrl: false};
        }

        if (externalUrl && externalUrl[id]) {
            return {url: externalUrl[id], isExternalUrl: true};
        }

        return {url: '', isExternalUrl: undefined};
    }

    private getFurtherChildLinksFromSettings(): FuseNavigationItem[] {
        return furtherLinks.map(l => (
            {
                id: 'further_' + l.label,
                classes: l.label,
                title: l.label,
                translate: 'navigation.' + l.label,
                type: 'item',
                url: l.url,
            }
        ))
    }

    private handleFurtherMenu() {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'forFurther',
            title: 'Pour aller plus loin',
            translate: 'generic.for_further',
            type: 'collapse',
            icon: 'for_further',
            children: this.getFurtherChildLinks(),
        });
    }

    private handleEducationalResourcesMenu() {
        this.dynamicNavigation.refreshModuleMenu('level0', {
            id: 'educational_resources',
            title: 'Ressources pédagogiques',
            translate: 'generic.educational_resources',
            type: 'collapse',
            icon: 'educational-resources',
            children: [
                {
                    id: 'ressources_humanum',
                    classes: 'ressources_humanum',
                    title: 'ressources_humanum',
                    translate: 'navigation.ressources_humanum',
                    type: 'item',
                    url: '/further/ressources-partenaires-ilove',
                },
                {
                    id: 'ressources_supplementaires',
                    classes: 'ressources_supplementaires',
                    title: 'ressources_supplementaires',
                    translate: 'navigation.ressources_supplementaires',
                    type: 'item',
                    url: '/further/ressources-supplementaires',
                }
            ],
        });
    }

    private handleExternalButton() {
        combineLatest([
            this.authorizationService.currentUserCan<Observable<boolean>>(GlobalAsyncRules.accessToExternalLinkButton),
            this.getMostImportantLicenceOfUser$().pipe(startWith('free')),
        ]).pipe(
            filter(([can, _licence]) => can),
        ).subscribe(([_can, licence]) => {
            let suffix = '';
            if (addRoleLicenceSuffixForExternalLink) {
                suffix = '_' + this.authService.accessLevel + '_' + licence;
            }
            this.translate
                .get('navigation.external-target-link-menu-url')
                .subscribe((translation: string) => {
                    this.dynamicNavigation.refreshModuleMenu('level0', {
                        id: 'external-target-link-menu',
                        classes: 'external-target-link-menu' + suffix,
                        title: 'Lien externe',
                        translate:
                            'navigation.external-target-link-menu' + suffix,
                        type: 'item',
                        icon: 'external-target-link-menu-icon',
                        url: translation,
                        externalUrl: true,
                        openInNewTab: true,
                        displayIcon: true,
                    });
                });
        });
    }

    private handleFurtherLinksAtRoot() {
        furtherLinks.forEach(l => {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                id: 'root_' + l.label,
                title: l.label,
                translate: 'navigation.' + l.label,
                type: 'item',
                url: l.url,
            });
        });
    }
}
