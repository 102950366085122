export const activities = {
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti']
    },
    activitiesDisplayedColumns: ['title', 'educationalLevel', 'chapters', 'action'],
    allowErrorReporting: false,
    APIPEMTask: true,
    addExitButton: true, // add exit button for iframe lesson
    cardFieldsForPreview: ['chapters', 'description'],
    cardLayout: 'card-split-media-info',
    canSelectActivities: true, // can select activities when assign lesson
    displayFilters: false,
    showAddLessonButtonCard: true,
    hideAddButtonLessonList: true,
    hideUserActionButtons: true,
    hiddenActivityPreview: false,
    isOnboardingEnabled: false,
    isOnlyModelLesson: true, // should be called by example : enableSelectionByTab for lesson list component
    latexKeyboard: false,
    lessonStep: null,
    loadLessonWithSublesson: {
        typology: null,
        multi_step: 0
    },
    openDialogInfoAfterDuplicateLesson: {
        manager: false,
        default: true
    },
    progress: false,
    searchFields: {
        default: {
            default: ['chapters', 'chapters-toogle'] // we use chapter as toogle button
        }
    },
    showLessonTitle: false,
    stepper: null,
    allowedRolesForModelsAssignation: ['manager', 'director', 'trainer'],
    allowedActivityTypes: [],
    // required params name for partner through iframe url
    iframeParams: [],
    dynamicContent: false,
    menu: {
        models: true
    },
    filtersEditorActivitiesList: ['title', 'educationnalLevel', 'chapters'],
    lessonDialogRequiredFields: {
        default: ['title', 'educationalLevel'],
    },
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: true // si chaque activités ou souslesson du parcours doit être sauvegardé
    },
    typeActivitiesToSkip: ['EXT'], // (lesson player) skip activity if activity's type is included,
    displayLearnerInfo: false,
    recommendationDisplayedColumns: ['checkbox', 'pseudo', 'lesson', 'level', 'confidence', 'buttons'],
    shouldSetDefaultOptionsOnActivityList: true,
    setAnswerWithUserSave: true, // à l'initialisation d'une activité, inscrit les réponses presente dans l'user-save
    accessMatrix: {
        activitiesListing: {
            view: ['trainer', 'manager', 'administrator'],
        },
        lessonsListing: {
            view: ['learner', 'trainer', 'manager', 'administrator'],
        }
    },
    // if page list of activities must be displayed
    isActivitiesListMustBeDisplayed: true,
    isPercentileDisplayAllowed: false,
    displayTipsInListOfActivities: true,
};
