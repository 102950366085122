import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-edition-grid',
  templateUrl: './activity-edition-grid.component.html',
  styleUrls: ['./activity-edition-grid.component.scss']
})
export class ActivityEditionGridComponent {

}
