import {Component, Input} from '@angular/core';
import {MatchingAnswer} from '@modules/activity-edition/core/features/activity-editor-panel/answerTypes';

@Component({
    selector: 'app-activity-edition-preview-text-matching',
    templateUrl: './activity-edition-preview-text-matching.component.html',
    styleUrls: ['./activity-edition-preview-text-matching.component.scss']
})
export class ActivityEditionPreviewTextMatchingComponent {
    @Input() answersState!: MatchingAnswer;

    public getPairs() {
        return this.answersState.firstElements[this.answersState.firstType].map((firstElement, index) =>
            ([
                firstElement,
                this.answersState.secondElements[this.answersState.secondType][index]
            ]));
    }
}
