import {Component, OnInit, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE} from '../base-activity.component';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {PointImgActivityGranule} from '@modules/activities/core/models/activities/typologies/point-img-activity.granule';

@Component({
    templateUrl: './image-zoning-abstract.component.html',
})

/**
 * common code two almost two component
 * 1- image-zoning : some zone inside the image are true when you click on it they will become green
 * 2- image-zoning-playing-sound click on zone visible and a mp3 sound will be played user can go to next after listenning all
 * be carreful this code is common and musn't be used has a component directly you must make a component
 * and inherit of it and add in the created component the specific logic and html
 */
export class ImageZoningAbstractComponent extends BaseActivityComponent<PointImgActivityGranule> implements OnInit {

    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    // public displaySaveBtn = false; // Boolean know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public showSubInstruction: boolean;
    public title: string;
    public containerClass = 'container';
    public minAnswers; // minimum answers needed to consider exo is finish

    protected setContentData(activityAttributes): void {
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {
            this.minAnswers = activityAttributes.reference.config.minAnswers ? activityAttributes.reference.config.minAnswers : null;
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
    }

    /**
     * a zone was clicked we get the answer corresponding at the zone
     * @param answer : answer clicked
     * @param autoSave : autoSave if all was done and go to next activity automaticly
     * @param changeStateAnswerOnlyAtEnd : changeStateAnswerOnlyAtEnd if true progress bar will move only at end of exo
     */
    public zoneClickEvent(answer: AnswerInterface, autoSave = true, changeStateAnswerOnlyAtEnd = false): void {
        this.answersSelected.find(a => a.id === answer.id).select = true;
        this.containerClass = this.answersSelected.find(a => a.id === answer.id).correct_answer ? 'container true-answer' : 'container false-answer';
        // init object for save
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined
        };

        if (this.answersSelected.length) {
            answerResult.state = this.answersSelected.find(a => a.id === answer.id).correct_answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.incorrect;
        }
        answerResult.isLast = this.allAnswerCorrect();

        if (!this.allAnswerCorrect()
            && !(answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect || answerResult.state === ItemAnswerStateEnum.missing)
            && answer.feedback) {
            super.feedbackEndExoOpenModal({title: 'activities.title_modal_bad_answer_help', content: answer.feedback});
        }
        // some exo not need feedback during exo but only at end to say it was done
        if (changeStateAnswerOnlyAtEnd && answerResult.isLast) {
            super.manageProgressBarEventToSend(answerResult);
        }
        // always move progress bar for each answer made
        if (!changeStateAnswerOnlyAtEnd) {
            super.manageProgressBarEventToSend(answerResult);
        }

        setTimeout(() => {
            if (this.allAnswerCorrect() && autoSave) {
                this.doAction('next', ['save']);
            } else {
                this.containerClass = 'container';
            }
        }, TIME_DELAY_BEFORE_SAVE);

        // store the answer status of current answer
        this.answerStatus = answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect ? answerStatusEnum.correct : answerStatusEnum.wrong;

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    /**
     * init array of answers
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    protected setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.answersSelected = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                select: resetSelectEd ? false : !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                audio: answer.audio ? answer.audio : null
            };
        });
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.setDefaultAnswersAvailable(this.answersSelected, true);
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * check that all answers are done
     * @private
     */
    public allAnswerCorrect(): boolean {
        // number of good answer is pass in config if all good answer are not needed
        if (this.minAnswers !== null && this.minAnswers !== undefined) {
            return this.answersSelected.filter((option: AnswerInterface) => option.select && option.correct_answer).length >= +this.minAnswers;
        } else {
            return !this.answersSelected.find((option: AnswerInterface) => !option.select && option.correct_answer);
        }
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: AnswerInterface) => answer.select && answer.correct_answer).length,
            oldGrade: this.userSave ?
                this.userSave.get('userActivity').entitySave.answers.filter((answer: AnswerInterface) =>
                    answer.select && answer.correct_answer).length : 0
        };
    }

    /**
     * no need to create answer because answer already exist.
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }


    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}

