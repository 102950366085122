import {Component, inject} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {DataEntity} from 'octopus-connect';
import {map, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {CompassThemeEntity} from "fuse-core/services/compassThemeEntity";
import {usingLoading} from "shared/utils/rxjs";

@Component({
    selector: 'app-compass',
    templateUrl: './compass.component.html',
})
export class CompassComponent extends AutoUnsubscribeTakeUntilClass {

    private compassService = inject(CompassService);
    private router = inject(Router);

    private themesAreLoading = true;
    private exploreDataIsLoading = true;
    private compassHelperIsLoading = true;


    public themes$ = this.compassService.loadThemes().pipe(take(1), usingLoading(isLoading => this.themesAreLoading = isLoading));
    public themesPromoted$ = this.themes$.pipe(map(themes => themes.filter(theme => theme.get('promoted') === '1')));
    public exploreData$ = this.compassService.loadExploreData().pipe(take(1), usingLoading(isLoading => this.exploreDataIsLoading = isLoading));
    public compassHelper$ = this.compassService.fetchCompassHomepage().pipe(take(1), usingLoading(isLoading => this.compassHelperIsLoading = isLoading))

    public seeResources(theme: CompassThemeEntity): void {
        if (theme?.get('ressources')?.length > 0) {
            this.router.navigate(['boussole-ressources'],
                {
                    state: {
                        id: theme?.get('ressources'),
                        selectedThemeTitle: theme.get('label'),
                        selectedThemeBody: theme.get('body')
                    }
                });
        }
    }

    /**
     * cette fonction permet de voir les themes
     * @param theme
     */
    public seeThemes(theme: DataEntity): void {
        this.router.navigate(['boussole-themes', theme.id]);
    }

    public isLoading() {
        return this.compassHelperIsLoading || this.exploreDataIsLoading || this.themesAreLoading;
    }
}

