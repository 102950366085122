
import {DataEntity} from 'octopus-connect';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CorpusService} from '@modules/corpus';
import {Corpus} from "@modules/corpus/core/corpus.class";
import {FuseNavigationItem} from 'fuse-core/types';

export class CorpusSet {

    public spaces: {[key: string]: DataEntity} = {};
    public corpusArray: Corpus[] = [];
    public id: number;
    public project: string;

    constructor(
        private dynamicMenu: DynamicNavigationService,
        private corpusService: CorpusService,
        private setEntity: DataEntity
    ) {
        // this.loadSpaces();
        this.id = +setEntity.id;
        this.project = setEntity.get('project');

        (<DataEntity[]>setEntity.getEmbed('corpus-list')).forEach(entity => {
            this.spaces[entity.get('name')] = entity;
            this.corpusArray.push(new Corpus(this.corpusService, entity));
        });
    }

    activateMenu() {
        const menuItems: FuseNavigationItem[] = [];

        if (this.spaces) {
            for (const key in this.spaces) {
                if (this.spaces.hasOwnProperty(key)) {
                    menuItems.push({
                        'id'   : key,
                        'title': key,
                        'translate': key,
                        'type' : 'item',
                        'url'  : '/corpus/' + this.spaces[key].id
                    });
                }
            }
        }

        this.dynamicMenu.setChildren('level0', 'corpus', menuItems);
    }

    remove() {
        this.setEntity.remove();

        if (this.spaces) {
          for (const key in this.spaces) {
            if (this.spaces.hasOwnProperty(key)) {
              this.spaces[key].remove();
            }
          }
        }
    }
}
