import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {CommunicationCenterService} from '@modules/communication-center';
import {map, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GlobalAsyncRules, GlobalSyncRules} from 'shared/models';
import {RolesEnum} from 'shared/models/roles';

@Injectable({
    providedIn: 'root'
})
export class GlobalAuthorizationService {

    private shouldDisplayExternalLinkDemands = [];

    constructor(private authorization: AuthorizationService,
                private communicationCenter: CommunicationCenterService) {
        this.authorization.addRule(GlobalAsyncRules.accessToExternalLinkButton, () =>
            this.listenShouldDisplayExternalLinksDemands().pipe(
                tap((shouldDisplayDemand: { id: string, shouldDisplay: boolean }) => this.refreshShouldDisplayExternalLinksDemands(shouldDisplayDemand)),
                map(() => this.mustAuthorizeExternalLinks())
            )
        );

        this.authorization.addRoleRule(GlobalSyncRules.accessToEducationalSheetsAsCards, [RolesEnum.trainer]);
    }

    private mustAuthorizeExternalLinks(): boolean {
        const someFalse = this.shouldDisplayExternalLinkDemands.some((demand) => demand.shouldDisplay === false);
        const someTrue = this.shouldDisplayExternalLinkDemands.some((demand) => !!demand.shouldDisplay);
        return someTrue && !someFalse;
    }

    private refreshShouldDisplayExternalLinksDemands(shouldDisplayDemand: { id: string; shouldDisplay: boolean }): void {
        this.shouldDisplayExternalLinkDemands = [
            shouldDisplayDemand,
            ...this.shouldDisplayExternalLinkDemands.filter((demand) => demand.id !== shouldDisplayDemand.id)];
    }

    private listenShouldDisplayExternalLinksDemands(): Subject<{ id: string; shouldDisplay: boolean }> {
        return this.communicationCenter
            .getRoom('global-authorization')
            .getSubject(GlobalAsyncRules.accessToExternalLinkButton);
    }
}
