import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProgressionComponent} from 'fuse-core/components/progression/progression.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {FlexModule} from '@angular/flex-layout';
import {MatLegacyProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';


@NgModule({
    declarations: [
        ProgressionComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        FlexModule,
        MatLegacyProgressSpinnerModule
    ],
    providers: [],
})
export class ProgressionModule { }
