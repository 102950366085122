import {Component} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {
    ImageZoningAbstractComponent
} from '@modules/activities/core/player-components/image-zoning-base/image-zoning-abstract.component';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {TIME_DELAY_BEFORE_SAVE} from '@modules/activities/core/player-components/base-activity.component';

@Component({
    selector: 'app-image-zoning',
    templateUrl: './image-zoning.component.html'
})
/**
 * some zone inside the image are true when you click on it they will become green
 */
export class ImageZoningComponent extends ImageZoningAbstractComponent {

    protected setContentData(activityAttributes): void {
        this.containerClass = 'container false-answer';
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {
            this.minAnswers = activityAttributes.reference.config.minAnswers ? activityAttributes.reference.config.minAnswers : null;
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.instructionAudio = activityAttributes.reference.instructionAudio;
        this.wordingAudio = activityAttributes.reference.wordingAudio;
        this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
    }

    /**
     * a zone was clicked we get the answer corresponding at the zone
     * @param answer : answer clicked
     */
    public zoneClickEvent(answer: AnswerInterface): void {
        this.answersSelected.find(a => a.id === answer.id).select = true;
        this.containerClass = this.answersSelected.find(a => a.id === answer.id).correct_answer ? 'container true-answer' : 'container false-answer';
        // init object for save
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined
        };
        if (this.answersSelected.length) {
            answerResult.state = !!this.answersSelected.find(a => a.id === answer.id).correct_answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.incorrect;
        }
        answerResult.isLast = this.allAnswerCorrect();

        if (!(answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect) && answer.feedback) {
            super.feedbackEndExoOpenModal({title: 'activities.title_modal_bad_answer_help', content: answer.feedback});
        }

        super.manageProgressBarEventToSend(answerResult);
        setTimeout(() => {
            if (this.allAnswerCorrect()) {
                this.doAction('next', ['save']);
            } else {
                this.containerClass = 'container';
            }
        }, TIME_DELAY_BEFORE_SAVE);

        // store the answer status of current answer
        this.answerStatus = (answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect) ? answerStatusEnum.correct : answerStatusEnum.wrong;

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    /**
     * init array of answers
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    protected override setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.answersSelected = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                select: resetSelectEd ? false : !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: AnswerInterface) => answer.select && answer.correct_answer).length,
            oldGrade: this.userSave ?
                this.userSave.get('userActivity').entitySave.answers.filter((answer: AnswerInterface) =>
                    answer.select && answer.correct_answer).length : 0
        };
    }

    /**
     * no need to create answer because answer already exist.
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }
}
