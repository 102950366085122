export const infoLegales = {
    // contain name of endpoint and icon to use order of item is the order of the array
    pagesGar: [
        {id: 'mentions-legales-gar', icon: 'policy-rounded-fill'},
        {id: 'cgurgpd-gar', icon: 'article-rounded-fill'},
        {id: 'politique-confidentialite', icon: 'lock-rounded-fill'},
        {id: 'accessibilite', icon: 'accessibility-rounded'},
        {id: 'feedback', icon: 'markunread-mailbox-rounded-fill'}],
    pagesNotGar: [
        {id: 'mentions-legales', icon: 'policy-rounded-fill'},
        {id: 'cgurgpd', icon: 'article-rounded-fill'},
        {id: 'politique-confidentialite', icon: 'lock-rounded-fill'},
        {id: 'accessibilite', icon: 'accessibility-rounded'},
        {id: 'feedback', icon: 'markunread-mailbox-rounded-fill'}],
};