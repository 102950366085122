// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: solid 1px black;
  height: 100%;
}

.color_0 {
  background-color: red;
}

.color_1 {
  background-color: green;
}

.color_2 {
  background-color: blue;
}

.color_3 {
  background-color: yellow;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-zone-generator/activity-edition-point-img-zone-generator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAGA;EACI,kBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".grid-item {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: white;\r\n    border: solid 1px black;\r\n    height: 100%;\r\n}\r\n\r\n.color_0 {\r\n    background-color: red;\r\n}\r\n\r\n.color_1 {\r\n    background-color: green;\r\n}\r\n\r\n.color_2 {\r\n    background-color: blue;\r\n}\r\n\r\n.color_3 {\r\n    background-color: yellow;\r\n}\r\n\r\n\r\n.container {\r\n    position: relative;\r\n    width: 100%;\r\n}\r\n\r\n.image {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.overlay {\r\n    position: absolute;\r\n    opacity: 0.8;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
