import {MergeTypes} from 'shared/merge';
import {ActivityGranuleAttributes} from '@modules/activities/core/models';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

export type UpdatableActivityGranuleAttributes = MergeTypes<[
    ActivityGranuleAttributes,
    { reference: string | number },
    { format: string | number }
]>;

export type UpdatableActivityGranule = TypedDataEntityInterface<UpdatableActivityGranuleAttributes>
