import {Component} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';
import {ActivityContent, ActivityGranule, ActivityMetaEntityAttributes} from '@modules/activities/core/models';
import {FileGranuleAttributes} from 'shared/models/granule';

export type DividerActivityGranule = ActivityGranule<ActivityContent, unknown>;

@Component({
    selector: 'app-divider',
    templateUrl: './divider.component.html'
})
export class DividerComponent extends BaseActivityComponent<DividerActivityGranule> {
    public resourceURI: string;
    public description: string;
    public filemime: string;

    //Todo là c'est n'importe quoi, faut rectifier le tir ou supprimer le composant
    public video: ActivityMetaEntityAttributes;

    protected setContentData(activityAttributes: DividerActivityGranule['attributes'] ): void {
        this.video = activityAttributes.metadatas;
        this.description = activityAttributes.metadatas.description;

        //Todo là aussi c'est n'importe quoi, faut rectifier le tir ou supprimer le composant
        const reference = <FileGranuleAttributes><any>activityAttributes.reference as FileGranuleAttributes;

        this.filemime = reference.filemime;
        this.resourceURI = reference.uri;

        this.activitiesService.saveUserSave(this.activity.id.toString(), this.activitiesService.currentAssignmentID, [], 1, 'divider-save');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
