import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {mergeMap, Observable, of, ReplaySubject, Subject} from "rxjs";
import {DataCollection, DataEntity, OctopusConnectService} from "octopus-connect";
import {map, take} from "rxjs/operators";
import {LessonGranuleEntity, NavigateToLessonOptions} from '@modules/activities/core/models';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
    private currentAssignment: DataEntity;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((assignment: DataEntity) => {
                this.currentAssignment = assignment;
            });
    }

    public loadLesson(lessonId: number, callbackSubject$: Subject<Observable<LessonGranuleEntity>>): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: lessonId,
                callbackSubject: callbackSubject$,
            });
    }

    public loadLesson$(lessonId: number): Observable<LessonGranuleEntity> {
        const callbackLesson$ = new ReplaySubject<Observable<LessonGranuleEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: lessonId,
                callbackSubject: callbackLesson$,
            });

        return callbackLesson$.pipe(
            mergeMap(lesson$ => lesson$),
        );
    }

    public loadActivities(lesson: LessonGranuleEntity, callbackSubject$: Subject<Observable<DataEntity[]>>): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('getActivities', {
                lesson: lesson,
                callbackSubject: callbackSubject$
            });
    }

    public loadUserSaves(): Observable<DataEntity[]> {
        if (this.currentAssignment) {
            return this.octopusConnect.loadCollection('user-save', {context: this.currentAssignment.id})
                .pipe(
                    take(1),
                    map((dataCollection: DataCollection) => dataCollection.entities)
                )
        }

        return of([]);
    }

    public navigateToLesson(lesson: LessonGranuleEntity, options: NavigateToLessonOptions, preview = false): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('navigateToLesson', {
                lesson: lesson,
                navigateOptions: options,
                preview: preview,
            });
    }
}
