import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-video-time',
  templateUrl: './video-time.component.html',
  styleUrls: ['./video-time.component.scss']
})
export class VideoTimeComponent implements AfterViewInit {
    @Input() src!: string;

    @ViewChild('videoElement') video: ElementRef<HTMLVideoElement>;

    public duration = 0;

    private changeDetectorRef = inject(ChangeDetectorRef);

    public ngAfterViewInit(): void {
        this.video.nativeElement.addEventListener('loadedmetadata', () => {
            this.duration = this.video.nativeElement.duration;
            this.changeDetectorRef.detectChanges();
        })
    }
}
