import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {GraphConfig} from 'fuse-core/components/graph/graph-mixed/graph.config';
import {GraphBayardService} from '../../services/graph-bayard.service';
import {delay, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FlattenedProgressData, ProgressData} from '@modules/graph-bayard/core/model/progress-data';
import {CustomTooltipModalContentSettings} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';
import * as moment from 'moment';
import {rgbOpacity, twoDecimalIfNeeded} from 'shared/utils';
import {GraphBayardTranslateService} from '@modules/graph-bayard/core/services/graph-bayard-translate.service';
import {FakeStepperOptions} from '@modules/graph-bayard/core/component/fake-stepper/fake-stepper.component';

const simpleColor = 'rgb(132,210,255)';
@Component({
    selector: 'app-progress-graph',
    templateUrl: './progress-graph.component.html',
})
export class ProgressGraphComponent implements OnInit, OnDestroy {

    public graphConfigObs: ReplaySubject<GraphConfig> = new ReplaySubject<GraphConfig>(1);
    public isReady = false;
    /**
     * Cache le loader si le service n'est pas encore pret
     */
    public showLoader = false;
    private unsubscribeInTakeUntil = new Subject<void>();
    /**
     * Aide a déterminer le temps entre les steps du loader
     * @private
     */
    private loaderShouldEnd = false;
    /**
     * Défini les steps du loader, le temps entre les stepsn, etc.
     */
    public loaderOptions: Partial<FakeStepperOptions> = {
        loop: false,
        interval: stateIndex => (this.loaderShouldEnd === false || stateIndex <= 2 ? 4000 : 1000),
        states: ['fake_stepper.state_1', 'fake_stepper.state_2', 'fake_stepper.state_3'],
        onEnd: new ReplaySubject(1)
    };
    public empty = false;

    constructor(private graphBayardService: GraphBayardService, public graphBayardTranslateService: GraphBayardTranslateService) {
    }

    ngOnInit(): void {
        this.graphBayardService.isReady
            .pipe(
                filter(isReady => !!isReady),
                tap(() => this.graphBayardService.graphDataArePending.subscribe(() => this.isReady = false)),
                mergeMap(() => this.graphBayardService.getProgressGraphData()),
                tap(() => this.showLoader = true),
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((data) => {
            this.loaderShouldEnd = true;
            if(data.entities.length === 0) {
                this.empty = true;
                this.graphConfigObs.next(null);
            } else {
                this.empty = false;
                this.generateSimpleGraph(data);
            }
            // Ne retirez pas de delay, sinon le timing foire et ça ne marche pas (je pense que le graphDataArePending est trigger trop tot)
            this.loaderOptions.onEnd.pipe(take(1), delay(750)).subscribe(() => this.isReady = true);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private generateSimpleGraph(progressData: ProgressData): void {
        const chartLabels: string[] = [];
        const dots: number[] = [];
        const dotsDetails: CustomTooltipModalContentSettings[] = [];
        progressData.dots
            .filter(dot => (progressData.graphFilters.lessonList ||[]).includes(dot.questionSetId))
            .filter(dot => dot.goodAnswers + dot.badAnswers !== 0)
            .forEach((dot, index) => {
                chartLabels.push(moment(+dot.date, 'X').format('DD/MM/YYYY'));
                dots.push(dot.goodAnswersPercent);

                dotsDetails.push({
                    header: `<label>${dot.questionSetLabel}</label>`,
                    content: this.generateModalContentHtml(dot, progressData.dots)
                });
            });

        this.graphConfigObs.next({
            chartLabels,
            chartConfig: {
                scales: {
                    y: {
                        beginAtZero: true,
                        max: 100,
                        min: 0,
                        offset: false
                    },
                    x: {
                        offset: false,
                    }
                },
                plugins: {
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        onClick: event => event.native.stopPropagation()
                    }
                }
            },
            chartData: [{
                data: dots,
                type: 'line',
                fill: true,
                label: this.graphBayardTranslateService.get('generic.pourcent_success'),
                backgroundColor: rgbOpacity(simpleColor, '0.3'),
                borderColor: simpleColor,
            }],
            modalContent: [dotsDetails],
        });
    }

    private generateModalContentHtml(currentDot: FlattenedProgressData, dotList: FlattenedProgressData[]): string {
        const listOfSimilarDots = dotList.filter(dot => dot.questionSetId === currentDot.questionSetId);
        const indexOfCurrentDot = listOfSimilarDots.findIndex(dot => dot.date === currentDot.date) + 1;
        const totalOfDots = listOfSimilarDots.length;

        return `<ul>
    <li>${moment(+currentDot.date, 'X').format('DD/MM/YYYY HH:mm')}</li>
    <li>${this.graphBayardTranslateService.get('generic.pourcent_success')} : ${twoDecimalIfNeeded(currentDot.goodAnswersPercent)} %</li>
    <li>${this.graphBayardTranslateService.get('graph_bayard.number_of_attempts')} : ${indexOfCurrentDot}/${totalOfDots}</li>
</ul>`;
    }

}
