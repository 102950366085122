// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

fuse-corpus-file-list, app-corpus-display {
    width: 100%;



    .mat-header-cell, .mat-cell {
        flex-basis: 50px;
        align-content: center;
        justify-content: center;
    }
    .mat-column-icon, .mat-column-ownerName,
    .mat-column-changedDate, .mat-column-used {
        flex: 1 0;
    }
    .mat-column-title, .mat-column-group, .mat-column-chapter, .mat-column-concept {
        flex: 3 0 20px;
        justify-content: left;
    }
    .mat-column-changedDate {
        flex-basis: 20px;
    }
    .mat-column-icon {

    }

    .mat-table {
        background: transparent;
        box-shadow: none;

        .mat-row {
            position: relative;
            cursor: pointer;
            min-height: 64px;

            .mat-cell {

                overflow: initial;

                &.mat-column-icon {
                }

                &.mat-column-detail-button {
                }
            }
        }

        .mat-header-cell {

            overflow: initial;
        }
    }
}
*/`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/corpus-file-list/corpus-file-list.component.scss"],"names":[],"mappings":"AACA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAAA","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n/*\r\n\r\nfuse-corpus-file-list, app-corpus-display {\r\n    width: 100%;\r\n\r\n\r\n\r\n    .mat-header-cell, .mat-cell {\r\n        flex-basis: 50px;\r\n        align-content: center;\r\n        justify-content: center;\r\n    }\r\n    .mat-column-icon, .mat-column-ownerName,\r\n    .mat-column-changedDate, .mat-column-used {\r\n        flex: 1 0;\r\n    }\r\n    .mat-column-title, .mat-column-group, .mat-column-chapter, .mat-column-concept {\r\n        flex: 3 0 20px;\r\n        justify-content: left;\r\n    }\r\n    .mat-column-changedDate {\r\n        flex-basis: 20px;\r\n    }\r\n    .mat-column-icon {\r\n\r\n    }\r\n\r\n    .mat-table {\r\n        background: transparent;\r\n        box-shadow: none;\r\n\r\n        .mat-row {\r\n            position: relative;\r\n            cursor: pointer;\r\n            min-height: 64px;\r\n\r\n            .mat-cell {\r\n\r\n                overflow: initial;\r\n\r\n                &.mat-column-icon {\r\n                }\r\n\r\n                &.mat-column-detail-button {\r\n                }\r\n            }\r\n        }\r\n\r\n        .mat-header-cell {\r\n\r\n            overflow: initial;\r\n        }\r\n    }\r\n}\r\n*/\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
