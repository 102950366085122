
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {takeUntil} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {DatacardService} from 'shared/datacard.service';
import {OrderDirection} from 'octopus-connect';
import {Subject} from 'rxjs';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';

@Component({
    selector: 'app-lessons-created',
    templateUrl: './lessons-created.component.html'
})
export class LessonsCreatedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private optionsInterface: CollectionOptionsInterface;
    private type = 'byRole' as const;
    private unsubscribeInTakeUntil = new Subject<void>();

    private lessonsService = inject(LessonsService)
    private lessonsConfigurationService = inject(LessonsConfigurationService)
    private datacardService = inject(DatacardService)
    private lessonsCardService = inject(LessonsCardService)

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {},
            orderOptions: [
                {field: 'created', direction: OrderDirection.DESC}
            ],
            page: 1,
            range: 6
        };

        const callbacks = this.lessonsCardService.getCallbacks();

        this.lessonsService.loadPaginatedLessons(this.type, this.lessonsService.getAllowedRoleIdsForModelsCreation(), '', this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                if (!resources) {
                    return;
                }

                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }


    public getCardLayout() {
        return this.lessonsConfigurationService.getCardLayout();
    }
}
