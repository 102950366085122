import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Type,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacyRadioChange as MatRadioChange} from '@angular/material/legacy-radio';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {CalendarEvent} from 'angular-calendar';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {InstitutionGroupService} from '@modules/groups-management/core/services/institution-group.service';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {fuseAnimations} from 'fuse-core/animations';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../../settings';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {Institution, Learner} from '../../definitions';
import {Field, FieldsOptions} from 'shared/fieldsOptions';
import {isArray} from 'lodash-es';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';

const settingsStructure = new ModelSchema({
    levelsWidget: Structures.string('input'),
    levelsRequired: Structures.boolean(false),
    isPasswordRequired: Structures.boolean(true),
    isGenderRequired: Structures.boolean(false),
    requiredFields: Structures.array(['nickname', 'groupname', 'workgroupname']),
});

export interface FuseGroupsFormDialogDataItem {
    birthMonth?: any; // need to know what is birthMonth's type
    username: string;
    color: string;
    nickname: string;
    schoolyear_term: {
        id: string
    };
    workgroups: string[];
    groups: string[];
    level?: any; // need to know what is level's type
}

export interface GenericModalOptions {
    titleDialog: string;
    bodyDialog: string;
    labelTrueDialog: string;
    labelFalseDialog: string;
}

export interface FuseGroupsFormDialogData {
    data: Partial<GenericModalOptions> & Partial<{
        item: FuseGroupsFormDialogDataItem,
        action: string,
        typeEntity: string,
        entityType: string,
        selects: Partial<{
            parent: () => Institution[],
            workgroups: () => string[],
            groups: () => string[],
            metadata: () => [],
            learnersList: () => Learner[],
            class: () => string[],
            schoolYears: () => DataEntity[],
            types: () => string[],
            levels: () => DataEntity[],
            educationalLevels: () => DataEntity[],
        }>,
        colors: [],
        fields: string[],
        title: string
        rules: []
    }>;
    alternatives?: {
        condition: () => boolean;
        component?: Type<any>; // dialog components
    }[];
    callback: (formGroup) => Observable<any>;
    callbackWithMultiple?: (formGroup) => void;
    isAuthorized: (formGroup) => boolean;
    assignGroups?: (learner, assignOnly) => void;
}

@Component({
    selector: 'fuse-groups-form-dialog',
    templateUrl: './groups-form.component.html',
    styleUrls: ['./groups-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class FuseGroupsFormDialogComponent implements OnInit, OnDestroy {
    public loading = false;
    public displayedColumns = ['field'];
    public dataSource = new MatTableDataSource();
    public isModal = true;
    event: CalendarEvent;
    dialogTitle: string;
    entityForm: UntypedFormGroup;
    action: string;
    fields: string[];
    colors: string[];
    selects: { [key: string]: () => string[] | Learner[] | DataEntity[] | Institution[] };
    entity: any;
    chips: string[];
    allChips: string[];
    allLearners: string[];
    filteredLearnersOptions: Observable<string[]>;
    nicknameCtrl = new UntypedFormControl();
    entityType: string;

    groupname: string[];
    workgroupname: string[];

    typeEntity: string;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;

    separatorKeysCodes = [ENTER, COMMA];

    chipsCtrl = new UntypedFormControl();
    filteredChips: Observable<any[]>;

    myCodePostalControl = new UntypedFormControl();
    filteredPostalCodes: Observable<string[]>;

    institutions: Institution[] = [];

    institutionSelected: any;

    entityFormErrors: {
        nickname?: { nameInvalid?: boolean, taken?: boolean, nonexistent?: boolean };
        groups?: { required?: boolean; };
        groupname?: { alreadyExist?: boolean, required?: boolean; };
        workgroupname?: { required?: boolean, alreadyExist?: boolean; };
        passwordConfirm?: { required?: boolean, passwordsNotMatch?: boolean; };
        institution?: { [key: string]: string }
    };

    postalCodes: any;

    institutionTitleFormControl: UntypedFormControl = new UntypedFormControl();

    countEntities = null;
    pageIndex = 0;

    // number of retrieve result from server per page
    pageRange = 10;
    /****** workgroup chips *******/
    workgroupChips = []; // array of workgroup Chips selected
    workgroupCtrl = new UntypedFormControl();
    workgroupAddOnBlur = false;
    workgroupSeparatorKeysCodes = [ENTER, COMMA];
    workgroupSelectable = true;
    workgroupRemovable = true;
    workgroupFilteredChips: Observable<any[]>;
    workgroupAllChips = [];
    /**
     * @deprecated @deprecated use a configuration service instead
     */
    public settings: {
        isPasswordRequired: boolean;
        levelsWidget: 'select' | 'input';
        levelsRequired: boolean;
        requiredFields: string[]
    };
    originEntity: FuseGroupsFormDialogData;
    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupChipInput') workgroupChipInput: ElementRef;
    @Input() dataByInput: FuseGroupsFormDialogData;
    @Input() closeEmitter?: EventEmitter<boolean> = new EventEmitter();
    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter();
    @Output() submitInside: EventEmitter<boolean> = new EventEmitter(); // send to parent that validation was done inside child component
    @Output() saveInProgress: EventEmitter<boolean> = new EventEmitter(); // send to parent that save is in progress
    @Input() groupNameClassroomSelected = '';
    @Input() groupNameSelected = '';
    @Input() learnersList: any[] = []; // list of all existing learner use to propose an existing learner to add to a group
    @Input() isJoiningExistingLearner = false; // if true we will not add a new learner to group or workgroup but join an existing one
    public selectedColor: string;
    months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018];
    genders = ['H', 'F'];
    dyspraxicTroubleList = [
        'aucun',
        'incertain',
        'probable',
        'certitude (mais pas de diagnostic médical formel)',
        'diagnostiqué par un médecin habilité',
    ];
    otherTroubleList = [
        'Dyslexie / dysorthographie',
        'Dyscalculie',
        'Troubles d’attention avec ou sans hyperactivité (TDAH)',
        'Troubles mnésiques',
    ];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };
    public fieldsToDisplays: Field[];

    constructor(
        private translate: TranslateService,
        @Optional() public dialogRef: MatDialogRef<FuseGroupsFormDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: FuseGroupsFormDialogData,
        private formBuilder: UntypedFormBuilder,
        private institutionService: InstitutionGroupService,
        private groupService: GroupService,
        private groupsManagementService: GroupsManagementService,
        private authorization: AuthorizationService,
    ) {
        if (data && (!isArray(data) && Object.keys(data).length || isArray(data) && data.length)) { // no data => data = [] but when we push data inside it's not an array
            // component is used in a modal dialog
            this.initData(data);
        }
    }

    /********* institution filter**********/
    ngOnInit(): any {
        if (this.dataByInput && !this.originEntity) {
            this.isModal = false; // specific thing of modal like title close button etc. will be remove
            // component is used in html with selector not in modal dialog
            this.initData(this.dataByInput);
            this.closeEmitter.subscribe(res => {
                // call save from parent component
                this.close(this.entityForm, true);
            });
        }

        this.setPaginator();

        // filteredPostalCodes is used to retrieve the postalcodes filtered when value change
        this.filteredPostalCodes = this.myCodePostalControl.valueChanges
            .pipe(
                startWith<string | any>(''),

                // return the filtered postalCodes or an copy of postalCodes array empty
                map(value => value ? this._filterPostalCodes(value) : this.postalCodes.slice())
            );

        this.myCodePostalControl.valueChanges
            .subscribe(query => {
                if (!query || query === '') {
                    delete this.optionsInterface.filter.postalCode;
                } else {
                    this.applyFilters({value: query}, 'postalCode');
                }
            });

        this.institutionTitleFormControl.valueChanges
            .subscribe(query => {
                this.applyFilters({value: query}, 'label');
            });

        this.initLearnersAutoCompletionIfExist();
    }

    /**
     * use for autocomplete of field nickname
     * @param learner name of the learner
     */
    displayFnNickname(learner: string): string {
        return learner ? learner : '';
    }

    ngOnDestroy(): any {
        this.resetInstitution();
    }

    hasPasswordField(): boolean {
        return !(this.action === 'edit' && this.entity['sso']);
    }

    hasLaunchSearchButton(): boolean {
        return this.originEntity.data.typeEntity === 'institution' && this.originEntity.data.action === 'new';
    }

    chipsGroupsValidate(control: AbstractControl): any {
        if (this.chips.length > 0 && this.entityForm.get('groups').valid) {
            return;
        } else if (this.settings.requiredFields.includes('groups')) {
            return {required: true};
        }
        return;
    }

    createEntityForm(fields: string[]): UntypedFormGroup {
        const config = {};
        fields.forEach((field: string) => {
            if (field === 'groupname' || field === 'workgroupname') {
                config[field] = [this.entity[field], [(control) => this.nameAlreadyExist(control)]];
            } else if (field === 'groups') {
                const classrooms = this.entity.groups ? this.entity.groups : [];
                // if classroom is selected in classroom-list to add learner
                if (this.groupNameClassroomSelected && this.isJoiningExistingLearner) {
                    classrooms.push(this.groupNameClassroomSelected);
                }
                config[field] = [classrooms.filter((classroom => !!classroom))];
            } else if (field === 'workgroups') {
                const workgroups = this.entity.workgroups ? this.entity.workgroups : [];
                // if group is selected in group-list to add learner
                if (this.groupNameSelected && this.isJoiningExistingLearner) {
                    workgroups.push(this.groupNameSelected);
                }
                config[field] = [workgroups.filter((workgroup => !!workgroup))];
            } else if (field === 'nickname' && this.typeEntity === 'learner') {
                config[field] = [this.entity[field], [
                    (control) => this.checkNameNotEmail(control),
                    (control) => this.nicknameAlreadyExist(control),
                    (control) => this.nicknameNotPickedFromList(control)
                ]];
            } else if (field === 'parentalConsent' && this.typeEntity === 'learner') {
                config[field] = [!!this.entity[field]];
            } else if (field === 'level' && this.settings.levelsRequired) {
                config[field] = [this.entity[field], []];
            } else if (field === 'institution') {
                config[field] = [this.entity[field], (control) => this.checkInstitutionSelected(control)];
            } else if (field === 'passwordConfirm') {
                config[field] = ['', [Validators.required, (control) => this.confirmPassword(control)]];
            } else if (field === 'password') {
                config[field] = ['', this.settings.isPasswordRequired ? [Validators.required] : []];
            } else {
                config[field] = [this.entity[field]];
            }
        });

        Object.keys(config).filter(key => (this.settings.requiredFields || []).includes(key)).forEach(key => {
            const [defaultValue, potentiallyValidators] = config[key];
            const validators = isArray(potentiallyValidators) ? potentiallyValidators : [potentiallyValidators];

            config[key] = [defaultValue, [...validators, Validators.required].filter(v => !!v)];
        });

        return this.formBuilder.group(config);
    }

    editEntityForm(fields: string[]): UntypedFormGroup {
        const config = {};
        fields.forEach((field: string) => {
            if (field === 'groupname' || field === 'workgroupname') {
                config[field] = [this.entity[field], [(control) => this.nameAlreadyExist(control)]];
            } else if (field === 'groups') {
                config[field] = [this.entity[field]];
            } else if (field === 'workgroups') {
                config[field] = [this.entity[field]];
            } else if (field === 'nickname' && this.typeEntity === 'learner') {
                config[field] = [{
                    value: this.entity['nickname'],
                    disabled: this.entity['sso']
                }, [(control) => this.checkNameNotEmail(control), (control) => this.nicknameAlreadyExist(control)]];
            } else if (field === 'parentalConsent' && this.typeEntity === 'learner') {
                config[field] = [!!this.entity[field]];
            } else if (field === 'institution') {
                config[field] = [this.entity[field], (control) => this.checkInstitutionSelected(control)];
            } else if (field === 'parent' && this.entity[field]) {
                config[field] = this.institutions.find((institution) => this.entity[field].id === institution.id);
            } else if (field === 'passwordConfirm') {
                config[field] = ['', [Validators.required, (control) => this.confirmPassword(control)]];
            } else {
                config[field] = [this.entity[field]];
            }
        });
        return this.formBuilder.group(config);
    }

    colorSelected(color: string): boolean {
        return this.entityForm.get('color').value === color;
    }

    onRegisterFormValuesChanged(): void {
        const controlGroups = this.entityForm.get('groups');
        const controlGroupName = this.entityForm.get('groupname');
        const controlWorkgroupName = this.entityForm.get('workgroupname');
        const controlNickname = this.entityForm.get('nickname');
        const controlInstitution = this.entityForm.get('institution');
        const controlPassword = this.entityForm.get('password');
        const controlPasswordConfirm = this.entityForm.get('passwordConfirm');

        if (this.entityFormErrors) {
            // use to know form errors
            for (const field in this.entityFormErrors) {
                this.entityFormErrors[field] = {};
            }
        }
        if (controlGroups && this.chipsCtrl.dirty && !controlGroups.valid) {
            this.entityFormErrors['groups'] = controlGroups.errors;
        }
        if (controlGroupName && controlGroupName.dirty && !controlGroupName.valid) {
            this.entityFormErrors['groupname'] = controlGroupName.errors;
        }
        if (controlWorkgroupName && controlWorkgroupName.dirty && !controlWorkgroupName.valid) {
            this.entityFormErrors['workgroupname'] = controlWorkgroupName.errors;
        }

        if (controlNickname && controlNickname.dirty && !controlNickname.valid) {
            this.entityFormErrors['nickname'] = controlNickname.errors;
        }

        if (controlInstitution && controlInstitution.dirty && !controlInstitution.valid) {
            this.entityFormErrors['institution'] = controlInstitution.errors;
        }

        if (controlPassword && controlPassword.dirty && !controlPassword.valid) {
            this.entityFormErrors['password'] = controlPassword.errors;
        }

        if (controlPasswordConfirm && controlPasswordConfirm.dirty && !controlPasswordConfirm.valid) {
            this.entityFormErrors['passwordConfirm'] = controlPasswordConfirm.errors;
        }
    }

    remove(chip: any, type): void {
        if ((type === 'groups' && this.groupNameClassroomSelected) || (type === 'workgroup' && this.groupNameSelected)) {
            // in this case we are adding learner inline inside a classroom the classroom cannot be remove she is in read only mode
            return;
        }

        let index: number;
        if (type === 'groups') {
            index = this.chips.indexOf(chip);
            if (index >= 0) {
                this.chips.splice(index, 1);
                this.allChips.push(chip);
                this.setGroupsAndWorkgroups('groups');
            }
        } else {
            index = this.workgroupChips.indexOf(chip);
            if (index >= 0) {
                this.workgroupChips.splice(index, 1);
                this.workgroupAllChips.push(chip);
                this.setGroupsAndWorkgroups('workgroups');
            }
        }

    }

    filter(name: string, type): string[] {
        if (type === 'groups') {
            return this.allChips.filter(chip =>
                chip.toLowerCase().indexOf(name.toLowerCase()) === 0);
        } else {
            return this.workgroupAllChips.filter(chip =>
                chip.toLowerCase().indexOf(name.toLowerCase()) === 0);
        }
    }

    selected(event: MatAutocompleteSelectedEvent, type: 'groups' | 'workgroups'): void {
        let index;
        if (type === 'groups') {
            this.chipsCtrl.setValue(null);
            if (!this.alreadyExisting(event.option.viewValue, type)) {
                this.chips.push(event.option.viewValue);
                this.groupsChipInput.nativeElement.value = '';

                index = this.allChips.indexOf(event.option.viewValue);
                this.allChips.splice(index, 1);
                this.setGroupsAndWorkgroups('groups');
            }
        }
        if (type === 'workgroups') {
            this.workgroupCtrl.setValue(null);
            if (!this.alreadyExisting(event.option.viewValue, type)) {
                this.workgroupChips.push(event.option.viewValue);
                this.workgroupChipInput.nativeElement.value = '';

                index = this.workgroupAllChips.indexOf(event.option.viewValue);
                this.workgroupAllChips.splice(index, 1);
                this.setGroupsAndWorkgroups('workgroups');
            }
        }
        this.blurAllChipsList();
    }

    setGroupsAndWorkgroups(type): void {
        if (type === 'groups' && this.entityForm.get('groups')) {
            this.entityForm.get('groups').setValue(this.chips);
            this.filteredChips = this.chipsCtrl.valueChanges.pipe(
                startWith(null),
                map((chip: string | null) => chip ? this.filter(chip, 'groups') : this.allChips.slice()));
        }
        if (type === 'workgroups' && this.entityForm.get('workgroups')) {
            this.entityForm.get('workgroups').setValue(this.workgroupChips);
            this.workgroupFilteredChips = this.workgroupCtrl.valueChanges.pipe(
                startWith(null),
                map((chip: string | null) => chip ? this.filter(chip, 'workgoup') : this.workgroupAllChips.slice()));
        }
    }

    blurAllChipsList(): void {
        if (this.groupsChipInput) {
            this.groupsChipInput.nativeElement.blur();
        }
        if (this.workgroupChipInput) {
            this.workgroupChipInput.nativeElement.blur();
        }
    }

    alreadyExisting(name: string, type): boolean {
        if (type === 'groups') {
            return this.chips.indexOf(name) !== -1;
        } else {
            return this.workgroupChips.indexOf(name) !== -1;

        }
    }

    selectType(type): string {
        if (type) {
            return 'groups-management.' + type.toString();
        }
    }

    resetInstitution(): void {

        if (this.institutionService.institutionInUserSubscription) {
            this.institutionService.resetOptionInterfaces();
        }

    }

    selectedInstitution(event: MatAutocompleteSelectedEvent): void {
        this.institutionSelected = event;
        this.entityForm.get('institution').setValue(this.institutionSelected);
    }

    confirmPassword(control: AbstractControl): any {
        if (!control.parent || !control) {
            return;
        }

        const password = control.parent.get('password');
        const passwordConfirm = control.parent.get('passwordConfirm');

        if (!password || !passwordConfirm) {
            return;
        }

        if (passwordConfirm.value === '') {
            return;
        }

        if (password.value !== passwordConfirm.value) {
            return {
                passwordsNotMatch: true
            };
        }
    }

    nameAlreadyExist(control: AbstractControl): any {
        let name;
        let type;

        if (!control || !control.parent) {
            return;
        }

        if (this.groupname && this.groupname.length > 0) {
            name = control.parent.get('groupname');
            type = 'groupname';
        }

        if (this.workgroupname && this.workgroupname.length > 0) {
            name = control.parent.get('workgroupname');
            type = 'workgroupname';
        }

        if (!name || name.value === '') {
            return;
        }


        if (this.action === 'edit' && name.value === this.entity[type]) {
            return;
        }

        if (this.checkIfNameExist(type, name.value)) {
            return {
                alreadyExist: true
            };
        }

    }

    nicknameAlreadyExist(control: AbstractControl): any {
        let name;
        if (!control || !control.parent) {
            return;
        }

        name = control.parent.get('nickname');
        if (!name || name.value === '') {
            return;
        }

        if (this.checkIfNameExist('learnersList', name.value)) {
            return {
                taken: true
            };
        }
    }

    private nicknameNotPickedFromList(control: AbstractControl): any {
        let name;
        if (!control || !control.parent) {
            return;
        }

        name = control.parent.get('nickname');
        if (!name || name.value === '') {
            return;
        }

        if (!this.checkIfNicknameFromList(name.value)) {
            return {
                nonexistent: true
            };
        }
    }

    checkNameNotEmail(control: AbstractControl): any {
        let name;
        if (!control || !control.parent) {
            return;
        }

        name = control.parent.get('nickname');

        if (!name || name.value === '') {
            return;
        }

        if (this.validateEmail(name.value)) {
            return {
                nameInvalid: true
            };
        }
    }

    checkIfNameExist(type: string, entry: string): boolean {
        if (type) {
            if (type === 'learnersList') {
                const nicknameFounded = !!this.learnersList.find((learner) => learner.nickname === entry);
                return nicknameFounded
                    && !this.isJoiningExistingLearner
                    && (!this.originEntity.data.item
                        || this.originEntity.data.item
                        && entry !== this.originEntity.data.item.nickname);
            }
            return this[type].indexOf(entry) !== -1;
        }
    }

    private checkIfNicknameFromList(entry: string): boolean {
        return this.isJoiningExistingLearner && !!this.allLearners.find((nickname) => nickname === entry) || !this.isJoiningExistingLearner;
    }

    validateEmail(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkInstitutionSelected(control: AbstractControl): any {

        if (!control || !control.parent) {
            return;
        }

        const institution = control.parent.get('institution');

        if (!institution) {
            return;
        }

        if (this.institutionSelected && !('id' in this.institutionSelected)) {
            return {
                institutionNotSelected: true
            };
        }

        return;

    }

    mergePostalCodes(data): any[] {
        const allPostalCodes = [];
        const datas = data.postalCodes;

        for (const country in datas) {
            if (country) {
                allPostalCodes.push(...datas[country]);
            }
        }

        return allPostalCodes;

    }

    applyFilters(event, type): void {
        if (event.option && event.option.value !== '') {
            this.optionsInterface.filter[type] = event.option.value;
        } else {
            if (event.value === '') {
                delete this.optionsInterface.filter[type];
            } else {
                this.optionsInterface.filter[type] = event.value;
            }
        }
    }

    setPaginator(): void {
        if (this.institutionService.paginatedCollection.paginator) {
            this.countEntities = this.institutionService.paginatedCollection.paginator.count;
            this.pageIndex = this.institutionService.paginatedCollection.paginator.page - 1;
            this.pageRange = this.institutionService.paginatedCollection.paginator.range;
        }
    }

    onPaginateChange(event): void {
        const page = event.pageIndex + 1;
        this.optionsInterface.page = page;

        this.launchSearch();
    }

    launchSearch(resetPagination = false): void {
        if (resetPagination) {
            this.optionsInterface.page = 0;
        }

        this.institutionService.launchSearch(() => this.setDataSource(), this.optionsInterface);
    }

    setDataSource(): void {
        this.dataSource.data = this.institutionService.getAllInstitutionsFormated();
        this.setPaginator();
    }

    close(entityForm: UntypedFormGroup, clickfromOutside = false): void {
        this.loading = true;
        if (this.isJoiningExistingLearner) {
            this.prepareDataIfLearnerExistAndJoinGroupOrWorkgroup(entityForm);
        }
        this.saveInProgress.emit(true);
        this.originEntity.callback(entityForm).subscribe((data) => {
                this.loading = false;
                if (this.isModal) {
                    this.dialogRef.close();
                } else {
                    if (!clickfromOutside) {
                        this.submitInside.next(clickfromOutside);
                    }
                    this.initData(this.dataByInput);
                    this.isFormValid.next(!this.entityForm.invalid);
                }

            }, (error) => {
                if (error.data.response.title === 'account-management.nickname_taken') {
                    this.entityForm.controls['nickname'].setErrors({'taken': true});
                    this.entityFormErrors.nickname.taken = true;
                    this.loading = false;
                }
            }
        );
    }

    /**
     * cancel the current action of editing a row
     */
    cancelEditingRow(): void {
        if (this.loading) {
            return;
        }
        this.submitInside.next(false);
    }

    public colorChange($event: MatRadioChange): string {
        return this.selectedColor = $event.value;
    }

    /**
     * if learners list is pass in input we offer possibility
     * to use this list for adding an already existing user to a group or a classroom
     * instead of just adding a new one so we initialize list and listener of changing selected value
     */
    private initLearnersAutoCompletionIfExist(): void {
        if (this.learnersList && this.isJoiningExistingLearner) {
            if (this.groupNameClassroomSelected) {
                this.allLearners = this.learnersList.filter(learner => !learner.groups.includes(this.groupNameClassroomSelected))
                    .map(l => l.nickname);
            }

            if (this.groupNameSelected) {
                this.allLearners = this.learnersList.filter(learner => !learner.workgroups.includes(this.groupNameSelected))
                    .map(l => l.nickname);
            }

            this.filteredLearnersOptions = this.entityForm.get('nickname').valueChanges.pipe(
                startWith(''),
                map(nickname => (nickname ? this.filterLearners(nickname) : this.allLearners.slice())),
                tap(() => this.onRegisterFormValuesChanged())
            );


        }
    }

    /**
     * init data of the form
     * in one case it comme from input in other case it come from @Inject(MAT_DIALOG_DATA) private data
     * @param data : data to construct the form
     */
    private initData(data: FuseGroupsFormDialogData): void {
        // TODO utiliser un service de config
        this.settings = settingsStructure.filterModel(modulesSettings.groupsManagement) as any;

        this.originEntity = data;

        this.action = this.originEntity.data.action;
        this.dialogTitle = 'groups-management.' + this.originEntity.data.title;
        this.fields = this.originEntity.data.fields;
        this.initFields();

        if (this.isJoiningExistingLearner) {
            const allowedFieldsForJoining = ['nickname', 'educationalLevel', 'groups', 'workgroups'];
            this.fields = this.fields.filter((field: string) => allowedFieldsForJoining.includes(field));

            if (this.groupNameSelected) {
                this.fields = this.fields.filter((field: string) => field !== 'workgroups');
            }
            if (this.groupNameClassroomSelected) {
                this.fields = this.fields.filter((field: string) => field !== 'groups');
            }
        }

        this.selects = this.originEntity.data.selects;
        this.colors = this.originEntity.data.colors ? this.originEntity.data.colors : [];
        this.selectedColor = this.colors.length && this.originEntity.data.item ? this.originEntity.data.item.color : this.colors[0];
        this.chips = [];
        this.institutionSelected = [];

        this.groupname = this.originEntity.data.selects && this.originEntity.data.selects.class ? this.originEntity.data.selects.class() : [];
        this.workgroupname = this.originEntity.data.selects && this.originEntity.data.selects.workgroups ? this.originEntity.data.selects.workgroups() : [];
        if (!this.learnersList || this.learnersList && !this.learnersList.length) {
            this.learnersList = this.originEntity.data.selects && this.originEntity.data.selects.learnersList ? this.originEntity.data.selects.learnersList() : [];
        }

        this.postalCodes = this.mergePostalCodes(this.originEntity.data.selects && this.originEntity.data.selects.metadata ? this.originEntity.data.selects.metadata() : {});
        this.typeEntity = this.originEntity.data.typeEntity;

        if (this.originEntity.data.action === 'edit') {
            this.allChips = this.originEntity.data.item.groups
                ? this.originEntity.data.selects.groups().filter((group) => this.originEntity.data.item.groups.indexOf(group) === -1)
                : [];
            this.workgroupAllChips = this.originEntity.data.item.workgroups
                ? this.originEntity.data.selects.workgroups().filter((workgroup) => this.originEntity.data.item.workgroups.indexOf(workgroup) === -1)
                : [];
        } else {
            this.allChips = this.originEntity.data.selects && this.originEntity.data.selects.groups ? this.originEntity.data.selects.groups() : [];
            this.workgroupAllChips = this.originEntity.data.selects && this.originEntity.data.selects.workgroups ? this.originEntity.data.selects.workgroups() : [];
        }


        if (this.fields && this.fields.indexOf('groupname') !== -1 || this.fields && this.fields.indexOf('workgroupname') !== -1) {
            this.entityFormErrors = {
                groupname: {},
                workgroupname: {},
                nickname: {}
            };
        }

        if (this.fields && this.fields.indexOf('nickname') !== -1 && (this.typeEntity === 'learner' || this.typeEntity === 'trainer')) {
            this.entityFormErrors = {
                nickname: {},
                groups: {}
            };
            if (this.fields.indexOf('passwordConfirm') !== -1) {
                this.entityFormErrors['passwordConfirm'] = {};
            }
        }

        if (this.fields && this.fields.indexOf('institution') !== -1) {
            this.entityFormErrors = {
                institution: {}
            };
        }

        this.institutions = this.originEntity.data.selects && this.originEntity.data.selects.parent ? this.originEntity.data.selects.parent() : [];

        this.dataSource.data = this.institutions;


        this.entityType = 'groups-management.' + this.originEntity.data.entityType;

        this.filteredChips = this.chipsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: string | null) => chip ? this.filter(chip, 'groups') : this.allChips.slice()));

        this.workgroupFilteredChips = this.workgroupCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: string | null) => chip ? this.filter(chip, 'workgoup') : this.workgroupAllChips.slice()));


        if (this.action === 'edit') {
            this.entity = this.originEntity.data.item;
            this.chips = this.originEntity.data.item.groups ? this.originEntity.data.item.groups : [];
            this.workgroupChips = this.originEntity.data.item.workgroups ? this.originEntity.data.item.workgroups.slice() : [];

            this.entityForm = this.editEntityForm(this.fields);
            if (this.entityForm.get('schoolyear_term') && this.entityForm.get('schoolyear_term').hasOwnProperty('id')) {
                this.entityForm.get('schoolyear_term').setValue(this.originEntity.data.item.schoolyear_term.id.toString());
            }
            // age is used in place of month and year
            if (this.getFieldByLabel('age') && !this.getFieldByLabel('birthMonth')) {
                const items: FuseGroupsFormDialogDataItem = this.originEntity.data.item;
                this.entityForm.get('age').setValue(items.birthMonth ? items.birthMonth.toString() : '');
            }
            // educational_level is store in back in level field
            if (this.getFieldByLabel('educationalLevel') && !this.getFieldByLabel('level')) {
                const items: FuseGroupsFormDialogDataItem = this.originEntity.data.item;
                let level = '';

                if (Array.isArray(items?.level)) {
                    if (items.level.length > 0) {
                        level = items.level[0].id.toString();
                    }
                } else if (items?.level?.id) {
                    level = items.level.id.toString();
                } else if (items?.level) {
                    level = items.level.toString();
                }

                this.entityForm.get('educationalLevel').setValue(level);
            }
        } else {
            // set value by default when (select value) init
            this.myCodePostalControl.reset(null);

            this.entity = {};
            this.entityForm = this.createEntityForm(this.fields);
            if (this.entityForm.get('groups')) {
                this.entityForm.get('groups').setValue([]);
                this.entityForm.get('groups').setValidators((control) => this.chipsGroupsValidate(control));
                this.chips = this.entityForm.value.groups;
            }

            if (this.entityForm.get('schoolyear_term')) {
                this.entityForm.get('schoolyear_term').setValue(this.groupsManagementService.schoolyearDefaultMatSelectValue);
            }

            if (this.entityForm.get('color')) {
                this.entityForm.get('color').setValue(this.selectedColor);
            }
        }
        this.setGroupOrWorkgroupOfPArentRowSelectedContext();
    }

    /**
     * filter learner list in regard of name pass
     * @param name nickname
     */
    private filterLearners(name: string): string[] {
        const filterValue = name.toLowerCase();

        return this.allLearners.filter(
            learner => learner.toLowerCase().indexOf(filterValue) === 0
        );
    }

    /**
     * set data if group or workgroup is pass to limit view of learner of this group in row
     * it's beacause in this context the group or classroom to use when we create user is already know
     * it's the group or the classroom of the row the user click to open the learner add block
     */
    private setGroupOrWorkgroupOfPArentRowSelectedContext(): void {
        if (this.groupNameClassroomSelected) {
            this.chips = [this.groupNameClassroomSelected];
            this.setGroupsAndWorkgroups('groups');

            if (this.entityForm.contains('educationalLevel')) {
                const group = this.groupService.getGroupFromName(this.groupNameClassroomSelected);
                if (group) {
                    this.entityForm.get('educationalLevel').setValue(group.level);
                }
            }
        }
        if (this.groupNameSelected) {
            this.workgroupChips = [this.groupNameSelected];
            this.setGroupsAndWorkgroups('workgroups');
        }

        this.entityForm.valueChanges.subscribe(() => {
            this.onRegisterFormValuesChanged();
        });
    }

    private _filterPostalCodes(name: string): any[] {

        const filterValue = name.toLowerCase();

        return this.postalCodes.filter(option => option.toLowerCase()
            .includes(filterValue));
    }

    /**
     * if user already exist we are in case of just adding group or workgroup to it
     * we flag it to let know to call back the case we are and we reset useless fields
     * @param entityForm : Data of form
     */
    private prepareDataIfLearnerExistAndJoinGroupOrWorkgroup(entityForm): void {
        if (this.groupNameClassroomSelected) {
            entityForm.value.groups = [this.groupNameClassroomSelected];
        }
        if (this.groupNameSelected) {
            entityForm.value.workgroups = [this.groupNameSelected];
        }
        entityForm.value.onlyJoinCurrentUserToGroupOrWorkgroup = true;
    }

    public getFieldByLabel(label: string): Field {
        return this.fieldsToDisplays.find(f => f.label === label);
    }

    public canAttachToGroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.AttachLearnerToGroup);
    }

    public canAttachToWorkgroup(): boolean {
        return this.authorization.currentUserCan(SyncRules.AttachLearnerToWorkgroup);

    }

    public isRequired(field: string): boolean {
        return (this.settings.requiredFields || []).includes(field);
    }

    private initFields(): void {
        this.fieldsToDisplays = this.fields.map(field => new Field(field));
    }
}

