import {Component, Input} from '@angular/core';

/**
 * generate a matrix and show a picture in background
 * 1  2  3  4
 * 5  6  7  8
 * 9  10 11 12
 * 13 14 15 16
 */
@Component({
    selector: 'app-activity-edition-point-img-zone-generator',
    templateUrl: './activity-edition-point-img-zone-generator.component.html',
    styleUrls: ['./activity-edition-point-img-zone-generator.component.scss']
})

export class ActivityEditionPointImgZoneGeneratorComponent {
    @Input() picture: string | ArrayBuffer;
}
