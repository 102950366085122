import {inject, Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {Observable, of} from 'rxjs';
import {UserDataEntity} from '@modules/authentication';
import {AsyncRules} from '@modules/corpus/core/async.rules';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

@Injectable({
  providedIn: 'root'
})
export class CorpusAuthorizationService {
    private authorizationService = inject( AuthorizationService );

    constructor() {
        this.authorizationService.addRule(AsyncRules.editResource, this.canEditResource.bind(this));
    }

    private canEditResource(user: UserDataEntity, resource: TypedDataEntityInterface<unknown>): Observable<boolean> {
        return of(+resource.get('owner') === +user.id && !!+resource.get('locked'));
    }
}
