import {Component, inject} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
    selector: 'app-assignment-select-seances',
    templateUrl: './assignment-select-seances.component.html',
})
export class AssignmentSelectSeancesComponent {
    private assignmentByStepService: AssignmentByStepService;
    public breadcrumb: string[] = [];

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
        this.breadcrumb = this.assignmentByStepService.setBreadcrumb();
    }

    public get seances(): { value: string, label: string, selected: boolean }[] {
        return this.assignmentByStepService.seances;
    }

    public get title(): string {
        return this.assignmentByStepService.title;
    }

    public checkboxEvent(evt: any): void {
        this.assignmentByStepService.disabledNextButton = this.assignmentByStepService.seances.filter(s => s.selected === true).length <= 0;
    }
}
