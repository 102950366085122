import {take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OctopusConnectService} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {brand, customHomePageBanner} from 'app/settings';
import {modulesSettings} from '../../../app/settings';
import {CommunicationCenterService} from '@modules/communication-center';
import {UserDataEntity} from '@modules/authentication';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

const settingsStructure: ModelSchema = new ModelSchema(({
    secureAlias: Structures.array([])
}));

export interface PageAttributes {
    label: string,
    body: string,
    hideLabel: boolean,
    summary: string,
    chapter: boolean,
    alias: string,
    pages: PageAttributes[],
    document: URL
}

export type PageEntity = TypedDataEntityInterface<PageAttributes>

@Injectable({
    providedIn: 'root'
})
export class BasicPageService {

    public settings: {[key: string]: any};
    public brand: string = brand;
    public isCustomBanner: boolean = customHomePageBanner;
    public userData: UserDataEntity;

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(tap((userdata) => this.userData = userdata))
            .subscribe();
        this.settings = settingsStructure.filterModel(modulesSettings.basicPage);
    }

    public loadPage(alias: string): Observable<PageEntity> {
        return this.octopusConnect.loadEntity('pages', alias).pipe(take(1)) as Observable<PageEntity>;
    }
}
