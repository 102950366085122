import {Injectable} from '@angular/core';
import {SettingsCardsDisplayInterface} from 'fuse-core/components/card/settings-cards-display.interface';
import {ModelSchema, Structures} from 'octopus-model';
import {Roles, RolesOrDefault} from 'shared/models';
import {getSettingByRole} from 'shared/utils/settings';
import {SwitchSettingsType} from 'shared/utils/settings/switch-settings.type';
import {modulesSettings} from '../../../app/settings';

const RawSettings = {
    menuLinks: Structures.object({
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            manager: ['duplicate'],
            default: ['duplicate']
        }
    }),
    cardFields: Structures.object({
        default: ['title', 'menu', 'lessonStats', 'keywords', 'keywords-chip-list', 'metadatas', 'assigned']
    }),
    cardDisplay: Structures.object({
        displayLessonBreadcrumb: Structures.object({usages: false, concepts: false, chapters: false, skills: false}),
        showButtonThemeLesson: Structures.boolean(false),
        showButtonBookmarks: Structures.boolean(true),
        isTextButton: Structures.boolean(false)
    }),
    cardDefaultHeader: Structures.string(),
    cardPlayOpenMenu: Structures.boolean(false),
    chooseSubLessonFeatureActive: Structures.object({default: false}),
    displayMenuWithPreviewOption: Structures.boolean(false),
    difficultyDisplaySteps: Structures.number(0),
    isLaunchLessonAskComment: Structures.boolean(false),
    isLaunchLessonAskModalActive: Structures.object({default: false}),
    isLaunchLessonAskTitle: Structures.boolean(false),
    launchLessonCommentMaxLength: Structures.number(null),
    multiPdfDownload: Structures.boolean(false),
    playButtonUseIcon: Structures.boolean(false),
    hidePlayPreviewFromFavoritesPage: Structures.boolean(false),
    allowedMultiPdfDownloadFromFavoritePage: Structures.boolean(false),
    viewLessonPage: Structures.boolean(false), // allow to show lesson page
    moveActionsButtonInMenu: Structures.object({default: false}), // Display all button in menu button except bookmark and open lesson page
    displayOnlyMediaIconsType: Structures.boolean(false), // Display only icon
    roleForbiddenToDownloadPdfLesson: Structures.array([]),
    isPdfMustBeOpenedDirectly: Structures.boolean(false),
};

const settingsStructure: ModelSchema = new ModelSchema(RawSettings);

@Injectable({
    providedIn: 'root'
})
export class CardsConfigurationService {

    private settings = <SwitchSettingsType<typeof RawSettings>>settingsStructure.filterModel(modulesSettings.cards);

    public menuLinks() {
        return this.settings.menuLinks;
    }

    public cardFields() {
        return this.settings.cardFields;
    }

    public cardDisplay() {
        return this.settings.cardDisplay as Partial<SettingsCardsDisplayInterface>;
    }

    public cardDefaultHeader() {
        return this.settings.cardDefaultHeader;
    }

    public cardPlayOpenMenu() {
        return this.settings.cardPlayOpenMenu;
    }

    public chooseSubLessonFeatureActive() {
        return this.settings.chooseSubLessonFeatureActive;
    }

    public displayMenuWithPreviewOption() {
        return this.settings.displayMenuWithPreviewOption;
    }

    public difficultyDisplaySteps() {
        return this.settings.difficultyDisplaySteps;
    }

    public isLaunchLessonAskComment() {
        return this.settings.isLaunchLessonAskComment;
    }

    public isLaunchLessonAskModalActive(role: Roles) {
        const setting = this.settings.isLaunchLessonAskModalActive as {[k in RolesOrDefault]: boolean}
        return getSettingByRole(setting, role);
    }

    public isLaunchLessonAskTitle() {
        return this.settings.isLaunchLessonAskTitle;
    }

    public launchLessonCommentMaxLength() {
        return this.settings.launchLessonCommentMaxLength;
    }

    public multiPdfDownload() {
        return this.settings.multiPdfDownload;
    }

    public hidePlayPreviewFromFavoritesPage() {
        return this.settings.hidePlayPreviewFromFavoritesPage;
    }

    public allowedMultiPdfDownloadFromFavoritePage() {
        return this.settings.allowedMultiPdfDownloadFromFavoritePage;
    }

    public viewLessonPage() {
        return this.settings.viewLessonPage;
    }

    public moveActionsButtonInMenu() {
        return this.settings.moveActionsButtonInMenu;
    }

    public displayOnlyMediaIconsType() {
        return this.settings.displayOnlyMediaIconsType;
    }

    public roleForbiddenToDownloadPdfLesson(): Roles[] {
        return this.settings.roleForbiddenToDownloadPdfLesson;
    }

    public isPdfMustBeOpenedDirectly() {
        return this.settings.isPdfMustBeOpenedDirectly;
    }
}
