import { Injectable } from '@angular/core';
import {ConfigurationProvider} from './configuration-provider.class';
import {BlackListItem, BrowserTestConfiguration} from './browser-test-configuration.interface';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {ScreenSizeModalComponent} from './modals/screen-size-modal/screen-size-modal.component';
import {BlackListModalComponent} from './modals/black-list-modal/black-list-modal.component';
import {OctopusConnectListenerService} from '@modules/browser-test/core/octopus-connect-listener.service';
import {modulesSettings} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';

declare var require: any;
const detectBrowser = require('detect-browser');
const MobileDetect = require('mobile-detect/mobile-detect');
const semver = require('semver');

const settingsStructure: ModelSchema = new ModelSchema({
    showWarningModal: Structures.boolean(true),
});

@Injectable()
export class BrowserTestService {

    /**
     * Module configuration
     */
    private config: BrowserTestConfiguration;

    /**
     * Default black list
     * @type {{name: string; minVersion: string}[]}
     */
    private blackList: BlackListItem[] = [
        {
            name: 'chrome',
            minVersion: '80'
        }
    ];

    public settings: {[key: string]: any};

    /**
     * Creates the service
     * @param {ConfigurationProvider} configurationProvider The conf provider
     * @param {MatDialog} dialog MatDialog injection
     */
    constructor(
        configurationProvider: ConfigurationProvider,
        private dialog: MatDialog,
        private connectListener: OctopusConnectListenerService
    ) {
        this.config = configurationProvider.configuration;

        if (this.config.browsersBlackList) {
            this.blackList = this.config.browsersBlackList;
        }

        if (this.config.browsersBlackListExtension) {
            this.blackList = this.blackList.concat(this.config.browsersBlackListExtension);
        }

        this.settings = settingsStructure.filterModel(modulesSettings.browserTest);
    }

    /**
     * Do the compatibilité checks :
     * Screen size on desktop, and browser black list
     */
    checkCompatibility() {
        const md = new MobileDetect(window.navigator.userAgent);

        // screen size desktop
        if ((screen.width < this.config.desktopSize.minWidth || screen.height < this.config.desktopSize.minHeight)) {

            if (sessionStorage.getItem('ResolutionModalDisplayed')) {
                // modal already displayed
            } else {
                if (this.settings.showWarningModal){
                    this.dialog.open(ScreenSizeModalComponent, {
                        data: {
                            minWidth: this.config.desktopSize.minWidth,
                            minHeight: this.config.desktopSize.minHeight
                        }
                    });
                    sessionStorage.setItem('ResolutionModalDisplayed', '1');
                    return;
                }
            }
        }


        // check if browser is in blacklist
        if (this.config.browsersBlackList) {
            const browser = detectBrowser.detect();

            for (const blackListItem of this.blackList)
            {
                if (browser.name === blackListItem.name) {
                    if (blackListItem.minVersion) {
                        const itemSemVersion: string = semver.coerce(blackListItem.minVersion).version;
                        const browserSemVersion: string = semver.coerce(browser.version).version;

                        if (semver.gte(itemSemVersion, browserSemVersion)) {
                            this.openBlackListModal(blackListItem, browser);
                            return;
                        }
                    } else {
                        this.openBlackListModal(blackListItem, browser);
                        return;
                    }
                }
            }
        }
    }

    /**
     * Opens the blacklisted modal
     * @param {Object} blackListed Targeted black list browser info
     * @param {Object} userBrowser User browser informations
     */
    openBlackListModal(blackListed: Object, userBrowser: Object) {
        if (sessionStorage.getItem('BrowserModalDisplayed')) {
            // modal already displayed
        } else {
            this.dialog.open(BlackListModalComponent, {
                data: {
                    userBrowser: userBrowser,
                    blackListed: blackListed
                }
            });
            sessionStorage.setItem('BrowserModalDisplayed', '1');
        }

    }
}
