import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GraphAssignationComponent} from './graph-assignation/graph-assignation.component';
import {CommunicationCenterService} from '../../communication-center/index';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '../../authentication/index';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {GraphAssignationService} from './graph-assignation.service';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {GraphAssignationExportDirective} from './graph-assignation-export.directive';
import {GraphProgressionComponent} from './progression/graph-progression/graph-progression.component';
import {NgChartsModule} from 'ng2-charts';
import {SearchFiltersModule} from 'fuse-core/components';
import { FiltersComponent } from './progression/filters/filters.component';
import { DetailsListComponent } from './progression/details-list/details-list.component';
import { ProgressionDisplayComponent } from './progression/progression-display/progression-display.component';
import {CdkTableModule} from '@angular/cdk/table';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { InfoMetadataDialogComponent } from './info-metadata-dialog/info-metadata-dialog.component';

const routes: Routes = [
    {
        path: 'result',
        canActivate: [IsUserLogged],
        component: GraphAssignationComponent
    },
    {
        path: 'result/progression',
        canActivate: [IsUserLogged],
        component: ProgressionDisplayComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        NgChartsModule,
        SearchFiltersModule,
        CdkTableModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        GraphAssignationComponent,
        GraphAssignationExportDirective,
        GraphProgressionComponent,
        FiltersComponent,
        DetailsListComponent,
        ProgressionDisplayComponent,
        InfoMetadataDialogComponent
    ],
    exports: [GraphAssignationComponent]
})
export class GraphAssignationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }


    static forRoot(): ModuleWithProviders<GraphAssignationModule> {

        return {
            ngModule: GraphAssignationModule,
            providers: [
                GraphAssignationService
            ]
        };
    }

    private postLogout(): void {
        GraphAssignationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-followed');
    }

    private postAuthentication(): void {
        if (!GraphAssignationModule.isMenuSet && this.authService.hasLevel(['trainer', 'learner'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'graph-followed',
                'title': 'Résultats',
                'translate': 'graph-assignation.title',
                'type': 'item',
                'icon': 'followed',
                'url': '/result',
            });
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'progression',
                'title': 'progression',
                'translate': 'graph-progression.title',
                'type': 'item',
                'icon': 'followed',
                'url': '/result/progression',
            });

            GraphAssignationModule.isMenuSet = true;
        }
    }
}

