import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {LicensingService} from '../licensing.service';
import {localizedDate} from 'shared/utils/datetime';

@Component({
    selector: 'licensing-content',
    templateUrl: './licensing-content.component.html',
    styleUrls: ['./licensing-content.component.scss']
})
export class LicensingContentComponent implements OnInit, OnDestroy {

    @Input('uid') uid: string;
    public dataSource: any[];
    public displayedColumns: string[] = ['name', 'unlockedDate', 'expirationDate', 'code'];
    public subscribeGetMethods: any;

    constructor(private licensingService: LicensingService) {}

    ngOnInit(): void {
        this.subscribeGetMethods = this.licensingService.getMethods().subscribe((entities) => {
            const licenses = [];
            for (const entity of entities) {
                if (!this.uid || this.uid === entity.get('uid')) {
                    const access = entity.get('access') || [];
                    for (const method of access) {
                        licenses.push({
                            id: method.id,
                            name: method.name,
                            unlockedDate: localizedDate(entity.get('date')),
                            code: entity.get('code'),
                            expirationDate: localizedDate(entity.get('expirationDate')),
                            expired: entity.get('expired'),
                        });
                    }
                }
            }
            this.dataSource = licenses;
        });
    }

    ngOnDestroy(): void {
        this.subscribeGetMethods.unsubscribe();
    }
}
