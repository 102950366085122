import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {AccountManagementProviderService} from './account-management-provider.service';
import {AuthenticationService} from '@modules/authentication';
import {ProfileService} from './profile/profile.service';

import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {DataEntity} from 'octopus-connect';
import {FuseSharedModule} from '@fuse/shared.module';

import {FuseRegisterComponent} from './register/register.component';
import {FuseProfileComponent} from './profile/profile.component';
import {FuseProfileAboutComponent} from './profile/tabs/about/about.component';
import {FuseFormsComponent} from './profile/tabs/edit-profile/forms.component';
import {UploadFileComponent} from './profile/upload-file/upload-file.component';

import {ValidateEmailModule} from '@modules/account-management/core/validate-email/validate-email.module';
import {FuseValidateEmailComponent} from '@modules/account-management/core/validate-email/validate-email.component';
import {FuseRequestSubscribeComponent} from '@modules/account-management/core/request-subscribe/request-subscribe.component';
import {FuseMailConfirmComponent} from '@modules/account-management/core/mail-confirm/mail-confirm.component';
import {SharedModule} from 'shared/shared.module';
import {ShowProfileComponent} from './profile/tabs/show-profile/show-profile.component';
import {LicensingModule} from '@modules/licensing';
import {ProfileDeleteConfirmModalComponent} from './profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {LinkChildComponent} from './components/link-child/link-child.component';
import {UnlinkParentComponent} from '@modules/account-management/core/components/unlink-parent/unlink-parent.component';
import {JoinSchoolComponent} from './profile/tabs/join-school/join-school.component';
import {AccountManagementAuthorizationService} from '@modules/account-management/core/services/account-management-authorization.service';
import {UploadFileGenericComponentModule} from 'fuse-core/components/upload-file-generic/upload-file-generic.module';
import {GoBuyLicenceNewsComponent} from './onboarding/components/go-buy-licence-news/go-buy-licence-news.component';
import {GoBuyLicenceNewsPassiveComponent} from './onboarding/components/go-buy-licence-news-passive/go-buy-licence-news-passive.component';
import {BlockedAccountComponent} from '@modules/account-management/core/blocked-account/blocked-account.component';
import {JoinGroupComponent} from '@modules/account-management/core/profile/tabs/join-group/join-group.component';
import {RegisterRedirectComponent} from './register-redirect/register-redirect.component';
import {TabOrderDirective} from 'fuse-core/directives/tab-order/tab-order.directive';
import {FuseDirectivesModule} from 'fuse-core/directives/directives';
import {IsAllowedBySettings} from "../../../guards/is-allowed-by-settings.class";
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';

const routes: Routes = [
    {
        path: 'profile',
        canActivate: [IsUserLogged],
        component: FuseProfileComponent,
        children:
            [
                {path: '', redirectTo: 'show-profile', pathMatch: 'full'},
                {
                    path: 'show-profile', component: ShowProfileComponent,
                    resolve: {
                        profile: ProfileService
                    }
                },
                {path: 'show-profile/:mode', component: ShowProfileComponent, resolve: {profile: ProfileService}},
                {
                    path: 'link-child',
                    component: LinkChildComponent
                },
                {
                    path: 'unlink-parent',
                    component: UnlinkParentComponent
                },
                {
                    path: '',
                    loadChildren: () => import('@modules/licensing/core/licensing.module').then(m => m.LicensingModule)
                }
            ],
    },
    {
        path: 'feedback',
        canActivate: [IsUserLogged],
        component: FeedbackComponent,
    },
    {
        path: 'editprofile',
        canActivate: [IsUserLogged],
        component: FuseFormsComponent
    },
    {
        path: 'register',
        canActivate: [IsAllowedBySettings],
        component: FuseRegisterComponent,
    },
    {
        path: 'requestsubcribe',
        canActivate: [IsAllowedBySettings],
        component: FuseRequestSubscribeComponent,
    },
];

@NgModule({
    imports: [
        NgHcaptchaModule,
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        FuseSharedModule,
        SharedModule,
        ValidateEmailModule,
        RouterModule.forChild(routes),
        UploadFileGenericComponentModule,
        FuseDirectivesModule
    ],
    declarations: [
        FuseProfileComponent,
        ShowProfileComponent,
        FuseRegisterComponent,
        FuseProfileAboutComponent,
        FuseFormsComponent,
        UploadFileComponent,
        FuseRequestSubscribeComponent,
        FuseMailConfirmComponent,
        BlockedAccountComponent,
        ProfileDeleteConfirmModalComponent,
        FeedbackComponent,
        LinkChildComponent,
        UnlinkParentComponent,
        JoinSchoolComponent,
        JoinGroupComponent,
        GoBuyLicenceNewsComponent,
        GoBuyLicenceNewsPassiveComponent,
        RegisterRedirectComponent,
        NewsletterFormComponent,
    ],
    providers: []
})
export class AccountManagementModule {
    private static isMenuSet = false;

    static forRoot(): ModuleWithProviders<AccountManagementModule> {

        return {
            ngModule: AccountManagementModule,
            providers: [
                AccountManagementProviderService,
                AuthenticationService,
                ProfileService
            ]
        };
    }

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private accountManagementAuthorizationService: AccountManagementAuthorizationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
        this.accountManagementAuthorizationService.activeRulesOnStartup();
    }

    private postLogout(): void {
        AccountManagementModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'feedback');
        this.dynamicNavigation.clearMenuItem('level0', 'unlink-parent');
        this.dynamicNavigation.clearMenuItem('level0', 'link-child');
    }

    private postAuthentication(): void {
        if (!AccountManagementModule.isMenuSet && this.authService.isAuthenticated) {
            // for connexion by gar the link is not the same than when connect by login but it s same code
            // ugly code: but not found better way
            if (!this.authService.isGAR()) {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    'id': 'feedback',
                    'title': 'feedback',
                    'translate': 'generic.feedback',
                    'type': 'link',
                    'icon': '',
                    'url': '/feedback',
                });
            }
            // for connexion by gar the link is not the same than when connect by login but it s same code
            // ugly code: but not found better way
            if (!this.authService.isGAR()) {
                this.dynamicNavigation.refreshModuleMenu('level0', {
                    'id': 'feedback',
                    'title': 'feedback',
                    'translate': 'generic.feedback',
                    'type': 'item',
                    'icon': 'feedback',
                    'url': '/feedback',
                });
            }
            // redirect dashboard'url to basic page url
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'unlink-parent',
                'title': 'unlink-parent',
                'translate': 'unlink_parent.title',
                'type': 'item',
                'icon': '',
                'url': '/profile/unlink-parent',
            });

            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'link-child',
                'title': 'link-child',
                'translate': 'link_child.title',
                'type': 'item',
                'icon': 'link-child',
                'url': '/profile/link-child',
            });

            AccountManagementModule.isMenuSet = true;
        }
    }
}
