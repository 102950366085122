import {Component, inject, OnInit} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {take, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {DataEntity} from 'octopus-connect';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ReadPdfComponent} from 'fuse-core/components/read-pdf/read-pdf.component';
import {
    ModalWrapperMediaComponent
} from 'fuse-core/components/compass/resources/modal-wrapper-media/modal-wrapper-media.component';

export interface Media {
    filemime: string;
    filename: string;
    title: string;
    url: string;
}

@Component({
    selector: 'app-compass-resource',
    templateUrl: './compass-resource.component.html',
})
export class CompassResourceComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    public dialog = inject(MatDialog);
    public displayLoader = true;
    public resource: DataEntity;
    public queryParams: Params = {};
    public goBackLinkName: string = '';

    constructor() {
        super();
        const navigation = this.router.getCurrentNavigation();
        if (navigation?.extras?.state) {
            this.queryParams = navigation.extras.state;
            this.goBackLinkName = navigation.extras.state.selectedThemeTitle;
            if (navigation?.extras?.state.idResource) {
                this.initialize(navigation?.extras?.state.idResource);
            }
        } else {
            this.router.navigate(['boussole']);
        }
    }

    ngOnInit() {
    }
    private initialize(id: string): void {
        this.displayLoader = true;
        this.compassService.loadResourceById(id)
            .pipe(
                take(1),
                tap((resource) => this.resource = resource),
                tap(() => this.displayLoader = false)
            )
            .subscribe();
    }

    openPdf(media: Media): void {
        const dialogRef = this.dialog.open(ReadPdfComponent, {
            data: {
                url: media.url
            },
            width: '80%',
            height: '80%',
            panelClass: 'read-pdf-dialog'
        });
    }

    openVideo(media: Media): void {
        const dialogRef = this.dialog.open(
            ModalWrapperMediaComponent,
            {
                data: {
                    controlsList: 'nodownload',
                    controls: true,
                    video: {
                        uri: media.url,
                        filemime: media.filemime,
                        subtitles: null
                    },
                    title: media.title
                },
                width: '80%',
                panelClass: 'shared-video-dialog'
            }
        );
    }

    openImage(media: Media): void {
        const dialogRef = this.dialog.open(
            ModalWrapperMediaComponent,
            {
                data: {
                    title: media.title,
                    image: {
                        uri: media.url,
                        filemime: media.filemime
                    }
                },
                width: '80%',
                panelClass: 'shared-image-dialog'
            }
        );
    }

    public isMediaImage(media: Media): boolean {
        return ['png', 'jpg', 'jpeg', 'gif'].includes(media.filemime.split('/')[1]);
    }
    public isMediaVideo(media: Media): boolean {
        return media.filemime.includes('mp4');
    }
    public isMediaPdf(media: Media): boolean {
        return media.filemime.includes('pdf');
    }

    public getResourceType(resource: DataEntity, useSlug?: boolean): string {
        const key = useSlug ? 'slug' : 'name';
        if (resource.get('type') && resource.get('type')[0] && resource.get('type')[0][key]) {
            return resource.get('type')[0][key];
        }
        return '';
    }

    goBack() {
        this.router.navigate(['boussole-ressources'], {state: this.queryParams});
    }
}
