import {inject, Injectable} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import * as _ from 'lodash-es';
import {AuthorizationService} from '@modules/authorization';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {AsyncRules, SyncRules} from '@modules/activities/core/models/rules';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {Roles} from 'shared/models/roles';


const INSTITUTION_MANAGER_ROLE_ID = '5';
const INSTITUTION_EDUCATOR_ROLE_ID = '4';

@Injectable({
    providedIn: 'root'
})
export class LessonAuthorizationService {
    private communicationCenter = inject(CommunicationCenterService)
    private activitiesService = inject(ActivitiesService);
    private authorizationService = inject(AuthorizationService);
    private authService = inject(AuthenticationService);
    private lessonsConfig = inject(LessonsConfigurationService);

    private reverseRoleMapping: { [roleNumber: number]: string } = {};
    private atLeastManagerRoles = ['manager', 'administrator'];
    private atLeastDirectorRoles = ['director', ...this.atLeastManagerRoles];
    private atLeastTrainerRoles = ['trainer', ...this.atLeastDirectorRoles];
    private userInstitutions: { admins: { uid: string, roles: Roles[] }[] }[];

    constructor() {

        this.communicationCenter.getRoom('authentication')
            .getSubject('roles')
            .subscribe((roleMapping: { [roleIdentifier: string]: number }) => {
                this.reverseRoleMapping = Object.keys(roleMapping).reduce((obj, roleName) => {
                    const roleId = roleMapping[roleName];
                    obj[roleId] = roleName;
                    return obj;
                }, {});
            });

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject<LessonAuthorizationService['userInstitutions']>('institutionList')
            .subscribe(institutionList => {
                this.userInstitutions  = institutionList || [];
            });
    }

    public activeRulesOnStartup(): void {
        // Le module lesson est appelé plusieurs fois et on ne peut pas tester la préexistence des rules. Donc dans le doute, on supprime chaque rules avant de l'ajouter.
        this.authorizationService.removeRule(AsyncRules.AccessLessonList);
        this.authorizationService.removeRule(AsyncRules.DuplicateLesson);
        this.authorizationService.removeRule(AsyncRules.EditLesson);
        this.authorizationService.removeRule(AsyncRules.PreviewLesson);
        this.authorizationService.removeRule(SyncRules.AccessFormsModels);

        this.authorizationService.addRule(AsyncRules.AccessLessonList, (user) => {
            if (this.lessonsConfig.isInstanceMustCheckLicense()) {
                return this.isUserCanAccessLessonList$(user);
            } else {
                const allowedRoles = this.activitiesService.settings.accessMatrix.lessonsListing.view;
                const userRoles = this.userRoleNames(user);
                return of(allowedRoles.length > 0 && allowedRoles.some(role => userRoles.includes(role)));
            }
        });
        this.authorizationService.addRule(SyncRules.AccessFormsModels, (_user) => this.activitiesService.settings.isAccessibleFormsModels);
        this.authorizationService.addRule(AsyncRules.DuplicateLesson, this.isUserCanDuplicate.bind(this));
        this.authorizationService.addRule(AsyncRules.EditLesson, this.isUserCanEditLesson.bind(this));
        this.authorizationService.addRule(AsyncRules.PreviewLesson, this.isUserCanPreviewLesson.bind(this));
    }

    public isUserHasEducatorRightsInHisInstitutions(user: UserDataEntity): boolean {
        return this.userInstitutions.length > 0 && this.userInstitutions.every(institution =>
            institution.admins.some(admin =>
                admin.uid.toString() === user.id.toString()
                && _.has(admin.roles, INSTITUTION_EDUCATOR_ROLE_ID)
            )
        );
    }

    public isUserHasManagerRightsInHisInstitutions(user: UserDataEntity): boolean {
        return this.userInstitutions.length > 0 && this.userInstitutions.every(institution =>
            institution.admins.some(admin =>
                admin.uid.toString() === user.id.toString()
                && _.has(admin.roles, INSTITUTION_MANAGER_ROLE_ID)
            )
        );
    }

    private isUserCanAccessLessonList$(user): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList').pipe(
                map(() => {
                    if (this.userIsAtLeastDirector(user)) {
                        return true;
                    } else if (this.userIsAtLeastTrainer(user)) {
                        // user can have Free license (no institution) or educator right
                        return !this.isUserHasManagerRightsInHisInstitutions(user) || this.isUserHasEducatorRightsInHisInstitutions(user);
                    }
                })
            );
    }

    private userIsAtLeastDirector(user: UserDataEntity): boolean {
        return this.atLeastDirectorRoles.some((role) => this.userRoleNames(user).includes(role));
    }

    private userIsAtLeastTrainer(user: UserDataEntity): boolean {
        return this.atLeastTrainerRoles.some((role) => this.userRoleNames(user).includes(role));
    }

    private userRoleNames(user: UserDataEntity): string[] {
        return user?.get('role')
            .map(id => this.reverseRoleMapping[id])
            .filter(str => !!str).map(str => str.toLowerCase()) || [];
    }

    private isUserCanEditLesson(user: UserDataEntity, lesson: LessonGranuleEntity): Observable<boolean> {
        // Est-ce que c'est autorisé par les settings
        const isConfigOk = this.lessonsConfig.isLessonEditionEnabled();

        // Si t'as pas de lesson, c'est que l'on test le droit en général
        if(!lesson) {
            return of(isConfigOk);
        }

        // Est-ce que je suis auteur de la lesson
        const isMyLesson = lesson.get('metadatas')?.author === user.id;

        return of(isConfigOk && isMyLesson);
    }


    private isUserCanDuplicate(_user: UserDataEntity, _lesson: LessonGranuleEntity): Observable<boolean> {
        // Est-ce que c'est autorisé par les settings
        const isConfigOk = this.lessonsConfig.isLessonDuplicationEnabled();

        // il n'y a pas de raison qu'une lesson soit explicitement pas duplicable donc on ne la teste pas
        return of(isConfigOk);
    }

    private isUserCanPreviewLesson(_user: UserDataEntity, _lesson: LessonGranuleEntity): Observable<boolean> {
        // Est-ce que c'est autorisé par les settings
        const isConfigOk = this.lessonsConfig.isLessonPreviewAccessible(this.authService.accessLevel as Roles);

        return of(isConfigOk);
    }
}
