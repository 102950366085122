import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Output} from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements AfterViewInit {

    @Output() public columnCounter = new EventEmitter<number>();

    private el = inject(ElementRef);

    ngAfterViewInit(): void {
        this.init();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        console.log("onresize");
        this.init();
    }

    private gridColumnCounter(gridElement) {
        const gridStyle = window.getComputedStyle(gridElement, null);
        return gridStyle.gridTemplateColumns.split(" ").length;
    }

    private init(): void {
        const columnCounter = this.gridColumnCounter(this.el.nativeElement);
        this.columnCounter.emit(columnCounter);
    }

}
