import {Injectable} from '@angular/core';
import {OctopusConnectService} from 'octopus-connect';
import {filter, map, tap} from 'rxjs/operators';
import {TypedDataEntityInterface} from '../../app/shared/models/octopus-connect/typed-data-entity.interface';
import {TypedDataCollectionInterface} from '../../app/shared/models/octopus-connect/typed-data-collection.interface';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash-es';

export type ChapterCollection = TypedDataCollectionInterface<ChaptersEntityAttributes>;
export type ChapterEntity = TypedDataEntityInterface<ChaptersEntityAttributes>;

// Attributs de la DataEntity derrière le endpoint Chapters
export interface ChaptersEntityAttributes {
    // Identifiants des concepts liés
    concepts: string[];
    label: string;
    name: string;
    // Identifiants des chapitres parents de ce chapitre
    parent: string[];
    // Ne semble jamais être utilisé et toujours valoir null
    sources: unknown;
}

export type ChapterFilters = Partial<{
    parent: 'null' | string;
    label: string;
    grade: (string | number)| (string | number)[],
    concepts: (string | number)| (string | number)[];
    id?: string | number | (string | number)[];
}>;

export const ONLY_PARENT_CHAPTERS_FILTER: ChapterFilters = {
    parent: 'null'
};

const CHAPTERS_ENDPOINT = 'chapters';

export type GetChaptersOptions = Partial<{
    filters: Partial<ChapterFilters>;
    allowCache: boolean,
    forceRefresh: boolean
}>;

@Injectable({
    providedIn: 'root'
})
export class ChaptersService {
    private chaptersCache: {
        filters: ChapterFilters,
        chapters: ChapterEntity[]
    }[] = [];
    private readonly defaultOptions: GetChaptersOptions = {
        allowCache: true,
        forceRefresh: false
    } as const;

    constructor(private octopusConnect: OctopusConnectService) {
    }

    public getParentChapters(): Observable<ChapterEntity[]> {
        return this.getChapters({filters: ONLY_PARENT_CHAPTERS_FILTER});
    }

    public getChapters(options: GetChaptersOptions = this.defaultOptions): Observable<ChapterEntity[]> {
        const mergedOptions = _.merge({}, this.defaultOptions, options);

        if (mergedOptions.allowCache === false) {
            return this.internalGetChaptersFromServer(mergedOptions.filters).pipe(map(collection => collection.slice()));
        }

        const cachedFilters = this.internalGetChaptersFromCache(options.filters);

        if (options.forceRefresh || cachedFilters === undefined) {
            return this.internalGetChaptersFromServer(mergedOptions.filters).pipe(
                map(collection => collection.slice()),
                tap(collection => this.internalSetCache(mergedOptions.filters, collection.slice()))
            );
        }

        return of(cachedFilters.slice());
    }

    public getChapter(chapterId: number): Observable<ChapterEntity | undefined> {
        return this.octopusConnect.loadEntity(CHAPTERS_ENDPOINT, chapterId) as Observable<ChapterEntity | undefined>;
    }

    private internalGetChaptersFromServer(chapterFilters: ChapterFilters): Observable<ChapterEntity[]> {
        return this.octopusConnect.loadCollection(CHAPTERS_ENDPOINT, chapterFilters)
            .pipe(
                filter(collection => !!collection),
                map(collection => (<ChapterCollection>collection).entities)
            );
    }

    private internalGetChaptersFromCache(filters: ChapterFilters): ChapterEntity[] | undefined {
        const cached = this.getFilterChapterCacheCombined(filters);
        return !!cached ? cached.chapters : undefined;
    }

    private internalSetCache(filters: ChapterFilters, chapters: ChapterEntity[]): void {
        const cachedData = this.getFilterChapterCacheCombined(filters);

        if (!!cachedData) {
            cachedData.chapters = chapters;
        } else {
            this.chaptersCache.push({
                filters: filters,
                chapters: chapters
            });
        }
    }

    private getFilterChapterCacheCombined(filters: ChapterFilters): { filters: ChapterFilters; chapters: ChapterEntity[] } {
        return this.chaptersCache.find(cachedChaptersBatchIteration =>
            _.isEqual(filters, cachedChaptersBatchIteration.filters)
        );
    }
}

