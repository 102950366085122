import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html'
})
/**
 * component that show level of learner and number of coins wins
 */
export class ProgressComponent {
    @Input() level: number = 0;
    @Input() coins: number = 0;
    @Input() img: string = 'coin';
    public username: string;

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        // TODO le composant ne devrait pas savoir comment on récupère le pseudo, il devrait le récupérer du parent
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.username = data?.get('nickname');
            });
    }

}