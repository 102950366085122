import {Injectable} from '@angular/core';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/assignation/core/models/rules';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Observable, of} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {AssignationService} from "@modules/assignation";

const AUTONOMY_USAGE_LABEL = 'usage.autonomie';

interface LicenceInterface {
    'id': string | number;
    'code': string;
    'type'?: {
        'id': string | number;
        'label': string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class AssignationAuthorizationService {
    private userLicences: TypedDataEntityInterface<LicenceInterface>[];

    constructor(private config: AssignationConfigurationService,
                private authorization: AuthorizationService,
                private authentication: AuthenticationService,
                private assignation: AssignationService,
                private communicationCenter: CommunicationCenterService) {

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('userLicenses')
            .subscribe((userLicenses) => this.userLicences = userLicenses
            );
    }

    public activeRulesOnstartUp(): void {
        if (this.config.doTrainersHaveRestrictiveRights()) {
            this.activeInstitutionGivenRightsTrainersRules();
        } else {
            this.activeSimplyTrainersRules();
        }
    }

    private isUserHasEducatorRights(user: UserDataEntity): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groupsManagement')
            .getSubject('isUserHasEducatorRightsInHisInstitutionsCallback')
            .pipe(
                take(1),
                mergeMap((callBack: (UserDataEntity) => Observable<boolean>) => callBack(user))
            );
    }

    private activeInstitutionGivenRightsTrainersRules(): void {
        /**
         * - Assigner des leçons à ses élèves
         * - Assigner des leçons à ses classes
         * - Assigner des leçons à ses groupes
         * - Suivre les assignations qu'il a donné
         */
        this.authorization.addRule(AsyncRules.ReadAssignments, (user) => {
            if (this.authentication.isLearner()) {
                if (this.config.learnerMustBeInGroupToSeeMenu()) {
                    if (user.get('groups').length > 0) {
                        return of(true);
                    } else {
                        // on lit quand même les assignements si l'user en a
                        return this.assignation.loadPaginatedAssignments({filter: {assignated_user: user.id}})
                            .pipe(map((assignments) => {
                                return assignments.length > 0;
                            }));
                    }
                } else {
                    return of(true);
                }
            } else if (this.authentication.isAtLeastDirector()) {
                return of(true);
            } else if (this.authentication.isTrainer()) {
                return this.isUserHasEducatorRights(user);
            }
            return of(false);
        });

        this.authorization.addRule(AsyncRules.AssignLesson, (user): Observable<boolean> => {
            if (this.config.isAssignationActionIsActive()) {
                if (this.authentication.isAtLeastManager()) {
                    return of(true);
                } else if (this.authentication.isTrainer()) {
                    if (this.isAssignationAllowedByLicenceByDefault()) {
                        return of(true);
                    } else {
                        return this.isUserHasEducatorRights(user);
                    }
                } else if (this.authentication.isDirector()) {
                    return of(true);
                }
            }
            return of(false);
        });
    }

    private activeSimplyTrainersRules(): void {
        this.authorization.addRule(AsyncRules.ReadAssignments, (user) => {
            if (this.authentication.isAtLeastLearner()) {
                if (this.config.learnerMustBeInGroupToSeeMenu() && this.authentication.isLearner()) {
                    if (user.get('groups').length > 0) {
                        return of(true);
                    } else {
                        // on lit quand même les assignements si l'user en a
                        return this.assignation.loadPaginatedAssignments({filter: {assignated_user: user.id}})
                            .pipe(map((assignments) => {
                                return assignments.length > 0;
                            }));
                    }
                } else {
                    return of(true);
                }
            }

            return of(false);
        });

        this.authorization.addRule(AsyncRules.IsUserHasTrainerRight, (user) => {
            return of(this.authentication.isAtLeastLearner());
        });

        this.authorization.addRule(AsyncRules.AssignLesson, (_user, granuleLesson: DataEntity) => {
            let granuleIsOk = false;
            let assignFeatureActive = false;
            let userIsOk = false;

            if(!granuleLesson) {
                granuleIsOk = true;
            } else if (this.config.isAssignationIsLimitedByUsage()) {
                granuleIsOk = granuleLesson?.get('usage').some(e => e.label === AUTONOMY_USAGE_LABEL);
            } else {
                granuleIsOk = true;
            }

            assignFeatureActive = this.config.isAssignationActionIsActive()
            userIsOk = this.authentication.isAtLeastTrainer();

            return of(granuleIsOk && assignFeatureActive && userIsOk);
        });
    }

    private isAssignationAllowedByLicenceByDefault(): boolean {
        return this.userLicences?.every(l => {
                const label = l.get('type')?.label;
                return label !== 'Class' && label !== 'Institution';
            }
        );
    }
}
