import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import * as _ from 'lodash-es';
import {UserDataEntity} from '@modules/authentication';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {defaultRoute} from '../settings';

/**
 * Object to pass to {@link IsUserAuthorisedByRoleGuard} to accept or not a route.
 */
export interface ForbiddenPathInterface {
    // Anything to reference the rule, can be a string, object, etc.
    identifier: any;
    // Url, path or route used to applied rule
    path: string | RegExp;

}

/**
 * Used to define if the user has the good role for a path.
 * The path & roles has to be pass with the {@link CommunicationCenterService} (Room : `IsUserAuthorisedByRoleGuard`, subject: `add|remove`).
 */
@Injectable()
export class IsUserAuthorisedByRoleGuard {

    private forbiddenPaths: ForbiddenPathInterface[] = [];
    private userData$: Observable<UserDataEntity>;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private router: Router
    ) {
        // get user data because before user was logged user data restritive route rules have no senses
        this.userData$ = this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData');

        this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').getSubject('add')
            .subscribe((authorisedRoutes: ForbiddenPathInterface[]) => {
                if (this.forbiddenPaths.some(inCacheAuthRoute => authorisedRoutes.map(toAddAuthRoute => _.isEqual(toAddAuthRoute.identifier, inCacheAuthRoute.identifier)))) {
                    throw new Error('Duplicate rules in authorised routes by roles');
                }

                this.forbiddenPaths.push(...authorisedRoutes);
            });

        this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').getSubject('remove')
            .subscribe((authorisedRoutes: ForbiddenPathInterface[]) => {
                this.forbiddenPaths = this.forbiddenPaths.filter(inCacheAuthRoute =>
                    authorisedRoutes.some(toDeleteAuthRoute => _.isEqual(toDeleteAuthRoute.identifier, inCacheAuthRoute.identifier)) === false
                );
            });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userData$.pipe(map(user => {
            if (this.forbiddenPaths.some(a => this.isCurrentRoute(state, a.path))) {
                this.router.navigate([defaultRoute]);
                return false;
            } else {
                return true;
            }
        }));
    }

    private isCurrentRoute(state: RouterStateSnapshot, testRoute: string | RegExp): boolean {
        if (_.isRegExp(testRoute)) {
            return new RegExp(testRoute).test(state.url);
        }

        return state.url === testRoute;
    }
}
