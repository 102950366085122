// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profile .content {
  flex: 1;
}
#profile .content mat-tab-group {
  height: 100%;
}
#profile .content mat-tab-group .mat-tab-body-wrapper {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/account-management/core/profile/profile.component.scss"],"names":[],"mappings":"AAGI;EACI,OAAA;AAFR;AAIQ;EACI,YAAA;AAFZ;AAIY;EACI,YAAA;AAFhB","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n\r\n#profile {\r\n    .content {\r\n        flex: 1;\r\n\r\n        mat-tab-group {\r\n            height: 100%;\r\n\r\n            .mat-tab-body-wrapper {\r\n                flex-grow: 1;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
