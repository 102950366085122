import {DataEntity} from 'octopus-connect';
import {localizedDate} from 'shared/utils/datetime';

export class Trainer {
    public id: number;
    public avatar: string;
    public username: string;
    public email: string;
    public password: string;
    public type: string;
    public access: string;
    public institution: string;

    constructor(private entity: DataEntity) {
        this.id = this.entity ? +this.entity.id : -1;
        this.avatar = this.entity.get('picture');
        this.username = this.entity.get('label');
        this.email = this.entity.get('email');
        this.password = '';
        this.type = this.entity.get('type');
        this.institution = this.entity.get('institution');
        this.access = this.entity.get('access') === '0' ? 'Never' : localizedDate(this.entity.get('access'));
    }

}