import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import * as _ from 'lodash-es';
import {combineLatest, ReplaySubject, Observable, Subject} from 'rxjs';
import {GraphBayardService} from '@modules/graph-bayard/core/services/graph-bayard.service';
import {filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {Learner} from '@modules/graph-bayard/core/model/learner';
import {GraphFilter, GraphFilterCustomRule} from '@modules/graph-bayard/core/model/graph-filter';
import {GraphFiltersValues} from '@modules/graph-bayard/core/model/graph-filters-values';
import {RawGraphFiltersValues} from '@modules/graph-bayard/core/model/raw-graph-filters-values';
import * as moment from 'moment';
import {Group} from '@modules/graph-bayard/core/model/group';
import {Workgroup} from '@modules/graph-bayard/core/model/workgroup';
import {ChapterEntity} from 'fuse-core/services/chapters.service';
import {EducationalLevelEntity} from '@modules/activities/core/services/educational-level.service';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {SkillEntity} from '@modules/graph-bayard/core/services/skills.service';
import {GenericContextualService} from "fuse-core/services/generic-contextual.service";
import {ConceptEntity} from 'shared/models';

@Component({
    selector: 'app-shared-filters',
    templateUrl: './shared-filters.component.html',
})
export class SharedFiltersComponent implements OnInit, OnDestroy {
    public controls = {
        endDate: new UntypedFormControl(),
        chapter: new UntypedFormControl(),
        learner: new UntypedFormControl(),
        startDate: new UntypedFormControl(),
        view: new UntypedFormControl(),
        group: new UntypedFormControl(),
        workgroup: new UntypedFormControl(),
        multiLearner: new UntypedFormControl(),
        multiLesson: new UntypedFormControl(),
        multiSubLesson: new UntypedFormControl(),
        concept: new UntypedFormControl(),
        educationalLevel: new UntypedFormControl(),
        skill: new UntypedFormControl(),
    };
    public barIsExpended = true;
    public filteredLearners: Observable<{id: string, nickname: string}[]>;
    public filteredByGroupLearners: ReplaySubject<{id: string, nickname: string}[]> = new ReplaySubject<{id: string, nickname: string}[]>();
    public lessons: DataEntity[] = [];
    public subLessons: DataEntity[] = [];
    public isReady = false;
    public chapters: ChapterEntity[] = [];
    public concepts: ConceptEntity[] = [];
    public educationalLevels: EducationalLevelEntity[] = [];
    public skills: SkillEntity[] = [];
    public groups: Group[] = [];
    public workgroups: Workgroup[] = [];
    private filters: { always: GraphFilter[], hidden: GraphFilter[] } = {always: [], hidden: []};
    private unsubscribeInTakeUntil = new Subject<void>();
    private learnerSorted: {id: string, nickname: string}[] = [];
    private filtersValues: Partial<RawGraphFiltersValues> = {};
    private learners: Learner[] = [];

    constructor(
        private graphBayardService: GraphBayardService,
        private contextualService: GenericContextualService,
        private adapterLocalDate: DateAdapter<any>,
        private translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.isReady = false;
        this.graphBayardService.isReady
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
            ).subscribe((isReady) => {
            this.isReady = false;
            if (isReady) {

                this.adapterLocalDate.setLocale(this.translate.currentLang);

                this.translate.onLangChange.subscribe(() => {
                    this.adapterLocalDate.setLocale(this.translate.currentLang);

                });

                this.graphBayardService.forceFiltersValues.pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    takeUntil(this.graphBayardService.isReady.pipe(filter((shadowedIsReady) => shadowedIsReady === false)))
                ).subscribe(() => this.redoAtEachForcedFilters());

                this.learners = this.graphBayardService.learners;
                this.learnerSorted = this.graphBayardService.getLearnersAlphabetically();
                this.lessons = this.graphBayardService.lessons;
                this.subLessons = [];
                this.chapters = this.graphBayardService.chapters;
                this.concepts = this.graphBayardService.concepts;
                this.educationalLevels = this.graphBayardService.educationalLevels;
                this.skills = this.graphBayardService.skills;
                this.groups = this.graphBayardService.groups;
                this.workgroups = this.graphBayardService.workgroups;
                this.initLists();
                this.initAutoSubmitOnChanges();
                this.initAutoFills();
                this.initDefaultValues();
                this.initDisabledFilters();
                this.initRequiredFilters();

                // this.graphBayardService.silentlySetFilterValues.pipe(
                //     takeUntil(this.unsubscribeInTakeUntil)
                // ).subscribe((data: { field: string, value: any }) => {
                //     (<UntypedFormControl>this.controls[data.field]).setValue(data.value);
                // });
                this.isReady = true;
            }
        });

        this.setupContextual();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public isDisplayed(filterName: string): boolean {
        try {
            return this.filters.always.map(f => f.label).includes(filterName) || (
                this.barIsExpended && this.filters.hidden.map(f => f.label).includes(filterName)
            );
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    public toggle(): void {
        this.barIsExpended = !this.barIsExpended;
    }

    public couldExpend(): boolean {
        return this.filters.hidden.length > 0;
    }

    public isEmptyAllowed(field: string): boolean {
        return this.isFieldHasRule(field, 'allowEmpty');
    }

    private setupContextual(): void {
        this.contextualService.dataField$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({field, callback}) => {
                let data: string[] = [];

                switch (field) {
                    case 'group':
                        data = this.groups.map((group) => group.groupname);
                        break;
                    case 'learner':
                        data = this.learners.map((learner) => learner.nickname);
                        break;
                }

                callback(data);
            });

        this.contextualService.actionFilter$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({field, value}) => {
                if(field == "learner" && this.controls[field]){
                    const matchingLearner = this.learners.find(learner => learner.nickname === value);
                    if (matchingLearner) {
                        this.controls[field].setValue(matchingLearner);
                    }
                } else if (this.controls[field]) {
                    this.controls[field].setValue(value);
                }
            });
    }

    private initLists(): void {
        this.filteredLearners = this.controls.learner.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterLearners(value))
            );
        // this.filteredExerciseName = this.controls.exerciseName.valueChanges
        //     .pipe(
        //         startWith(''),
        //         map(value => this.filterExercises(value))
        //     );

        combineLatest([
            this.controls.group.valueChanges,
            this.controls.workgroup.valueChanges
        ]).pipe(
            map(([group, workgroup]) => {
                this.filteredByGroupLearners.next(this.filterLearnersByGroup(group, workgroup));
            })
        ).subscribe();
    }

    private initAutoSubmitOnChanges(): void {
        for (const control of Object.keys(this.controls)) {
            this.controls[control].valueChanges.subscribe((v) => {
                this.updateFiltersValues(control, v);
                this.emitFiltersValues();
            });
        }
    }

    private filterLearners(learner: {id: string, nickname: string} | string) {
        const filterValue = typeof learner === 'string' ? learner : learner?.nickname;
        return this.learnerSorted.filter(l => l.nickname.toLowerCase().includes(filterValue));
    }

    private updateFiltersValues(control: string, v: any): void {
        if (v === undefined || v === null || v === '' || (Array.isArray(v) && v.length === 0)) {
            delete this.filtersValues[control];
        } else {
            this.filtersValues[control] = v;
        }
    }

    private emitFiltersValues(): void {
        const raw = _.cloneDeep(this.filtersValues);
        // On duplique this.filtersValues mais il a pas le meme format que notre variable values
        // alors on va ajouter/retirer des données a values pour respecter le format
        const optimised: Partial<GraphFiltersValues & RawGraphFiltersValues & {learner: any}> = _.cloneDeep(raw);

        Object.keys(optimised).forEach((field) => {
            if (this.isIgnored(field)) {
                delete optimised[field];
                return;
            }

            if (field === 'multiLesson') {
                const lessonIds = this.filtersValues.multiLesson;
                if (lessonIds.length > 0) {
                    optimised.lessonList = lessonIds.map(id => +id);
                } else {
                    delete optimised.lessonList;
                }
                delete optimised.multiLesson;
                return;
            }

            if (field === 'multiLearner') {
                const learnerIds= this.filtersValues.multiLearner;
                if (learnerIds.length > 0) {
                    optimised.learnerList = learnerIds.map(id => +id);
                } else {
                    delete optimised.learnerList;
                }
                delete optimised.multiLearner;
                return;
            }

            if (field === 'learner') {
                const learner = this.learners.find(l => l.id === this.filtersValues.learner.id);
                if (!!learner) {
                    optimised.learner = learner.id;
                } else {
                    delete optimised.learner;
                }
                return;
            }

            if (field === 'chapter') {
                const chapter = this.chapters.find(e => e.attributes.name === this.filtersValues.chapter);
                if (!!chapter) {
                    optimised.chapter = chapter.id.toString();
                } else {
                    delete optimised.chapter;
                }
                return;
            }

            if (field === 'concept') {
                const concept = this.concepts.find(c => c.attributes.name === this.filtersValues.concept);
                if (!!concept) {
                    optimised.concept = concept.id.toString();
                } else {
                    delete optimised.concept;
                }
                return;
            }

            if (field === 'educationalLevel') {
                const educationalLevel = this.educationalLevels.find(c => c.attributes.label === this.filtersValues.educationalLevel);
                if (!!educationalLevel) {
                    optimised.educationalLevel = educationalLevel.id.toString();
                } else {
                    delete optimised.educationalLevel;
                }
                return;
            }

            if (field === 'skill') {
                const skill = this.skills.find(s => s.attributes.label === this.filtersValues.skill);
                if (!!skill) {
                    optimised.skill = skill.id.toString();
                } else {
                    delete optimised.skill;
                }
                return;
            }

            if (field === 'group') {
                const group = this.groups.find(g => g.groupname === this.filtersValues.group);
                if (!!group) {
                    optimised.group = group.id.toString();
                } else {
                    delete optimised.group;
                }
                return;
            }

            if (field === 'workgroup') {
                const workgroup = this.workgroups.find(wg => wg.workgroupname === this.filtersValues.workgroup);
                if (!!workgroup) {
                    optimised.workgroup = workgroup.id.toString();
                } else {
                    delete optimised.workgroup;
                }
                return;
            }

            if (field === 'startDate') {
                optimised.startDate = moment(optimised.startDate).toDate();
                return;
            }

            if (field === 'endDate') {
                optimised.endDate = moment(optimised.endDate).endOf('day').toDate();
                return;
            }
        });

        this.graphBayardService.filtersChanges.next({
            raw: (<any> raw),
            optimised
        });
    }

    private initDefaultValues(): void {
        // this.graphBayardService.setLearnerDynamicFilterWithCacheFilter();
        this.barIsExpended = true;
        this.filters = _.clone(this.graphBayardService.dynamicFilters);

        Object.keys(this.controls).forEach(control => this.controls[control].setValue(null));
        [...this.filters.always, ...this.filters.hidden]
            .forEach(f => this.controls[f.label].setValue(f.value));
    }

    private filterLearnersByGroup(groupName: string, workgroupName: string): {id: string, nickname: string}[] {
        const group = _.get(this.groups.find(g => g.groupname === groupName), 'id');
        const workgroup = _.get(this.workgroups.find(g => g.workgroupname === workgroupName), 'id');

        const groupAndWorkgroupLearnersIds = this.graphBayardService.getLearnerFilteredOfGroupAndWorkgroup(group, workgroup);
        let results = this.learners.slice();

        if (!!group || !!workgroup) {
            results = results.filter(l => groupAndWorkgroupLearnersIds.includes(+l.id));
        }
        return results
    }

    private isIgnored(field: string): boolean {
        return this.isFieldHasRule(field, 'ignore');
    }

    private fieldsToAutofill(field: string): string[] {
        const fieldConfig = [...this.filters.always, ...this.filters.hidden].find(f => f.label === field);

        if (!!fieldConfig && !!fieldConfig.custom && !!fieldConfig.custom['rules']) {
            const rules = fieldConfig.custom['rules'].filter(rule => rule.startsWith('autofill:'));

            if (rules.length > 0) {
                return rules.map(r => r.replace('autofill:', ''));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private isFieldHasRule(field: string, rule: GraphFilterCustomRule): boolean {
        try {
            const fieldConfig = [...this.filters.always, ...this.filters.hidden].find(f => f.label === field);

            if (!!fieldConfig) {
                return fieldConfig.custom['rules'].includes(rule);
            }
        } catch (e) {
            // on avale volontairement l'erreur pour pas avoir a tester
        }

        return false;
    }

    // private autoFillMultiSubLessonWithMultiLesson(): void {
    //     const multiLesson = this.controls.multiLesson.value || [];
    //     if (multiLesson.length > 0) {
    //         const lessons = this.graphBayardService.lessons.filter(l => multiLesson.includes(l.attributes.metadatas.title));
    //         const subLessonPayloads = lessons.map(l => l.attributes.reference)
    //             .reduce((acc, curr) => acc.concat(curr), [])
    //             .map(l => ({callbackSubject: new Subject<Subject<DataEntity>>(), lessonId: l.id}));
    //         const subLessons$ = subLessonPayloads.map(payload => payload.callbackSubject.asObservable());
    //         combineLatest(subLessons$).pipe(
    //             take(1),
    //             mergeMap(subLessons$ => combineLatest(subLessons$))
    //         ).subscribe(subLessons => {
    //             this.subLessons = subLessons;
    //         });
    //
    //         subLessonPayloads.forEach(payload =>
    //             this.communicationCenter
    //                 .getRoom('lessons')
    //                 .next('getLesson', payload));
    //     } else {
    //         this.controls.multiSubLesson.setValue([]);
    //     }
    // }

    private autoFillMultiLessonWithChaptersAndConceptsAndEducationalLevelsAndSkills(): void {

        let lessons = this.graphBayardService.lessons.slice();
        const chapterName = this.controls.chapter.value;
        if (!!chapterName) {
            lessons = lessons.filter(l => {
                const chapter = this.chapters.find(c => c.attributes.name === chapterName);
                return l.attributes.metadatas.chapters.some(c => c.id.toString() === chapter.id.toString());
            });
        }

        const educationalLevelName = this.controls.educationalLevel.value;
        if (!!educationalLevelName) {
            lessons = lessons.filter(l => {
                const educationalLevel = this.educationalLevels.find(c => c.attributes.label === educationalLevelName);
                return l.attributes.metadatas.educationalLevel.some(c => c.id.toString() === educationalLevel.id.toString());
            });
        }

        const conceptName = this.controls.concept.value;
        if (!!conceptName) {
            lessons = lessons.filter(l => {
                const concept = this.concepts.find(c => c.attributes.name === conceptName);
                return l.attributes.metadatas.concepts.some(c => c.id.toString() === concept?.id?.toString())
            });
        }

        const skillName = this.controls.skill.value;
        if (!!skillName) {
            lessons = lessons.filter(l => {
                const skill = this.skills.find(c => c.attributes.label === skillName);
                return l.attributes.metadatas.skills.some(c => c.id.toString() === skill.id.toString());
            });
        }

        this.lessons = lessons;
    }

    private autoFill(field: string, targetFill: string): void {
        switch ([field, targetFill].join(':')) {
            case 'group:multiLearner': {
                this.autoFillMultiLeanerWithGroup();
                break;
            }
            case 'concept:multiLesson':
            case 'chapter:multiLesson':
            case 'educationalLevel:multiLesson':
            case 'skill:multiLesson': {
                this.autoFillMultiLessonWithChaptersAndConceptsAndEducationalLevelsAndSkills();
                break;
            }
            default: {
                throw new Error(`Autofill ${targetFill} with ${field} not implemented`);
            }
        }
    }

    private autoFillMultiLeanerWithGroup(): void {
        const group = this.groups.find(g => g.groupname === this.filtersValues.group);
        if (!!group) {
            // Deux façon de faire, on pourrait activer tout les learner du champ ou activer tout les learner du group
            const learners = this.learners.filter(l => group.learnersIds.includes(+l.id));
            this.controls.multiLearner.setValue(learners.map(l => l.nickname));
        }
    }

    private initAutoFills(): void {
        [...this.filters.always, ...this.filters.hidden].forEach(field => {
            const autoFills = this.fieldsToAutofill(field.label);
            if (autoFills.length > 0) {
                this.controls[field.label].valueChanges.subscribe(() => {
                    autoFills.forEach(targetFill => this.autoFill(field.label, targetFill));
                    this.emitFiltersValues();
                });
            }
        });
    }

    private redoAtEachForcedFilters(): void {
        // Deux initDefaultValues pour que les filtres forcés soient bien pris en compte
        this.initDefaultValues();
        this.initAutoFills();
        this.initDefaultValues();
        this.initDisabledFilters();
        this.initRequiredFilters();
    }

    private filterHasRule(filterLabel: string, rule: GraphFilterCustomRule): boolean {
        return [...this.filters.always, ...this.filters.hidden]
            .find(f => f.label === filterLabel)
            ?.custom
            ?.rules
            ?.includes(rule);
    }

    private initDisabledFilters(): void {
        [...this.filters.always, ...this.filters.hidden].forEach(field => {
            const fieldsToListenForDisable = this.fieldsAssociatedToMyFieldByThisRule(field.label, 'disabledIfEmpty');
            if (fieldsToListenForDisable.length > 0) {
                combineLatest(fieldsToListenForDisable.map(fieldToListenForDisable => this.controls[fieldToListenForDisable].valueChanges))
                    .pipe(
                        map(valuesChanges => valuesChanges.some(value =>
                            value === null || value === ''
                            || ('length' in value && value.length === 0)
                        )),
                        tap((somethingEmpty) => {
                            if (somethingEmpty) {
                                this.controls[field.label].setValue(null);
                                this.controls[field.label].disable();
                            } else {
                                this.controls[field.label].enable();
                            }
                            this.controls[field.label].updateValueAndValidity();
                        }),
                    ).subscribe();
            }
        });
    }

    private initRequiredFilters(): void {
        [...this.filters.always, ...this.filters.hidden]
            .filter(field => this.filterHasRule(field.label, 'required'))
            .forEach(field => {
                this.controls[field.label].addValidators(Validators.required);
                this.controls[field.label].updateValueAndValidity();
            });

    }

    private fieldsAssociatedToMyFieldByThisRule(field: string, rule: string): string[] {
        const rulePrefix = `${rule}:`;
        const fieldConfig = [...this.filters.always, ...this.filters.hidden].find(f => f.label === field);
        if (!!fieldConfig?.custom?.rules) {
            const rules = fieldConfig.custom.rules.filter(rule => rule.startsWith(rulePrefix));

            if (rules.length > 0) {
                return rules.map(r => r.replace(rulePrefix, ''));
            } else {
                return [];
            }
        } else {
            return [];
        }
    }
    public displayLearnerFn(learner: {id: string, nickname: string}): string {
        return learner && learner.nickname ? learner.nickname : '';
    }
}
