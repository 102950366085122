import { Component, inject, OnInit } from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-default-route',
    template: ``,
})
export class DefaultRouteComponent implements OnInit {


    private authenticationService = inject(AuthenticationService);
    constructor(private communicationCenter: CommunicationCenterService) { }

    ngOnInit(): void {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(userData => {
            if (userData) {
                this.authenticationService.overrideDefaultRouteInRegardOfRole(true);
            }
        });
    }

}
