import {Component, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';

@Component({
  selector: 'app-tag-block',
  templateUrl: './tag-block.component.html',
  styleUrls: ['./tag-block.component.scss']
})
export class TagBlockComponent implements OnInit {
    @Input() block: CustomBlock;

  constructor() { }

  ngOnInit(): void {
  }

}
