import {Component, Input} from '@angular/core';
import {QcmAnswer, QcmuAnswer} from '@modules/activity-edition/core/features/activity-editor-panel/answerTypes';

@Component({
  selector: 'app-activity-edition-preview-qcm',
  templateUrl: './activity-edition-preview-qcm.component.html',
  styleUrls: ['./activity-edition-preview-qcm.component.scss']
})
export class ActivityEditionPreviewQcmComponent {
    @Input() answersState!: QcmAnswer | QcmuAnswer;

}
