import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../guards';
import {ContestListComponent} from './component/contest-list/contest-list.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {ContestService} from 'app/@modules/contest';
import {SharedModule} from 'shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ContestCardComponent} from './component/contest-card/contest-card.component';
import {ContestDetailComponent} from './component/contest-management/contest-detail/contest-detail.component';
import {RegistrationDialogComponent} from './component/registration-dialog/registration-dialog.component';
import {ContestTabsComponent} from '@modules/contest/core/component/contest-tabs/contest-tabs.component';
import {ContestRankingComponent} from './component/contest-management/contest-ranking/contest-ranking.component';
import {ContestLessonsComponent} from './component/contest-management/contest-lessons/contest-lessons.component';
import {ContestGroupsComponent} from './component/contest-management/contest-groups/contest-groups.component';
import {ContestHeaderComponent} from './component/contest-management/contest-header/contest-header.component';
import {CONTEST_BASE_ROUTE, CONTEST_TAB_ROUTES} from '@modules/contest/core/contest.routes';
import {ContestAuthorizationService} from "@modules/contest/core/services/contest-authorization.service";
import {ClipboardModule} from "@angular/cdk/clipboard";
import { ContestTimerComponent } from './component/contest-timer/contest-timer.component';
import {IsAllowedBySettings} from "../../../guards/is-allowed-by-settings.class";

const routes: Routes = [
    {
        path: 'contests',
        canActivate: [IsUserLogged, IsAllowedBySettings],
        component: ContestListComponent,
    },
    {
        path: CONTEST_BASE_ROUTE,
        canActivate: [IsUserLogged, IsAllowedBySettings],
        canActivateChild: [IsAllowedBySettings],
        component: ContestTabsComponent,
        children: [
            {
                path: CONTEST_TAB_ROUTES.home,
                component: ContestDetailComponent
            }, {
                path: CONTEST_TAB_ROUTES.lessons,
                component: ContestLessonsComponent
            }, {
                path: CONTEST_TAB_ROUTES.manage,
                component: ContestGroupsComponent
            }, {
                path: CONTEST_TAB_ROUTES.ranking,
                component: ContestRankingComponent
            }
        ]
    }
];

@NgModule({
    declarations: [
        ContestListComponent,
        ContestCardComponent,
        ContestDetailComponent,
        RegistrationDialogComponent,
        ContestTabsComponent,
        ContestRankingComponent,
        ContestLessonsComponent,
        ContestGroupsComponent,
        ContestHeaderComponent,
        ContestTimerComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        ClipboardModule
    ],
})
export class ContestModule {

    private static isMenuSet = false;

    constructor(
        private dynamicNavigationService: DynamicNavigationService,
        private communicationCenter: CommunicationCenterService,
        private contestAuthorizationService: ContestAuthorizationService,
    ) {
        this.contestAuthorizationService.activeRulesOnStartup();
        
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postAuthentication(): void {
        if (!ContestModule.isMenuSet) {
            this.dynamicNavigationService.registerModuleMenu('level0', {
                'id': 'contests',
                'title': 'contests',
                'translate': 'contest.list_title',
                'type': 'item',
                'icon': 'award',
                'url': '/contests',
            });

            ContestModule.isMenuSet = true;
        }
    }

    private postLogout(): void {
        ContestModule.isMenuSet = false;
        this.dynamicNavigationService.clearMenuItem('level0', 'contests');
    }

    static forRoot(): ModuleWithProviders<ContestModule> {
        return {
            ngModule: ContestModule,
            providers: [
                ContestService,
            ]
        };
    }

}
