import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';

/**
 * used to calculate progress in questionSet (sub-lesson)
 */
export interface AnswerResultInterface {
    id: number;
    state: ItemAnswerStateEnum;
    isLast: boolean;
    forceStateToCurrentlyCorrect?: boolean; // cas ou on a besoin de forcer un état "bonne reponse"
    answer?: AnswerInterface;
    multipleAnswers?: AnswerInterface[];
}