import {Injectable} from '@angular/core';
import {OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';
import {map} from 'rxjs/operators';

const SKILLS_ENDPOINT = 'skills';

export interface SkillsAttribute {
    label: string,
    name: string
}

export type SkillEntity = TypedDataEntityInterface<SkillsAttribute>;
export type SkillCollection = TypedDataCollectionInterface<SkillsAttribute, SkillEntity>;

@Injectable({
    providedIn: 'root'
})
export class SkillsService {

    constructor(
        private octopusConnect: OctopusConnectService
    ) {
    }

    public getSkillsCollection() {
        return this.octopusConnect.loadCollection(SKILLS_ENDPOINT) as Observable<SkillCollection>;
    }

    public getSkills() {
        return this.getSkillsCollection()
            .pipe(
                map(collection => collection.entities)
            );
    }
}
