import {merge} from 'lodash-es';
import {ModelSchema, Structures} from 'octopus-model';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthenticationService} from '@modules/authentication';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ChapterRecapComponent} from './components/resource-upload-modal-step3/chapter-recap/chapter-recap.component';
import {ChapterSelectorComponent} from './components/resource-upload-modal-step3/chapter-selector/chapter-selector.component';
import {ChaptersModule} from 'fuse-core/components/chapters-selection/chapters.module';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {CorpusConsultedComponent} from '@modules/corpus/core/components/widget/corpus-consulted/corpus-consulted.component';
import {CorpusCreatedComponent} from '@modules/corpus/core/components/widget/corpus-created/corpus-created.component';
import {CorpusDetailsSidenavComponent} from '@modules/corpus/core/components/corpus-sidenavs/corpus-details/corpus-details.component';
import {CorpusDisplayComponent} from './components/corpus-display/corpus-display.component';
import {CorpusFavoritesComponent} from './components/widget/corpus-favorites/corpus-favorites.component';
import {CorpusFileListComponent} from '@modules/corpus/core/components/corpus-file-list/corpus-file-list.component';
import {CorpusListComponent} from './components/corpus-list/corpus-list.component';
import {CorpusMainSidenavComponent} from '@modules/corpus/core/components/corpus-sidenavs/corpus-main/corpus-main.component';
import {CorpusMenuResolve} from '@modules/corpus/core/corpus-menu.resolve';
import {CorpusRootComponent} from './components/corpus-root/corpus-root.component';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {DataEntity} from 'octopus-connect';
import {DatacardService} from 'shared/datacard.service';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {ExternalCorpusDisplayComponent} from './components/external-corpus-display/external-corpus-display.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {LayoutModule} from '../../../layout/layout.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ResourceEditionModalComponent} from './components/resource-edition-modal/resource-edition-modal.component';
import {ResourceShareToGroupsModalComponent} from './components/resource-share-to-groups-modal/resource-share-to-groups-modal.component';
import {ResourceUploadModalComponent} from './components/resource-upload-modal/resource-upload-modal.component';
import {ResourceUploadModalErrorComponent} from './components/resource-upload-modal-error/resource-upload-modal-error.component';
import {ResourceUploadModalStep1Component} from './components/resource-upload-modal-step1/resource-upload-modal-step1.component';
import {ResourceUploadModalStep2Component} from './components/resource-upload-modal-step2/resource-upload-modal-step2.component';
import {ResourceUploadModalStep3Component} from './components/resource-upload-modal-step3/resource-upload-modal-step3.component';
import {ResourceUploadModalStep4Component} from './components/resource-upload-modal-step4/resource-upload-modal-step4.component';
import {SearchFiltersModule} from 'fuse-core/components';
import {SharedModule} from 'shared/shared.module';
import {TagsModule} from 'fuse-core/components/tags-selection/tags.module';
import {modulesSettings} from '../../../settings';
import {userCorpusTest} from './is-user-corpus.class';
import {FuseNavigationItem} from 'fuse-core/types';

const routes: Routes = [
    {
        path: 'corpus',
        component: CorpusRootComponent,
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: CorpusListComponent,
            },
            {
                path: ':id',
                resolve: {
                    menu: CorpusMenuResolve,
                },
                children: [
                    {
                        path: '',
                        component: CorpusDisplayComponent,
                    },
                    {
                        path: 'filters/:title',
                        component: CorpusDisplayComponent
                    },
                    {
                        path: 'search',
                        component: CorpusDisplayComponent
                    }
                ]

            },
            {
                path: 'pdf/:id',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve,
                },
            },
            {
                path: 'community/:uid',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve,
                },
                canActivate: [userCorpusTest],
            },
            {
                path: 'user/:uid',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve,
                },
                canActivate: [userCorpusTest],
            },
        ]
    },
];

const settingsStructure: ModelSchema = new ModelSchema({
    accessMatrix: Structures.object(),
});

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        LayoutModule,
        FuseSharedModule,
        SharedModule,
        MatButtonToggleModule,
        // NgxFileDropModule,
        MatProgressSpinnerModule,
        ChaptersModule,
        TagsModule,
        CKEditorModule,
        SearchFiltersModule,
        NgxFileDropModule,
    ],
    declarations: [
        ChapterRecapComponent,
        ChapterSelectorComponent,
        CorpusConsultedComponent,
        CorpusCreatedComponent,
        CorpusDetailsSidenavComponent,
        CorpusDisplayComponent,
        CorpusFavoritesComponent,
        CorpusFileListComponent,
        CorpusListComponent,
        CorpusMainSidenavComponent,
        CorpusRootComponent,
        ExternalCorpusDisplayComponent,
        ResourceEditionModalComponent,
        ResourceUploadModalComponent,
        ResourceUploadModalErrorComponent,
        ResourceUploadModalStep1Component,
        ResourceUploadModalStep2Component,
        ResourceUploadModalStep3Component,
        ResourceUploadModalStep4Component,
        ResourceShareToGroupsModalComponent,
    ],
    providers: [DatacardService, userCorpusTest],
    exports: [
        CorpusDisplayComponent,
        CorpusDetailsSidenavComponent,
        CorpusFileListComponent,
        CorpusListComponent,
        CorpusMainSidenavComponent,
        CorpusRootComponent,
        ExternalCorpusDisplayComponent,
        ResourceEditionModalComponent,
        ResourceUploadModalComponent,
        ResourceUploadModalErrorComponent,
        ResourceUploadModalStep1Component,
        ResourceUploadModalStep2Component,
    ],
})
export class CorpusModule {
    private static isMenuSet = false;
    public settings: { [key: string]: any };

    constructor(
        private corpusService: CorpusService,
        private mediaUploadService: MediaUploadService,
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusDisplayComponent', CorpusDisplayComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusFavoritesComponent', CorpusFavoritesComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusConsultedComponent', CorpusConsultedComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusCreatedComponent', CorpusCreatedComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusService', this.corpusService);

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_RESEARCH_CORPUS',
                icon: 'business',
                text: 'corpus.resource_research',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName'],
                    };
                },
                action: (data: Object) => {
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'corpus',
                        data['corpusId'],
                    ];
                },
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_COURSE_CORPUS',
                icon: 'business',
                text: 'corpus.resource_course',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName'],
                    };
                },
                action: (data: Object) => {
                    return [
                        'projects',
                        data['projectId'],
                        'tools',
                        'corpus',
                        data['corpusId'],
                    ];
                },
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_GLOBAL_CORPUS',
                icon: 'business',
                text: 'corpus.resource_global',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName'],
                    };
                },
                action: (data: Object) => {
                    return ['corpus', data['corpusId']];
                },
            });

        this.settings = settingsStructure.filterModel(modulesSettings.corpus);
    }

    static forRoot(): ModuleWithProviders<CorpusModule> {
        return {
            ngModule: CorpusModule,
            providers: [
                CorpusService,
                CorpusMenuResolve,
                MediaUploadService,
                {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
            ],
        };
    }

    private postLogout(): void {
        CorpusModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'corpus');
        this.dynamicNavigation.clearMenuItem('level0', 'corpusPdf');
        this.dynamicNavigation.clearMenuItem('level0', 'corpus-global');
        this.dynamicNavigation.clearMenuItem('level0', 'corpus-user');
        this.dynamicNavigation.clearMenuItem('level0', 'corpus-community');
    }

    private postAuthentication(): void {
        if (
            !CorpusModule.isMenuSet &&
            this.corpusService.settings.globalCorpus &&
            this.settings &&
            this.authService.hasLevel(this.settings.accessMatrix['global']['view'])
        ) {
            const corpusGlobal: FuseNavigationItem = {
                id: 'corpus-global',
                title: 'Ressources Lamorim',
                translate: 'corpus.title.generic',
                type: 'item',
                url: '/corpus/' + this.corpusService.settings.globalCorpus,
            };

            const corpusUser: FuseNavigationItem = {
                id: 'corpus-user',
                title: 'Mes ressources',
                translate: 'corpus.title.user',
                type: 'item',
                url: '/corpus/user/' + this.corpusService.currentUser.id,
            };

            const children = [
                corpusGlobal,
                corpusUser
            ];

            if (!this.corpusService.settings.userCorpus) {
                // check if there is a user corpus in addition to general corpus
                this.dynamicNavigation.registerModuleMenu('level0', {
                    id: 'corpus',
                    title: 'Corpus',
                    translate: 'corpus.title',
                    type: 'item',
                    icon: 'corpus',
                    url: '/corpus/' + this.corpusService.settings.globalCorpus,
                });
            } else {


                if (this.corpusService.settings.shareableResourceToCommunity) {
                    children.push({
                        id: 'corpus-community',
                        title: 'Ressources de la communité',
                        translate: 'corpus.corpus_community',
                        type: 'item',
                        url:
                            '/corpus/community/' + this.corpusService.settings.globalCorpus,
                    });
                }

                this.dynamicNavigation.registerModuleMenu('level0', {
                    id: 'corpus',
                    title: 'Corpus',
                    translate: 'corpus.title',
                    type: 'collapse',
                    icon: 'corpus',
                    children: children,
                });
            }


            children.forEach(child => {
                this.dynamicNavigation.registerModuleMenu('level0', merge({}, child, {icon: 'corpus'}));
            });

            this.dynamicNavigation.registerModuleMenu('level0', {
                id: 'corpusPdf',
                title: 'CorpusPdf',
                translate: 'corpus.title.pdf',
                type: 'item',
                icon: 'corpus_pdf',
                url: '/corpus/pdf/' + this.corpusService.settings.globalCorpus,
            });

            CorpusModule.isMenuSet = true;
        }
    }
}
