import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReplaySubject} from 'rxjs';
import { langs, defaultLanguage } from '../settings';

export type Language = {
    id: string;
    title: string;
    flag: string;
    code: string;
}

const LANGUAGE_LOCAL_STORAGE_KEY = 'lang';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    private translateService = inject(TranslateService)

    private activeLanguage: Language;
    private _onLanguageChange$ = new ReplaySubject<Language>(1);

    public readonly availableLanguages: Language[] = langs;
    public readonly onLanguageChange$ = this._onLanguageChange$.asObservable();

    constructor() {
        const lastLanguage = this.getLastActiveLanguage();
        const navigatorLanguage = this.getNavigatorLanguage();
        const defaultSettingsLanguage = this.availableLanguages.find(lang => lang.id === defaultLanguage);

        this.initializeTranslateService(navigatorLanguage || defaultSettingsLanguage || this.availableLanguages[0]);

        this.activeLanguage = lastLanguage || navigatorLanguage || defaultSettingsLanguage || this.availableLanguages[0];

        this._onLanguageChange$.next(this.activeLanguage);
        this.translateService.use(this.activeLanguage.id);
    }

    private getNavigatorLanguage(): Language {
        const languageId = navigator.language.split('-')[0];
        return this.availableLanguages.find(lang => lang.id === languageId);
    }

    private getLastActiveLanguage(): Language {
        const languageId = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
        return this.availableLanguages.find(lang => lang.id === languageId);
    }

    public getActiveLanguage(): Language {
        return this.activeLanguage;
    }

    public setActiveLanguage(language: Language): void {
        this.activeLanguage = language;
        localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language.id);
        this.translateService.use(language.id);
        this._onLanguageChange$.next(language);
    }

    private initializeTranslateService(defaultLanguage: Language): void {
        const availableLanguageIds = this.availableLanguages.map(lang => lang.id);
        this.translateService.addLangs(availableLanguageIds);
        this.translateService.setDefaultLang(defaultLanguage.id);

    }
}
