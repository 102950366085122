import {Component, inject} from '@angular/core';
import * as _ from 'lodash-es';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import {brand} from '../../../../../settings';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {PluginSetting} from '@modules/activities/core/models/plugin.setting';
import {ToolActivityGranule} from '@modules/activities/core/models/activities/typologies/tool-activity.granule';

@Component({
    selector: 'app-tool',
    templateUrl: './tool.component.html'
})
export class ToolComponent extends BaseActivityComponent<ToolActivityGranule>{
    public brand = brand;
    public referenceActivityGranule: ToolActivityGranule['attributes']['reference'];
    public isReady = false;
    public allowedToolsPlugins: { toolIdentifier: string; setting: PluginSetting }[] = [];
    protected textButtonTool: string;

    private genericPluginsService = inject(GenericPluginsService)

    constructor(
    ) {
        super();
        this.isReady = false;
    }

    private getToolSetting(referenceActivityGranule: ToolActivityGranule['attributes']['reference']) {
        const toolId = _.get(referenceActivityGranule, 'config.tool');
        return this.genericPluginsService.getPluginsByType(PluginType.lessonTool).find(
            pluginSetting => pluginSetting.toolIdentifier === toolId
        );
    }

    /**
     * Return the svg identifier of the current activity tool image.
     * This image should be set while creating the tool activity.
     * If it's not, the default tool image `tools-circle` will be showed
     */
    public get iconName(): string {
        const toolSetting = this.getToolSetting(this.referenceActivityGranule);

        this.textButtonTool = "generic.ouvrir_" + toolSetting.toolIdentifier;
        return _.get(toolSetting, 'setting.iconIdentifier');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    public getToolsPlugins(): { toolIdentifier: string; setting: PluginSetting }[] {
        return this.genericPluginsService.getPluginsByType(PluginType.lessonTool);
    }

    protected setContentData(activityAttributes): void {
        this.isReady = false;
        this.allowedToolsPlugins = this.getToolsPlugins();
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = _.get(this.referenceActivityGranule, 'instruction');
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.isSaving = false;
        this.isReady = true;
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    public executeTool(): void {
        const toolSetting = this.getToolSetting(this.referenceActivityGranule);
        if (toolSetting) {
            this.lessonsService.executeToolFromLesson(toolSetting, {lesson: this.lessonsService.currentLesson, step: this.activitiesService.presentArrayElementIndex}).pipe(
                take(1)
            ).subscribe();
        }
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
