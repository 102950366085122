import {Component, EventEmitter, Input, Output} from '@angular/core';

export type AddLessonButtonMorphableType =
    'old-school'
    | 'neo-first-time'
    | 'neo-already-practiced'
    | 'neo-searching';

@Component({
  selector: 'app-add-lesson-button-morphable',
  templateUrl: './add-lesson-button-morphable.component.html',
  styleUrls: ['./add-lesson-button-morphable.component.scss']
})
export class AddLessonButtonMorphableComponent {
    @Input() public type: AddLessonButtonMorphableType = 'old-school';
    @Output() public buttonClick = new EventEmitter<MouseEvent>();

    public onButtonOfAnytypeClick($event: MouseEvent): void {
        this.buttonClick.emit($event);
    }
}
