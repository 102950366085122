import { Component, Input } from '@angular/core';
import {CustomBlock} from '../custom-block.model';

@Component({
    selector: 'app-presentation-general',
    templateUrl: './presentation-general.component.html',
    styleUrls: ['./presentation-general.component.scss']
})
export class PresentationGeneralComponent {
    @Input() block: CustomBlock;

    constructor() {}
}
