import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {defaultApiURL} from '../../app/settings';
import {HttpClient} from '@angular/common/http';
import {FileReferenceData} from "shared/models/file-reference";

/**
 * @deprecated use MediaService instead
 * use it to upload a file to backend
 */
@Injectable({
    providedIn: 'root'
})
export class UploadFileService {
    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';

    constructor(private http: HttpClient) {
    }

    /**
     * @deprecated use MediaService.createMedia instead
     * upload a file
     * @param file : file
     * @param userAccessToken : token of user you will find it in accountManagementProviderService.userAccessToken
     */
    public uploadFile(file: File, userAccessToken: string): Observable<FileReferenceData> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http
            .post<FileReferenceData>(this._urlFileUpload, formData, {headers: {'access-token': userAccessToken}});
    }
}
