import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE} from '../base-activity.component';
import {v4 as uuidv4} from 'uuid';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import * as _ from 'lodash';
import {AnswerResultInterface, answerStatusEnum} from '@modules/activities/core/models';
import {MemoryActivityGranule} from '@modules/activities/core/models/activities/typologies/memory-activity.granule';

export type ExtendedFlashCardInterface = FlashCardInterface & { selected: boolean, uid: number, state: ItemAnswerStateEnum };

/**
 * Memory activity :
 * take a list of flashcard in entry user must select two item
 * first item selected make the card returned and a sound play
 * after sound is played user can returned another card second card is returned and sound is played
 * a color indicate if it s an error or not if it s a true answer card will be returned but cannot be selectable anymore
 * if it's wrong they are returned but can be selected again
 */
@Component({
    selector: 'app-memory',
    templateUrl: './memory.component.html'
})
export class MemoryComponent extends BaseActivityComponent<MemoryActivityGranule> {
    public uuid: string = uuidv4();
    public stateOfLastSelectedItems: { uid: number, state: ItemAnswerStateEnum }[] = [];
    private uidItemsFound: number[] = [];
    public data: ExtendedFlashCardInterface[] = [];

    /**
     * listen if a card is playing the song
     * store the current state in the card
     * check if it's the second card and if it s launch the check of answer after end of read
     * @param playState play paused or stop
     * @param targetCard the leftCard or the right card of the memory is emitting the event
     */
    public cardPlayState(playState: string): void {
        // second card is turned and audio file read is endeed we check the answer
        if (this.data.filter(d => d.selected === true).length >= 2 && playState === PlayState.stop) {
            this.checkAnswer();
        }
    }

    /**
     * a click is made on a card we check if a click on it is allowed
     * if yes we push data on left or right card and pass the card on selected state
     * after card is select a sound is play : during this time where a card play a sound automaticly we can't do nothing
     * we must wait to select the second card
     * @param uid : unique id of the card
     */
    public changeState(uid: number): void {
        if (this.blockActionOnClick(uid)) {
            return;
        }
        this.data.find(d => d.uid === uid).selected = true;
    }

    /**
     * check the two element returned
     * @private
     */
    protected checkAnswer(): void {
        const itemCurrentlySelected = [...this.data.filter(d => d.selected === true)];
        if (this.data.filter(d => d.selected === true)[0].data === this.data.filter(d => d.selected === true)[1].data) {
            this.storeFoundedElement();
            this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.wasCorrect);
            this.data.filter(d => d.selected === true).forEach(d => d.selected = false);
            this.communicationCenter.getRoom('stepper').getSubject('locked').next(true);
            setTimeout(() => {
                this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.currentlyCorrect);

                if (this.uidItemsFound.length === this.data.length) {
                    const answerResult: AnswerResultInterface = {
                        id: +this.activity.id,
                        state: ItemAnswerStateEnum.currentlyCorrect,
                        isLast: true
                    };
                    super.manageProgressBarEventToSend(answerResult);
                    if (this.isActivityEmbedded) {
                        super.setFeedBackFromApi(null, answerStatusEnum.correct);
                        this.isActivityEmbeddedDone = true;
                    } else {
                        // user found all items
                        setTimeout(() => {
                            this.doAction('next');
                        }, TIME_DELAY_BEFORE_SAVE);
                    }
                } else {
                    this.communicationCenter.getRoom('stepper').getSubject('locked').next(false);
                }
            }, 1000);

        } else {
            this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.incorrect);
            this.data.filter(d => d.selected === true).map(d => d.selected = false);

            setTimeout(() => {
                this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.pristine);
            }, 1000);
        }
    }

    /**
     * all found element are store we push the last one founded
     * @private
     */
    private storeFoundedElement(): void {
        this.uidItemsFound.push(this.data.filter(d => d.selected === true)[0].uid);
        this.uidItemsFound.push(this.data.filter(d => d.selected === true)[1].uid);
    }

    /**
     * in some case the click on a card must not fire any action
     * already returned nothing to do or already finded nothing to do in a transitory state nothing to do
     * one card is playing the sound nothing to do user must wait the ended
     * state is showing bad or good answer color
     */
    public blockActionOnClick(uid: number): boolean {
        return this.data.filter(d => d.selected === true).length >= 2 || this.uidItemsFound.includes(uid) || this.data.find(d => d.uid === uid).selected === true
            || this.rulesForDisabledAllItems();
    }

    /**
     * some common rules to block and disable effect sound played two items selected...
     */
    public rulesForDisabledAllItems(): boolean {
        return this.stateOfLastSelectedItems.length === 2 &&
                (this.stateOfLastSelectedItems[1].state === ItemAnswerStateEnum.incorrect || this.stateOfLastSelectedItems[1].state === ItemAnswerStateEnum.wasCorrect);
    }

    /**
     * set the last item state
     * use to change the class of memory zone component to change design in regard of state
     * @param itemCurrentlySelected : item selected
     * @param currentState : pristine => not touch, was correct : it's good answer, currently-correct : it's good we not have to touch it anymore
     * @private
     */
    private setLastSelectedItems(itemCurrentlySelected: ExtendedFlashCardInterface[], currentState: ItemAnswerStateEnum): void {
        this.stateOfLastSelectedItems = [];
        this.stateOfLastSelectedItems.push({uid: itemCurrentlySelected[0].uid, state: currentState});
        this.stateOfLastSelectedItems.push({uid: itemCurrentlySelected[1].uid, state: currentState});
    }

    protected setContentData(data): void {
        this.wordingAlreadyReadWithTts = false;
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        if (this.referenceActivityGranule.config) {
            this.isVertical = (this.referenceActivityGranule.config.direction === 'vertical');
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.data = [];
        this.stateOfLastSelectedItems = [];
        this.uidItemsFound = [];
        if (this.displayForSummary) {
            this.data = this.referenceActivityGranule.activity_content[0].flashcards.slice() as ExtendedFlashCardInterface[];
        } else {
            this.data = this.shuffle(this.pairingDataIfNeeded()) as ExtendedFlashCardInterface[];
        }
        let i = 0;
        this.data.map(d => {
            d.selected = this.displayForSummary;
            d.state = this.displayForSummary ? ItemAnswerStateEnum.wasCorrect : ItemAnswerStateEnum.pristine;
            d.uid = i;
            i++;
        });
    }

    /**
     * if data contain only one exemplaire of each card make the other one
     * on crée les pair si le champs data est null ou si il n'y a pas au moins deux exemplaire identique
     * avec la meme data. ( at origine data is used to pair card if back send all the pair already made)
     * @private
     */
    private pairingDataIfNeeded() {
        let dataComplete = _.cloneDeep(this.referenceActivityGranule.activity_content[0].flashcards);
        if (dataComplete.filter(d => d.data === dataComplete[0].data).length !== 2 || dataComplete[0].data === null) {
            dataComplete.map((d, index) => {
                d.data = index.toString();
            });
            // create pairing doubling data
            dataComplete = [..._.cloneDeep(dataComplete), ..._.cloneDeep(dataComplete)];
        }
        return dataComplete;
    }

    /**
     * shuffle data of an array
     * @param array an array
     * @private
     */
    private shuffle<T>(array: T[]) {
        let currentIndex = array.length;
        let randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    /**
     * list of item selected currently
     */
    public selectedItems(): number[] {
        return this.data.filter(d => d.selected === true) ? this.data.filter(d => d.selected === true).map(data => data.uid) : [];
    }


    /* code from base activity inheritance not used for this activity*/

    protected saveAnswer(): Observable<any> {
        // not used
        return undefined;
    }

    protected reviewAnswer(): void {
        // not used
    }

    protected getGrade(): { oldGrade: number; newGrade: number } {
        // not used
        return {newGrade: 0, oldGrade: 0};
    }

    protected seeAnswerSolution(): void {
        // not used
    }

    protected setAnswer(): void {
        // not used
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }

    public get answersCounting(): string {
        return 'answers-counting-' + this.data.length;
    }

    public get answersWrapperClass(): string {
        return this.rulesForDisabledAllItems() ? 'disable-items ' + this.answersCounting :this.answersCounting;
    }

}

export enum PlayState {
    play = 'play',
    pause = 'pause',
    stop = 'stop'
}


