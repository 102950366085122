export const mainMenu = {
    displayMenu: {
        learner: {
            level0: [
                'home',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                    icon: 'lessons',
                },
                {
                    name: 'forFurther',
                    children: [
                        'gameCodeList',
                        'gleamCodeList',
                        'external-dictionary',
                        'external-language-lab'
                    ],
                },
                'corpus',
                {
                    name: 'tools',
                    children: [
                        'notepadList',
                        'mindmapList',
                        'bdList'
                    ],
                },
                'graph-humanum-single-own-progress',
                {
                    name: 'external-url',
                    class: ['menu-break'],
                },
                'informations-legales',
            ],
        },
        trainer: {
            level0: [
                'home',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                {
                    name: 'educational_resources',
                    children: [
                        'ressources_humanum',
                        'ressources_supplementaires',
                    ],
                },
                'lessonsDeployable',
                {
                    name: 'forFurther',
                    children: [
                        'gameCodeList',
                        'gleamCodeList',
                        'external-dictionary',
                        'external-language-lab'
                    ],
                },
                'corpus',
                {
                    name: 'tools',
                    children: [
                        'notepadList',
                        'mindmapList',
                        'bdList'
                    ],
                },
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                'graph-humanum',
                // followed with tab include followed list and diary log use only in mathia for moment
                //'faq',
                'ideasWall',
                {
                    name: 'external-url',
                    class: ['menu-break'],
                },
                'informations-legales',
                'feedback',
            ],
        },
        default: {
            level0: [
                'home',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                'lessonsDeployable',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                // followed with tab include followed list and diary log use only in mathia for moment
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback',
            ],
        },
    },
};