import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataVisualizationComponent} from './components/data-visualization/data-visualization.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {DataVisualizationService} from './services/data-visualization.service';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {DataVisualizationExportDirective} from "@modules/data-visualization/core/directives/data-visualization-export.directive";
import {NgChartsModule} from 'ng2-charts';

const routes: Routes = [
     {
         path: 'data-visualization',
         canActivate: [IsUserLogged],
         component: DataVisualizationComponent
     }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        NgChartsModule
    ],
    declarations: [
        DataVisualizationComponent,
        DataVisualizationExportDirective
    ],
    exports: [
        DataVisualizationComponent,
        DataVisualizationExportDirective
    ]
})
export class DataVisualizationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<DataVisualizationModule> {
        return {
            ngModule: DataVisualizationModule,
            providers: [
                DataVisualizationService
            ]
        };
    }

    private postLogout(): void {
        DataVisualizationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'data-visualization');
    }

    private postAuthentication(): void {
        if (!DataVisualizationModule.isMenuSet) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'data-visualization',
                'title': 'Résultats',
                'translate': 'Suivi élève',
                'type': 'item',
                'icon': 'followed',
                'url': '/data-visualization'
            });

            DataVisualizationModule.isMenuSet = true;
        }
    }
}
