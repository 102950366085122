import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {ActionBarComponent} from './action-bar/action-bar.component';
import {GridComponent} from './grid/grid.component';


@NgModule({
    declarations: [
        ActionBarComponent,
        GridComponent
    ],
    exports: [
        ActionBarComponent,
        GridComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class UiModule {
}
