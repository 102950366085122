import {map} from 'rxjs/operators';
import {Component, OnDestroy, inject} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Language, LanguageService} from 'shared/language.service';
import {HeaderNavigationService} from '../service/header-navigation.service';
import {shareReplay, Subject} from 'rxjs';
import {brand, modulesSettings} from '../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {Router} from '@angular/router';

const settingsToolbar: ModelSchema = new ModelSchema({
    helpMenu: Structures.object({default: true}),
    showWebAppBtn: Structures.boolean(false),
    translationAccess: Structures.object({show: false, withFlag: true}),
    notificationButtonAccess: Structures.boolean(),
    showTechnicalProblemMessage: Structures.boolean(false)
});

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy {

    private headerNavService = inject(HeaderNavigationService)
    private communicationCenter = inject(CommunicationCenterService)
    private router = inject(Router)
    private languageService = inject(LanguageService);

    private $destroy = new Subject<void>();
    private settings: { translationAccess: { show: boolean, withFlag: boolean } } = settingsToolbar.filterModel(modulesSettings.featureAccess) as any;
    private levelData$ = this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData')
            .pipe(shareReplay(1))

    public canBack$ = this.headerNavService.backAvailabilityChanged;
    public level$ = this.levelData$.pipe(map(({level}) => level));
    public coins$ = this.levelData$.pipe(map(({coins}) => coins));
    public showTranslateMenu = this.settings.translationAccess.show;
    public showFlagImg = this.settings.translationAccess.withFlag;
    public languages: Language[] = this.languageService.availableLanguages;
    public selectedLanguage$ = this.languageService.onLanguageChange$
    public brand = brand;

    /** ANGULAR LIFE CYCLE **/
    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    /** LOGIC **/

    public IsConnectPage() {
        return this.router.url === '/connect';
    }

    /** EVENTS **/

    public OnToggleMenuClick(): void {
        this.communicationCenter.getRoom('mainMenu').next('toggle', null);
    }

    public OnLanguageClick(lang: Language): void {
        this.languageService.setActiveLanguage(lang);
    }

    public onBackClick(): void {
        if (this.IsConnectPage()) {
            this.router.navigate(['/profile/show-profile']);
        } else {
            this.headerNavService.back();
        }
    }
}
