import {Injectable} from '@angular/core';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, of, combineLatest} from 'rxjs';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CollectionPaginator} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';
import {AuthenticationService} from '@modules/authentication';

// Todo: better to use this interface but createEntity's function can only have {[key: string]: any} for prop's type
/*export interface InterfaceRecommendation {
    uid: number; // id of current user
    action: string; // can be modify, ignore, assign
    lesson: number; // id of lesson
    learner: number; // id of learner
}*/

@Injectable({
    providedIn: 'root'
})
export class RecommendationService {

    public assignmentStates: DataEntity[] = [];
    public types: DataEntity[] = [];
    public learners: any[] = [];
    public lessons: DataEntity[] = [];
    public loading: boolean;
    public exitUrl: string;
    private userData: DataEntity;
    constructor(private activitiesService: ActivitiesService,
                private authenticationService: AuthenticationService,
                private communicationCenter: CommunicationCenterService,
                private lessonsService: LessonsService,
                private lessonEditionService: LessonEditionService,
                private octopusConnect: OctopusConnectService) {

        this.activitiesService.getAssignationTypes()
            .pipe(
                tap((types: DataEntity[]) => this.types = types)
            ).subscribe();
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
                tap((learners: any[]) => this.learners = learners)
        ).subscribe();
        this.communicationCenter
            .getRoom('assignments')
            .getSubject('statesList').pipe(
            tap((states: DataEntity[]) => this.assignmentStates = states)
        ).subscribe();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                // todo: exporter les url du lesson module pour récuperer les de redirection !
                this.exitUrl = this.authenticationService.isAtLeastManager() ? '/lessons/list/models' : '/lessons/list/lessons';
            });
    }

    /**
     * load recommendations
     * @param filterOptions
     */
    public loadRecommendations(filterOptions: CollectionOptionsInterface): Observable<{entities: DataEntity[], paginator: CollectionPaginator}> {
        const activitiesPaginated = this.octopusConnect.paginatedLoadCollection('recommendations', filterOptions);
        const activitiesPaginatedObs = activitiesPaginated.collectionObservable.pipe(take(1), map(collection => collection.entities));

        return activitiesPaginatedObs.pipe(
            mergeMap((entities: DataEntity[]) => of({entities, paginator: activitiesPaginated.paginator}))
        );
    }

    /**
     * assign recommended lesson selected
     * @param recommendations
     */
    public assignRecommendedLessonSelected(recommendations: DataEntity[]): Observable<DataEntity[]> {
        this.loading = true;
        const dataForAssignments =  recommendations.map((recommendation: DataEntity) => {
            return {
                assignated_node: recommendation.get('journey'),
                assignated_user: recommendation.get('student'),
                assignator: this.userData.id,
                dates: {value: 0, value2: 0},
                state_term: +(this.assignmentStates.find((state: DataEntity) => state.get('label') === 'assigned').id),
                type_term: recommendation.get('assignationType'),
            };
        });

        this.communicationCenter.getRoom('assignment')
            .next('createAssignment', {assignment: dataForAssignments, callback: () => this.loading = false, dontUseSpecificConfiguration: true});
        // only to call this.updateRecommendationsInDataSource() after assign
        return combineLatest(recommendations.map((recommendation: DataEntity) => this.createEntityByAction('assign', recommendation)));

    }

    /**
     * create entity after action (assign/modify/ignore).
     * @param action
     * @param recommendation
     */
    public createEntityByAction(action: string, recommendation: DataEntity): Observable<DataEntity> {
        const recommendationData = {
            uid: +this.userData.id,
            action: action,
            lesson: +recommendation.get('journey'),
            learner: +recommendation.get('student')
        };
        return this.octopusConnect.createEntity('recommendations', recommendationData).pipe(take(1));
    }

    /**
     * start lesson editor, begin with edit metadata modal then edit lesson
     * @param recommendation
     */
    public launchCopyLessonEditor(recommendation: DataEntity): Observable<DataEntity> {
        this.loading = true;
        if (this.authenticationService.isAtLeastManager()) {
            return this.lessonsService.launchEditor(recommendation.get('journey'))
            .pipe(
                mergeMap(() => this.createEntityByAction('modify', recommendation)),
                tap(() => this.loading = false)
            );
        } else {
            return this.lessonsService.launchCopyLessonEditor('copy', recommendation.get('journey').toString(), 'lesson').pipe(
                take(1),
                mergeMap((duplicatedLesson: DataEntity) => this.lessonEditionService.openEditor(duplicatedLesson.id, null, this.exitUrl)),
                mergeMap(() => this.createEntityByAction('modify', recommendation)),
                tap(() => this.loading = false)
            );
        }
    }

    /**
     * load lessons and models
     */
    public loadLessons(): Observable<any> {
        this.lessons = [];
        const lessonOptions: CollectionOptionsInterface = {
            filter: {
                format: 'lesson',
                multi_step: 0,
                author: this.userData.id,
                typology: null
            },
            page: 1,
            range: 50
        };
        const modelOptions: CollectionOptionsInterface = {
            filter: {
                format: 'lesson',
                multi_step: 0,
                role: this.lessonsService.getAllowedRoleIdsForModelsCreation(),
                typology: null
            },
            page: 1,
            range: 50
        };
        const obs = [lessonOptions, modelOptions].map((options: CollectionOptionsInterface, index) => this.loadLessonsInMultiplePage(options));
        return combineLatest(obs);
    }

    /**
     * get from api all paginated lessons and models
     * @param options
     */
    loadLessonsInMultiplePage(options): Observable<{entities: DataEntity[], paginator: CollectionPaginator}> {
        return this.lessonsService.loadPaginatedLessonGranuleCollection(options).pipe(
            tap((collection: {entities: DataEntity[], paginator: CollectionPaginator}) => {
                this.lessons.push(...collection.entities.slice());
                if (collection.paginator.hasNextPage) {
                    ++options.page;
                    this.loadLessonsInMultiplePage(options);
                }
            })
        );
    }

    /**
     * get settings of activities service
     */
    public get settings(): {[key: string]: any} {
        return this.activitiesService.settings;
    }
}
