import {Component, inject, OnDestroy} from '@angular/core';
import {AssignmentByStepService} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-step.service';

@Component({
    selector: 'app-assignment-settings-choice',
    templateUrl: './assignment-settings-choice.component.html',
})
export class AssignmentSettingsChoiceComponent implements OnDestroy {
    public dateSelected = false;
    public ruleSelected = false;

    private assignmentByStepService: AssignmentByStepService;

    constructor() {
        this.assignmentByStepService = inject(AssignmentByStepService);
    }

    public goToRules(): void {
        this.ruleSelected = true;
    }

    public goToDate(): void {
        this.dateSelected = true;
    }

    ngOnDestroy(): void {
        this.dateSelected = false;
        this.ruleSelected = false;
    }

}
