import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {brand} from '../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {AccountManagementProviderService} from '@modules/account-management';
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {UploadFileService} from 'fuse-core/services/upload-file.service';
import {ErrorUploadFile} from 'fuse-core/components/upload-file-generic/upload-file-generic.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";
import {FileReferenceData} from "shared/models/file-reference";
import {currentNavigator, currentOs} from 'shared/utils/device';

interface FeedbackData {
    email: string;
    category: string;
    content: string;
    language: string;
    idsFiles?: number[];
}

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit {
    isMessageSend = false;
    messageSendError = false;
    public bugDetailInfo = false;
    public files: File[] = [];
    public tooManyFilesError = false;
    public fileErrorMsg = '';
    feedbackForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        category: new UntypedFormControl('', [Validators.required]),
        content: new UntypedFormControl('', [Validators.required]),
    });
    public categoryList: { id: string | number, label: string, content: string }[] = [];
    public assetbrand = brand;

    constructor(private authenticationService: AuthenticationService,
                private octopusConnectService: OctopusConnectService,
                private router: Router,
                private translateService: TranslateService,
                private profileService: ProfileService,
                private accountManagementProviderService: AccountManagementProviderService,
                private uploadFileService: UploadFileService,
                public dialog: MatDialog) {

        this.octopusConnectService.loadCollection('feedback').subscribe(category => {
            category.entities.forEach((cat: DataEntity) => {
                this.categoryList.push({
                    id: cat.id,
                    label: cat.get('label'),
                    content: cat.get('description')
                });
            });
        });
    }

    ngOnInit(): void {
        this.setUserMailToContactIfExist();
    }

    /**
     * if user connected has an email push this email on the field email
     */
    private setUserMailToContactIfExist(): void {
        if (this.authenticationService.userData && this.authenticationService.userData.get('email')) {
            this.feedbackForm.patchValue({email: this.authenticationService.userData.get('email')});
        }
    }

    /**
     * listen category list change to show or not other fields
     * @param category
     */
    public changeCategory(category: MatSelectChange): void {
        if (this.profileService.settings.feedbackUseInputFieldForBug && category.value === this.profileService.settings.idSubjectToOpenBugInputs) {
            this.bugDetailInfo = true;
            this.addBugControls();
            this.setContextInfos();
        } else {
            this.removeBugControls();
            this.bugDetailInfo = false;

        }
        // insert text inside msg to help user to describe mistake not use if input field is used for describe bug
        if (!this.profileService.settings.feedbackUseInputFieldForBug && this.categoryList.filter(c => c.id === category.value)[0].content) {
            this.translateService.get(this.categoryList.filter(c => c.id === category.value)[0].content)
                .subscribe((translation: string) => {
                    this.feedbackForm.patchValue({'content': translation});
                });
        }
    }

    /**
     * in case of bug with input use we remove control when we doesn't use it
     * @private
     */
    private removeBugControls(): void {
        try {
            this.bugDetailInfo = false;
            this.feedbackForm.removeControl('description');
            this.feedbackForm.removeControl('navigator');
            this.feedbackForm.removeControl('os');
            this.feedbackForm.removeControl('bugcomputer');
        } catch (ex) {
            console.error(ex);
        }

    }

    /**
     * in case of bug with data to push inside input we add control dynamicly when we use it
     * @private
     */
    private addBugControls(): void {
        const description = new UntypedFormControl('');
        this.feedbackForm.addControl('description', description);
        const navigator = new UntypedFormControl('');
        this.feedbackForm.addControl('navigator', navigator);
        const os = new UntypedFormControl('');
        this.feedbackForm.addControl('os', os);
        const bugComputer = new UntypedFormControl(true);
        this.feedbackForm.addControl('bugcomputer', bugComputer);
    }

    /**
     * add context info if find it navigator used and os
     * @private
     */
    private setContextInfos(): void {
        if (currentOs() !== '') {
            this.feedbackForm.patchValue({'os': currentOs()});
        }

        if (currentNavigator() !== '') {
            this.feedbackForm.patchValue({'navigator': currentNavigator()});
        }
    }

    /**
     * suppress data in field that use autodetect
     * @private
     */
    private deleteContextInfos(): void {
        this.feedbackForm.patchValue({'os': ''});
        this.feedbackForm.patchValue({'navigator': ''});
    }

    public onSubmit(): void {
        this.sendData();
        this.resetForm();
        this.resetFormStatus();
        this.setUserMailToContactIfExist();
    }

    private resetFormStatus(): void {
        this.feedbackForm.reset();
        Object.keys(this.feedbackForm.controls).forEach(key => {
            this.feedbackForm.get(key)?.setErrors(null);
        });
    }

    /**
     * reset form after sending message
     */
    private resetForm(): void {
        this.feedbackForm.reset();
        this.feedbackForm.controls.email.setErrors(null);
        this.feedbackForm.controls.content.setErrors(null);
        this.feedbackForm.controls.category.setErrors(null);

        if (this.feedbackForm.controls.os) {
            this.feedbackForm.controls.os.setErrors(null);
        }
        if (this.feedbackForm.controls.description) {
            this.feedbackForm.controls.description.setErrors(null);
        }
        if (this.feedbackForm.controls.navigator) {
            this.feedbackForm.controls.navigator.setErrors(null);
        }

        this.removeBugControls();
    }

    /**
     * send data to backend join file if exist and mail to send after
     */
    private sendData(): void {
        const feedback: FeedbackData = {
            email: this.feedbackForm.value.email,
            category: this.feedbackForm.value.category,
            content: this.feedbackForm.value.content,
            language: this.translateService.currentLang,
        };

        if (this.isbugWithInputCase()) {
            // concat exta info in content because it's a bug with input data
            feedback.content = feedback.content + ' browser : ' + this.feedbackForm.value.navigator + ' OS: ' + this.feedbackForm.value.os + ' bug: ' + this.feedbackForm.value.description;
            try {
                if (this.feedbackForm.value.bugcomputer) {
                    const width = window.innerWidth
                        || document.documentElement.clientWidth
                        || document.body.clientWidth;

                    const height = window.innerHeight
                        || document.documentElement.clientHeight
                        || document.body.clientHeight;

                    const screenInfo = ' resolution : ' + screen.width + ' x ' + screen.height;

                    feedback.content = feedback.content + screenInfo + ' browser width : ' + width + ' browser height : ' + height;
                    feedback.content = feedback.content + ' browser zoom : ' + Math.round(window.devicePixelRatio * 100);
                }
            } catch (ex) {
                console.error('error getting width and height' + ex);
            }
        }

        if (this.isbugWithInputCase() && this.files.length > 0) {
            // it's a bug and so we use input fields if setting is true and we have file to send
            feedback.idsFiles = [];
            this.uploadFiles()
                .subscribe(fileReferences => {
                    fileReferences.forEach(fileReference => {
                        // file data is wrapped as a lone element in two arrays
                        if (fileReference.data.length && fileReference.data[0].length) {
                            feedback.idsFiles.push(+fileReference.data[0][0].id);
                        }
                    });
                    // we add ids that picture that was sended
                    this.files = [];
                    this.sendEmail(feedback);
                });
        } else {
            // send feedback bug or not without file
            this.sendEmail(feedback);
        }


    }

    /**
     * we are in case where when user select a bug some extra input permit to add navigator or os info
     * @private
     */
    private isbugWithInputCase(): boolean {
        return this.profileService.settings.feedbackUseInputFieldForBug && this.feedbackForm.value.category === this.profileService.settings.idSubjectToOpenBugInputs;
    }

    /**
     * send data to back for sending email
     * @param feedback : data to send to create email in back
     * @private
     */
    private sendEmail(feedback: FeedbackData): void {
        this.octopusConnectService.createEntity('form-project', feedback)
            .subscribe({
                next: () => {
                    this.isMessageSend = true;
                    if (this.profileService.settings.feedbackUseInputFieldForBug) {
                        this.openPopUpSuccess();

                    } else {
                        setTimeout(() => {
                            this.isMessageSend = false;
                            this.router.navigate(['../']);
                        }, 3000);
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.messageSendError = true;
                    setTimeout(() => {
                        this.isMessageSend = false;
                    }, 2000);
                }
            });
    }

    /**
     * open pop up that confirm success of email sendeed
     * @private
     */
    private openPopUpSuccess(): void {
        // open pop up confirming email sended
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: 'generic.email_sent_title',
            bodyDialog: 'generic.email_sent',
        };
        this.translateService.get(dialogConfig.data['bodyDialog']).subscribe((translation: string) => dialogConfig.data['bodyDialog'] = translation);
        this.translateService.get(dialogConfig.data['titleDialog']).subscribe((translation: string) => dialogConfig.data['titleDialog'] = translation);

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
    }

    /**
     * file is choosen store it
     * @param file : file
     */
    public uploadFileEvent(file: File): void {
        this.fileErrorMsg = '';
        if (this.files.length < +this.profileService.settings.feedbackMaxFileToSend) {
            this.files.push(file);
        } else {
            this.tooManyFilesError = true;
        }
    }

    /**
     * an error occurred
     * @param error
     */
    public fileError(error: ErrorUploadFile): void {
        try {
            switch (error) {
                case ErrorUploadFile.fileIsTooBig :
                    this.errorMsgToSend('generic.file_too_big');
                    break;
                case ErrorUploadFile.errorDuringDropFile:
                    this.errorMsgToSend('generic.file_drop_error');
                    break;
                case ErrorUploadFile.formatNotAllowed:
                    this.errorMsgToSend('generic.file_format_not_allowed');
                    break;
                default:
                    this.errorMsgToSend('generic.unknow_error');
                    break;
            }
        } catch (ex) {
            this.fileErrorMsg = 'an error occured';
        }
    }

    /**
     * translate terme to text to use
     * @param termeToTranslate : interface term
     * @private
     */
    private errorMsgToSend(termeToTranslate: string): void {
        this.translateService.get(termeToTranslate)
            .subscribe((translation: string) => {
                this.fileErrorMsg = translation;
            });
    }

    /**
     * remove file of the list of files to send
     * @param fileName : file
     */
    public removeFile(fileName: string): void {
        const index = this.files.findIndex(f => f.name === fileName);
        this.files.splice(index, 1);
        this.tooManyFilesError = false;
    }

    /**
     * @deprecated use MediaService.createMedia instead
     * upload all file the use have choosen
     * @private
     */
    private uploadFiles(): Observable<FileReferenceData[]> {
        const obs: Observable<FileReferenceData>[] = [];
        this.files.forEach(f => {
            obs.push(this.uploadFileService.uploadFile(f, this.accountManagementProviderService.userAccessToken));
        });

        return combineLatest(obs).pipe(take(1));
    }

    /**
     * the bug is not appear on this computer we reset data autodetct
     * or it s on this computer we launch again auto detect
     * @param isThisComputer
     */
    public changeDataAutoDetectForBug(isThisComputer: boolean): void {
        if (!isThisComputer) {
            this.deleteContextInfos();
        } else {
            this.setContextInfos();
        }
    }

    // max size allowed for file
    get maxFileSize(): string {
        return this.profileService.settings.feedbackMaxFileSize;
    }

    // is input use or not for describe bug
    get feedbackUseInputFieldForBug(): boolean {
        return this.profileService.settings.feedbackUseInputFieldForBug;
    }
}
